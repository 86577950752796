import { AxiosResponse } from "axios";
import helper from "../helper.util";
import { axiosRequest } from "../../../utils/auth";

interface ITimeSheetsPage {
  instanceId?: string;
  email?: string;
  fromDate?: string;
  toDate?: string;
  timezone?: string;
  shiftId?: string;
  shiftData?: { start: string; end: string; email: string; timezone: string };
}

const controllerName = "timeSheetsPage";

const getTimeSheetsPageData = (
  data: ITimeSheetsPage
): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getTimeSheetsPageData`, options);
};

const getTimeSheetsTimeCardData = (
  data: ITimeSheetsPage
): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getTimeSheetsTimeCardData`, options);
};

const updateShift = (data: ITimeSheetsPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/updateShift`, options);
};

const addShift = (data: ITimeSheetsPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/addShift`, options);
};

const deleteShift = (data: ITimeSheetsPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/deleteShift`, options);
};

const getAllStaffTimeCardsCSV = (
  data: ITimeSheetsPage
): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getAllStaffTimeCardsCSV`, options);
};

const getTimeCardCSV = (data: ITimeSheetsPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getTimeCardCSV`, options);
};

const timeSheetsApi = {
  getTimeSheetsPageData,
  getTimeSheetsTimeCardData,
  updateShift,
  addShift,
  deleteShift,
  getAllStaffTimeCardsCSV,
  getTimeCardCSV,
};

export default timeSheetsApi;
