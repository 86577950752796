
import { mapGetters } from "vuex";

export default {
  name: "AppRouters",
  computed: {
    ...mapGetters({
      isAdmin: "commonModule/getIsAdmin",
    }),
    appRoutes(): any {
      return [
        {
          name: "Reports",
          activeLinkName: "/reports",
          to: "/reports",
          disabled: false,
        },
        this.isAdmin
          ? {
              name: "Staff Members",
              activeLinkName: "/staff",
              to: "/staff",
            }
          : null,
        this.isAdmin
          ? {
              name: "Pay Rates",
              activeLinkName: "/staffPay",
              to: "/staffPay",
            }
          : null,
        {
          name: "Manage Timesheets",
          activeLinkName: "/timesheets",
          to: "/timesheets",
          disabled: false,
        },
      ].filter((x) => !!x);
    },
    isRouteActive(): any {
      return (activeLinkName: string) => {
        return this.$route.path === activeLinkName;
      };
    },
  },
  methods: {
    changeRoute(): void {
      this.$store.commit("commonModule/setActiveRoute", this.$route.path);
    },
  },
};
