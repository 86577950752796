import { MutationTree } from "vuex";
import { ICommonRootState, IUser } from "./types";
import { IOnBoardingPayRatesHeaders } from "../../../types/onBoardingPayRates.interface";

export const mutations: MutationTree<ICommonRootState> = {
  setIsLoading(state: ICommonRootState, data: boolean): void {
    state.isLoading = data;
  },
  setSiteServicesList(
    state: ICommonRootState,
    data: IOnBoardingPayRatesHeaders[]
  ): void {
    state.siteServicesList = data;
  },
  setUserDetails(state: ICommonRootState, data: IUser): void {
    state.user = data;
  },
  setTokenDetails(state: ICommonRootState, data: IUser): void {
    state.tokenDetails = data;
  },
  setRedirectFrom(state: ICommonRootState, data) {
    state.redirectFrom = data;
  },
  setAlertError(state: ICommonRootState, data: string): void {
    state.isApiError = true;
    state.errorMessage = data;
    setTimeout(() => {
      state.isApiError = false;
    }, 3000);
  },
  setInstanceId(state: ICommonRootState, data: string): void {
    state.instanceId = data;
  },
  setCurrentShopIndex(state, data) {
    state.currentShopIndex = data;
    sessionStorage.setItem("currentShopIndex", data);
  },
  setIsActive(state, data) {
    state.isUserActive = data;
  },
  setGeneralData(state, data) {
    state.generalData = data;
  },
  setPositions(state, data) {
    state.positions = data;
  },
  setActiveRoute(state, data) {
    state.activeRoute = data;
  },
  setInviteStaffDialogMembers(state, data) {
    state.inviteStaffDialogMembers = data;
  },
};
