import { ActionTree } from "vuex";
import { RootState } from "../../types";
import { IOnboardingRootState } from "./types";
import onBoardingApi from "../../../utils/api/onBoarding.api";
import payRateHelper from "../../../utils/payRatesTable.util";

export const actions: ActionTree<IOnboardingRootState, RootState> = {
  async getPayRatesTable({ commit, rootState }) {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
    };

    commit("commonModule/setIsLoading", true, { root: true });

    try {
      const tableData: any = await onBoardingApi.getPayRatesTable(data);

      if (tableData.success) {
        const { table, services } = tableData;

        const mapServiceIds = services.reduce((acc: any, service: any) => {
          if (service.name) acc[service.name] = service.id;
          return acc;
        }, {});

        const formattedTable = table.map((member: any) => {
          return {
            ...member,
            services: payRateHelper.formattedServices(
              member.services,
              mapServiceIds,
              true
            ),
          };
        });

        commit("setTableData", formattedTable);
        commit("commonModule/setSiteServicesList", services, { root: true });
        return tableData.success;
      }
    } catch (err) {
      console.info(`get pay rates table request: ${err}`);
    } finally {
      commit("commonModule/setIsLoading", false, { root: true });
    }
  },

  async saveSiteSettings({ rootState }, payload) {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      ...payload,
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
    };

    return onBoardingApi.saveSiteSettings(data);
  },

  async savePayRatesTableData({ rootState }, payload) {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      ...payload,
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
    };

    return onBoardingApi.savePayRatesTableData(data);
  },
};
