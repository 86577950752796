
import ReportsDateRange from "./ReportsDateRange.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import ReportSelectStaff from "./ReportSelectStaff.vue";
import constants from "../../utils/constants/constants.util";
export default {
  name: "ReportsMainHeader",
  components: { ReportSelectStaff, ReportsDateRange },
  props: {
    dates: {
      type: Array,
    },
    settings: {
      type: Object,
    },
  },
  data() {
    return {
      reportTypeOptions: ["Bookings", "Timesheets"],
      reportTypesSelect: ["Bookings", "Timesheets"],
      staffSelect: [constants.ALL_STAFF],
      selectedCurrentStaff: [],
      headerDates: [],
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: "commonModule/getIsAdmin",
      getReportsPageData: "reportsModule/getReportsPageData",
      user: "commonModule/getUser",
      getTokenDetails: "commonModule/getTokenDetails",
      getSettingsData: "payRatesModule/getSettings",
    }),
    rangeDates(): string[] {
      return [
        moment().clone().startOf(this.displayDatesBy).format("YYYY-MM-DD"),
        moment().clone().endOf(this.displayDatesBy).format("YYYY-MM-DD"),
      ];
    },
    displayDatesBy(): string {
      return this.settings.payFrequency === "Monthly" ? "month" : "week";
    },
    isDisabledSubmit(): boolean {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const dates = this.headerDates.sort((a: string, b: string) =>
        a.localeCompare(b)
      );

      const isValidDates =
        moment(dates[1], "YYYY-MM-DD").diff(moment(dates[0], "YYYY-MM-DD")) > 0;

      return this.getTokenDetails.status === "Blocked" || !isValidDates;
    },
    getCurrentStaff(): string[] {
      const item = this.getReportsPageData
        .filter(
          (staff: { email: string }) =>
            staff.email === this.getTokenDetails.email
        )
        .map((staff: { name: string }) => staff.name);

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.selectedCurrentStaff = item;

      return item;
    },
  },
  methods: {
    moment,
    updateDates(dates: string[]): void {
      this.headerDates = dates;
    },
    getSelectedStaff(selectedStaff: string[]): void {
      this.staffSelect = selectedStaff;
    },
    getReportsByFilters(): void {
      const calculateType =
        this.reportTypesSelect.length === this.reportTypeOptions.length
          ? "Both"
          : this.reportTypesSelect[0];

      const selectedStaff =
        this.staffSelect.length === this.getReportsPageData.length
          ? [constants.ALL_STAFF]
          : this.staffSelect;
      const data = {
        dates: this.headerDates.sort(),
        calculate: calculateType,
        emails: this.isAdmin ? selectedStaff : this.selectedCurrentStaff,
      };

      this.$store.commit("reportsModule/setReportType", calculateType);

      this.$emit("getReportsData", data);
    },
  },
};
