
import { mapActions, mapGetters } from "vuex";
import TimeSheetsTable from "../components/timeSheets/TimeSheetsTable.vue";
import TimeSheetsMainHeader from "../components/timeSheets/TimeSheetsMainHeader.vue";
import moment from "moment";

export default {
  name: "Timesheets",
  components: { TimeSheetsMainHeader, TimeSheetsTable },
  data(): { chosenDates: string[] } {
    return {
      chosenDates: [],
    };
  },
  computed: {
    ...mapGetters({
      getTokenDetails: "commonModule/getTokenDetails",
    }),
  },
  methods: {
    ...mapActions({
      getTimeSheetsList: "timeSheetsModule/getTimeSheetsPageData",
    }),
    moment,
    async getTimeSheetsListData(dates?: string[]): Promise<void> {
      this.chosenDates = dates;

      let fromDate = moment().startOf("month").startOf("day").format();
      let toDate = moment().endOf("month").endOf("day").format();

      if (!dates?.length) {
        this.chosenDates = [fromDate, toDate];
      }

      if (dates && dates.length > 0) {
        fromDate = moment(dates[0]).startOf("day").format();
        toDate = moment(dates[1]).endOf("day").format();
      }

      const data = {
        fromDate,
        toDate,
      };

      await this.getTimeSheetsList(data);
    },
  },
  async created(): Promise<void> {
    if (this.getTokenDetails.status !== "Blocked")
      await this.getTimeSheetsListData();
  },
};
