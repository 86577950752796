import { ActionTree } from "vuex";
import { ICommonRootState, ILoginToPlatform } from "./types";
import { RootState } from "../../types";
import { login } from "../../../../utils/auth";
import router from "../../../router";
import pagesApi from "../../../utils/api/pages.api";
import loginApi from "../../../utils/api/login.api";
import staffApi from "../../../utils/api/staffPage.api";
import jwt_decode from "jwt-decode";
import hashUtil from "../../../utils/hashPass.util";
import authApi from "../../../utils/api/auth.api";
import { AxiosResponse } from "axios";

export const actions: ActionTree<ICommonRootState, RootState> = {
  loginToPlatform: async function (
    { commit, dispatch },
    payload: ILoginToPlatform
  ): Promise<void> {
    commit("setIsLoading", true);
    try {
      const hashPassword =
        payload.instanceId || payload.isHasPassword
          ? payload.password
          : hashUtil.hashPassword(payload.password);
      const rsp: any = await login(
        payload.email?.toLowerCase(),
        hashPassword,
        payload.instanceId
      );

      if (rsp.success) {
        const { sites } = rsp;

        const jwtDetails: {
          permission: string;
          productId: string;
          email: string;
        } = jwt_decode(rsp.authToken);

        const data = {
          ...rsp,
          permission: jwtDetails.permission,
        };

        const defaultSiteIndex = sites.findIndex((site: any) => {
          return site.instanceId === (payload.instanceId || rsp.defaultSite);
        });

        commit("setUserDetails", data);
        commit("setTokenDetails", jwtDetails);
        commit("setCurrentShopIndex", defaultSiteIndex);

        const instanceId =
          sites[defaultSiteIndex]?.instanceId || rsp.defaultSite;
        const siteDisplayName =
          sites[defaultSiteIndex]?.instanceId || rsp.siteData.siteDisplayName;

        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.clarity("set", "userDetails", [instanceId, jwtDetails.email]);
        } catch (err) {
          console.error("clarity", err);
        }

        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.$crisp.push(["set", "user:email", jwtDetails.email]);
          // eslint-disable-next-line no-undef,@typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.$crisp.push([
            "set",
            "user:nickname",
            `${data.userName} - ${jwtDetails.permission}`,
          ]);
          // eslint-disable-next-line no-undef,@typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.$crisp.push(["set", "session:segments", [["Wix"]]]);
          // eslint-disable-next-line no-undef,@typescript-eslint/ban-ts-comment
          // @ts-ignore
          $crisp.push([
            "set",
            "session:data",
            [
              [
                ["billing_PLAN", jwtDetails.productId],
                ["instance_ID", instanceId],
                ["shop_NAME", siteDisplayName],
                [
                  "Open_Admin",
                  `https://easyteamadmin.z13.web.core.windows.net?instanceId=${instanceId}`,
                ],
                [
                  "User_Login",
                  `https://easyteamadmin.z13.web.core.windows.net?instanceId=${instanceId}&loginEmail=${jwtDetails.email}`,
                ],
              ],
            ],
          ]);
        } catch (err) {
          console.error(`crisp: ${err}`);
        }

        await Promise.all([
          dispatch("getGeneralData"),
          dispatch("workClockModule/getShiftState", null, { root: true }),
        ]);
        if (payload.redirectTo) await router.push(payload.redirectTo);
      } else {
        commit("setAlertError", rsp.msg);
        commit("setIsLoading", false);
      }
    } catch (err) {
      console.error(`login request: ${err}`);
    } finally {
      commit("setIsLoading", false);
      // eslint-disable-next-line no-undef,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      $crisp.push(["do", "chat:show"]);
    }
  },
  async mailRegister({ commit, dispatch }, payload): Promise<void> {
    commit("setIsLoading", true);

    const password = hashUtil.hashPassword(payload.password);

    const data = {
      ...payload,
      password,
    };

    try {
      const rsp: any = await loginApi.mailRegister(data);

      if (rsp.data.success) {
        dispatch("loginToPlatform", {
          email: rsp.data.email,
          password: payload.password,
          redirectTo: "/reports",
        });
      }
    } catch (err) {
      console.error(`mail register request: ${err}`);
      await router.push("/");
    } finally {
      commit("setIsLoading", false);
    }
  },
  async getGeneralData({ commit, rootState }): Promise<void> {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;

    commit("setIsLoading", true);

    const data = {
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
    };

    try {
      const rsp: any = await pagesApi.getGeneralData(data);

      if (rsp.success) commit("setGeneralData", rsp.data.categories);
    } catch (err) {
      console.error(`get general data: ${err}`);
    } finally {
      commit("setIsLoading", false);
    }
  },
  async getPositions({ commit, rootState }): Promise<void> {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
    };

    try {
      const rsp: any = await pagesApi.getPositions(data);

      if (rsp.success) commit("setPositions", rsp.data.categories);
    } catch (err) {
      console.error(`get positions data: ${err}`);
    }
  },
  async deleteDepartment({ rootState, dispatch }, payload): Promise<void> {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      ...payload,
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
    };

    try {
      const rsp = await staffApi.deleteDepartment(data);
      if (rsp.data.success) {
        dispatch("getGeneralData");
      }
    } catch (err) {
      console.error(`delete department request: ${err}`);
    }
  },
  async deletePosition({ rootState, dispatch }, payload): Promise<void> {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      ...payload,
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
    };

    try {
      const rsp = await staffApi.deletePosition(data);
      if (rsp.data.success) {
        dispatch("getGeneralData");
      }
    } catch (err) {
      console.error(`delete position request: ${err}`);
    }
  },
  async addPositions({ rootState, dispatch }, payload) {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      ...payload,
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
    };

    try {
      await staffApi.addPositions(data);
      dispatch("getPositions");
    } catch (err) {
      console.error(`add positions request: ${err}`);
    }
  },
  async addDepartments({ rootState, dispatch }, payload) {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      ...payload,
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
    };

    try {
      await staffApi.addDepartments(data);
      dispatch("getGeneralData");
    } catch (err) {
      console.error(`add department request: ${err}`);
    }
  },
  async publicURLRegister({ commit }, payload): Promise<void> {
    commit("setIsLoading", true);
    try {
      await loginApi.publicURLRegister(payload);
      await router.push("/");
    } catch (err) {
      console.error(`public URL register request: ${err}`);
    } finally {
      commit("setIsLoading", false);
    }
  },
  async setPassword({ commit }, payload): Promise<void> {
    commit("setIsLoading", true);
    try {
      await loginApi.setPassword(payload);
      await router.push("/");
    } catch (err) {
      console.error(`set password request: ${err}`);
    } finally {
      commit("setIsLoading", false);
    }
  },
  async changeSite({ rootState, dispatch }, payload): Promise<void> {
    const { tokenDetails } = rootState.commonModule;

    await dispatch("loginToPlatform", {
      email: tokenDetails.email,
      password: tokenDetails.password,
      instanceId: payload,
    });
  },
  refreshToken({ rootState }): Promise<AxiosResponse> {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;

    return authApi.refreshToken({
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
    });
  },
};
