
import { rootColorNames } from "../../utils/root.util";
import { IOnboardingTypes } from "../../types/onboarding.interface";
import { pngList } from "../../utils/assets.util";
import { mapActions, mapGetters } from "vuex";
import constants from "../../utils/constants/constants.util";
import formRules from "../../utils/formRules.util";
import onboardingApi, {
  IOnBoardingRequest,
} from "../../utils/api/onBoarding.api";
import hashUtil from "../../utils/hashPass.util";

export default {
  name: "RegisterPage",
  data(): IOnboardingTypes {
    return {
      applicationColor: rootColorNames.applicationColor,
      fullName: "",
      password: "",
      email: "",
      formRules: formRules(),
      isValid: "",
      isShowPass: false,
      appLogo: pngList.appLogo,
      registerSubtitle: constants.onboardingConstants.REGISTER_SUBTITLE,
      registerTitle: constants.onboardingConstants.REGISTER_TITLE,
      registerListData: constants.onboardingConstants.REGISTER_LIST_DATA,
      appName: constants.commonConstants.APP_NAME,
      instanceId: "",
      siteOwnerId: "",
    };
  },
  computed: {
    ...mapGetters({
      isApiError: "commonModule/getIsApiError",
      errorMessage: "commonModule/getErrorMessage",
    }),
    height(): number | undefined {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 4;
        case "sm":
          return 400;
        case "md":
          return 6;
        case "lg":
          return 600;
        default:
          return 7;
      }
    },
  },
  created(): void {
    const { instanceId, siteOwnerId, siteEmail } = this.$route.query;
    this.instanceId = instanceId;
    this.siteOwnerId = siteOwnerId;
    this.$store.commit("commonModule/setInstanceId", instanceId);
    this.email = !siteEmail ? siteEmail : "";
  },
  methods: {
    ...mapActions("commonModule", ["loginToPlatform"]),
    registerToPlatform(): void {
      this.$store.commit("commonModule/setIsLoading", true);

      const data: IOnBoardingRequest = {
        instanceId: this.instanceId,
        password: hashUtil.hashPassword(this.password),
        name: this.fullName,
        email: this.email?.toLowerCase(),
        siteOwnerId: this.siteOwnerId,
      };

      onboardingApi
        .createOwner(data)
        .then((rsp) => {
          if (rsp.data.success) {
            this.loginToPlatform({
              email: this.email,
              password: this.password,
              redirectTo: "/onboarding",
            });
          }
        })
        .finally(() => {
          this.$store.commit("commonModule/setIsLoading", false);
        });
    },
    handleSubmit(): void {
      if (this.$refs.form.validate()) this.registerToPlatform();
    },
  },
};
