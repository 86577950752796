const SELECTED_RATE_OPTIONS = {
  FLAT_RATE: "Flat rate",
  PERCENTAGE_RATE: "Percentage rate",
  PARTICIPANT_RATE: "Participant rate",
};

const enums = {
  SELECTED_RATE_OPTIONS,
};

export default enums;
