
import { IServiceOptions } from "../types/onBoardingPayRates.interface";
import enums from "../utils/enums/enums.";
import { mapGetters } from "vuex";
import {
  ICustomService,
  IMember,
  IPayRatesHeaderBlock,
} from "../types/staffPay.interface";

export default {
  name: "PayRateHeaderBlock",
  props: {
    header: {
      type: Object,
    },
    totalEmployees: {
      type: Number,
      default: 0,
    },
    selectedRateOption: {
      type: String,
      default: "",
    },
    isParticipant: {
      type: Boolean,
    },
    serviceType: {
      type: String,
    },
    serviceName: {
      type: String,
    },
    isOnBoarding: {
      type: Boolean,
      default: false,
    },
    onBoardingRateType: {
      type: String,
    },
  },
  data(): IPayRatesHeaderBlock {
    return {
      menu: false,
      currentPaymentValue: "",
      selectedOption: "",
      selectedRateMap: {
        Both: "Flat rate",
        Flat: "Flat rate",
        Percentage: "Percentage rate",
        "Flat Rate": "Flat Rate",
        "Flat rate": "Flat rate",
        "Percentage rate": "Percentage rate",
      },
      selectedRateOptions: enums.SELECTED_RATE_OPTIONS,
      tableFormatted: [],
      isFirstLoading: true,
      baseSteps: [
        {
          index: 1,
          from: 0,
          to: 1,
          rate: 0,
        },
        {
          index: 2,
          from: 2,
          to: 3,
          rate: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      siteTable: "onBoardingModule/getTableData",
      payRatePageData: "payRatesModule/getPayRatePageData",
      getSiteSettings: "payRatesModule/getSettings",
      serviceNamesOnDisabled: "payRatesModule/getServiceNamesOnDisabled",
      getIsDisabledPayRates: "payRatesModule/getIsDisabledPayRates",
    }),
    setServiceType: {
      get(): string {
        let paymentType;
        const tableData = this.isOnBoarding
          ? this.siteTable
          : this.payRatePageData;

        tableData.forEach((employee: any) => {
          if (
            employee.services[this.serviceName] &&
            employee.services[this.serviceName].paymentType !== ""
          ) {
            paymentType = employee.services[this.serviceName].paymentType;
          }
        });
        return paymentType ? paymentType : "";
      },
      set(val: string): void {
        const tableData = this.isOnBoarding
          ? this.siteTable
          : this.payRatePageData;

        const tableFormatted = tableData.map((member: IMember) => {
          return {
            ...member,
            services: {
              ...member.services,
              [this.serviceName]: {
                ...member.services[this.serviceName],
                isSelected: false,
                payRates: {},
                isParticipant: val === "Percentage rate" && false,
                paymentType: val,
              },
            },
          };
        });

        if (this.isOnBoarding)
          this.$store.commit("onBoardingModule/setTableData", tableFormatted);
        else {
          this.$store.commit(
            "payRatesModule/setPayRatePageData",
            tableFormatted
          );
        }
      },
    },
    setIsParticipant: {
      get(): boolean {
        let isParticipant = false;
        const tableData = this.isOnBoarding
          ? this.siteTable
          : this.payRatePageData;

        tableData.forEach((employee: { services: ICustomService[] }) => {
          if (employee.services[this.serviceName].isParticipant) {
            isParticipant = employee.services[this.serviceName].isParticipant;
          }
        });
        return tableData.length > 0 && isParticipant;
      },
      set(val: boolean): void {
        const tableData = this.isOnBoarding
          ? this.siteTable
          : this.payRatePageData;

        const tableFormatted = tableData.map((member: IMember) => {
          return {
            ...member,
            services: {
              ...member.services,
              [this.serviceName]: {
                ...member.services[this.serviceName],
                isParticipant: val,
                steps: this.baseSteps,
              },
            },
          };
        });

        if (this.isOnBoarding)
          this.$store.commit("onBoardingModule/setTableData", tableFormatted);
        else {
          this.$store.commit(
            "payRatesModule/setPayRatePageData",
            tableFormatted
          );
        }
      },
    },
    getValueBySelection(): string {
      let paymentType,
        isParticipant = false;

      const tableData = this.isOnBoarding
        ? this.siteTable
        : this.payRatePageData;

      if (this.serviceType === "Course") {
        return "Flat rate per session";
      }

      if (!this.isOnBoarding && tableData.length) {
        tableData.forEach((employee: any) => {
          if (employee.services[this.serviceName].paymentType !== "") {
            paymentType = employee.services[this.serviceName].paymentType;
          }

          if (employee.services[this.serviceName].isParticipant)
            isParticipant = employee.services[this.serviceName].isParticipant;
        });

        if (paymentType === "Flat rate" && isParticipant) {
          return "Flat rate + participants";
        }

        return (
          paymentType || this.selectedRateMap[this.getSiteSettings.rateType]
        );
      } else {
        const paymentType =
          (tableData.length &&
            tableData[0].services[this.serviceName].paymentType) ||
          this.onBoardingRateType;

        const isParticipant =
          tableData.length &&
          tableData[0].services[this.serviceName].isParticipant;

        if (this.serviceType === "Appointment") {
          return this.selectedRateMap[paymentType];
        }

        if (paymentType === "Flat rate" && isParticipant) {
          return "Flat rate + participants";
        }

        if (this.onBoardingRateType === paymentType) {
          return this.selectedRateMap[paymentType];
        } else {
          return paymentType;
        }
      }
    },
    serviceOptionsList(): IServiceOptions[] | any {
      const { FLAT_RATE, PERCENTAGE_RATE, PARTICIPANT_RATE } =
        enums.SELECTED_RATE_OPTIONS;

      const tableData = this.isOnBoarding
        ? this.siteTable
        : this.payRatePageData;

      const paymentType =
        (tableData.length &&
          tableData[0].services[this.serviceName] &&
          tableData[0].services[this.serviceName].paymentType) ||
        this.selectedRateMap[this.getSiteSettings.rateType];

      return [
        {
          id: 1,
          title: PERCENTAGE_RATE,
          description:
            "Staff members get a percentage of the total revenue earned from a session. e.g., If a staff member gets 50% of a class that makes $300, they would earn $150.",
          isDisabled: false,
        },
        {
          id: 2,
          title: FLAT_RATE,
          description:
            "Staff members get a flat rate for each session they hold. e.g., A staff member earns $100 per session.",
          isDisabled: false,
        },
        this.serviceType !== "Appointment"
          ? {
              id: 3,
              title: PARTICIPANT_RATE,
              description:
                "Staff members get paid for each participant, on top of their session rate. e.g., If a staff member gets $10 per participant and 20 people book, they would earn $200.",
              isDisabled:
                this.serviceType === "Course" ||
                paymentType === "Percentage rate",
            }
          : null,
      ].filter((x) => !!x);
    },
    getIsParticipant() {
      return (
        serviceParticipant: boolean,
        isParticipant: boolean,
        paymentType: string
      ): boolean => {
        if (this.isOnBoarding) {
          if (this.serviceType !== "Appointment") {
            return this.isParticipant;
          } else {
            return false;
          }
        } else if (paymentType && !serviceParticipant) {
          return false;
        } else {
          return isParticipant;
        }
      };
    },
    getPaymentType() {
      return (paymentType: string): string => {
        if (this.serviceType === "Course") {
          return "Flat rate";
        } else if (this.isOnBoarding) {
          return this.selectedRateMap[this.onBoardingRateType];
        } else {
          return (
            paymentType || this.selectedRateMap[this.getSiteSettings.rateType]
          );
        }
      };
    },
  },
  methods: {
    changeRadio(): void {
      this.$store.commit("payRatesModule/setIsDisabledPayRates", true);
      if (
        !this.serviceNamesOnDisabled.includes(this.serviceName) &&
        this.getIsDisabledPayRates
      ) {
        const services = [...this.serviceNamesOnDisabled, this.serviceName];
        this.$store.commit(
          "payRatesModule/setServiceNamesOnDisabled",
          services
        );
      }
    },
  },
  created(): void {
    let paymentType = "";
    let isParticipant = "";

    let payRatePageData = !this.isOnBoarding
      ? this.payRatePageData
      : this.siteTable;
    if (!this.isOnBoarding) {
      payRatePageData.forEach((employee: any) => {
        if (
          employee.services[this.serviceName] &&
          employee.services[this.serviceName].paymentType !== ""
        ) {
          paymentType = employee.services[this.serviceName].paymentType;
        }
        if (
          employee.services[this.serviceName] &&
          employee.services[this.serviceName].isParticipant
        )
          isParticipant = employee.services[this.serviceName].isParticipant;
      });
    }

    const tableFormatted = payRatePageData.map((member: IMember) => {
      return {
        ...member,
        services: {
          ...member.services,
          [this.serviceName]: {
            ...member.services[this.serviceName],
            isParticipant: this.getIsParticipant(
              payRatePageData[0]?.services[this.serviceName].isParticipant,
              Boolean(isParticipant),
              paymentType
            ),
            paymentType: this.getPaymentType(paymentType),
          },
        },
      };
    });

    if (this.isOnBoarding)
      this.$store.commit("onBoardingModule/setTableData", tableFormatted);
    else {
      this.$store.commit("payRatesModule/setPayRatePageData", tableFormatted);
    }
  },
};
