
import formRules from "../utils/formRules.util";
import { pngList } from "../utils/assets.util";
import { rootColorNames } from "../utils/root.util";
import constants from "../utils/constants/constants.util";
import { IWelcomePage } from "../types/welcomePages.interface";
import WelcomePageWrapper from "../components/WelcomePageWrapper.vue";
import { mapActions, mapGetters } from "vuex";
import hashUtil from "../utils/hashPass.util";

export default {
  name: "ResetPassword",
  components: { WelcomePageWrapper },
  props: {
    registrationMode: {
      type: String,
    },
    isRegisterViaSignIn: {
      type: Boolean,
    },
    userToken: {
      type: String,
    },
  },
  data(): IWelcomePage {
    return {
      password: "",
      isShowPass: false,
      verifyPassword: "",
      isVerifyPassword: false,
      formRules: formRules(),
      isValid: "",
      appLogo: pngList.appLogo,
      applicationColor: rootColorNames.applicationColor,
      appName: constants.commonConstants.APP_NAME,
      mainLogo: pngList.main,
      labels: constants.LABELS,
    };
  },
  computed: {
    ...mapGetters({
      isApiError: "commonModule/getIsApiError",
    }),
  },
  methods: {
    ...mapActions({
      setPassword: "commonModule/setPassword",
    }),
    async handleSubmit(): Promise<void> {
      if (this.$refs.form.validate()) {
        const { resetToken } = this.$route.query;

        const data = {
          resetToken,
          password: hashUtil.hashPassword(this.password),
        };

        await this.setPassword(data);
      }
    },
  },
};
