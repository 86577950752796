import { MutationTree } from "vuex";
import { ITimeSheetsRootState } from "./types";
import { ITimeSheetsDetails } from "../../../types/timeSheets.interface";

export const mutations: MutationTree<ITimeSheetsRootState> = {
  setTimeSheetsList(
    state: ITimeSheetsRootState,
    data: ITimeSheetsDetails[]
  ): void {
    state.timeSheetsList = data;
  },
};
