import { ActionTree } from "vuex";
import reportsApi from "../../../utils/api/reports.api";
import { RootState } from "../../types";
import { IReports } from "./types";

export const actions: ActionTree<IReports, RootState> = {
  async getReportsData({ commit, rootState, rootGetters }, payload) {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...payload,
    };

    commit("commonModule/setIsLoading", true, { root: true });

    try {
      const rsp: any = await reportsApi.generateReport(data);
      if (rsp.success) {
        commit(
          "setReports",
          rootGetters["commonModule/sortByOnlineUser"](rsp.reportData)
        );

        const totalTable = { session: 0, payout: 0 };
        rsp.reportData.forEach((item: any) => {
          totalTable.session += item.sessionsCount;
          totalTable.payout += parseFloat(item.totalPayout);
        });

        commit("setTableTotal", totalTable);
      }
    } catch (err) {
      console.error(`generate report request: ${err}`);
    } finally {
      commit("commonModule/setIsLoading", false, { root: true });
    }
  },
  async getReportsPageData({ commit, rootState }) {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
    };

    try {
      const rsp: any = await reportsApi.getReportsPageData(data);
      if (rsp.success) {
        commit("setReportsPageData", rsp.data.siteStaff);
      }
    } catch (err) {
      console.error(`get reports page data request: ${err}`);
    } finally {
      // eslint-disable-next-line no-undef,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      $crisp.push(["do", "chat:show"]);
    }
  },
  async generateReportSummaryCSV({ rootState }, payload) {
    try {
      const data = {
        reportData: payload,
      };
      return reportsApi.generateReportSummaryCSV(data);
    } catch (err) {
      console.error(`generate report summary CSV: ${err}`);
    }
  },
};
