import constants from "./constants/constants.util";

const getRequestOptions = (data: object): { method: string; body: string } => {
  return {
    method: "POST",
    body: JSON.stringify(data),
  };
};

const getShortName = (userName: any): string => {
  const isHebrewUserName = /[\u0590-\u05FF]/.test(userName);
  if (isHebrewUserName) {
    const fullHebrewName =
      isHebrewUserName &&
      userName
        .match(/[\s]*(\S)+[\s]*/g)
        .join("")
        .split(" ");
    return fullHebrewName.map((char: string) => char.charAt(0)).join("");
  }
  return userName
    ? userName
        .match(/\b(\w)/g)
        .join("")
        .toUpperCase()
    : "";
};

const getAvatarSrc = (avatarFile: string): string => {
  return `${constants.commonConstants.AVATAR_FOLDER}${avatarFile}`;
};

const uploadFile = (eventData: { target: HTMLInputElement }) => {
  const image: any = eventData.target.files && eventData.target.files[0];
  const data = new FormData();
  data.append("name", "my-picture");
  data.append("file", image);
  const reader = new FileReader();
  reader.readAsDataURL(image);
  return reader;
};

const downloadCSV = (csvData: any, csvName: string): void => {
  const downloadUrl = window.URL.createObjectURL(new Blob([csvData]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", `${csvName}.csv`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const helper = {
  getRequestOptions,
  getShortName,
  getAvatarSrc,
  uploadFile,
  downloadCSV,
};

export default helper;
