const APP_NAME = "EasyTeam";

const ID_TOKEN_KEY = "easy_team_id_token";

const PUBLIC_PATHS = [
  "/",
  "/onboarding/register",
  "/onboarding",
  "/forgotPassword",
  "/resetPassword",
  "/onboarding/login",
  "/onboarding/memberRegister",
];

const AVATAR_FOLDER =
  "https://avatarfiles.blob.core.windows.net/avatarfiles/828878_8041ccc02f544dff815de25a06f09e8e~mv2.jpg";

const commonConstants = {
  APP_NAME,
  ID_TOKEN_KEY,
  PUBLIC_PATHS,
  AVATAR_FOLDER,
};

export default commonConstants;
