
import BaseDialog from "./BaseDialog.vue";
import constants from "../utils/constants/constants.util";
import { IDialogActions } from "../types/baseDialog.interface";
export default {
  name: "InviteStaffDialog",
  props: {
    value: {
      type: Boolean,
    },
  },
  components: { BaseDialog },
  data(): { labels: object } {
    return {
      labels: constants.LABELS,
    };
  },
  computed: {
    isOpenInviteStaffDialog: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit("input", val);
      },
    },
    dialogActions(): IDialogActions[] {
      return [
        {
          label: "I’ll do it later",
          outlined: true,
          color: "primary",
          onClick: () => this.$emit("closeDialog"),
        },
        {
          label: "Send invite",
          color: "primary",
          onClick: () => console.log("confirm"),
        },
      ];
    },
  },
};
