
import { mapActions, mapGetters } from "vuex";
import OnBoardingInviteStaff from "../components/onBoarding/OnBoardingInviteStaff.vue";
import { IStaff } from "../types/inviteStaff.interface";
import BackButton from "../components/BackButton.vue";
import { emailReg } from "../utils/formRules.util";
import constants from "../utils/constants/constants.util";
import jwt_decode from "jwt-decode";
import { login } from "../../utils/auth";
import config from "../../config";

export default {
  name: "InviteStaff",
  components: { BackButton, OnBoardingInviteStaff },
  data(): {
    membersData: IStaff[];
    isOpenMissingEmailsDialog: boolean;
    missingEmails: IStaff[];
    isSendInviteEmails: boolean;
  } {
    return {
      membersData: [],
      isOpenMissingEmailsDialog: false,
      missingEmails: [],
      isSendInviteEmails: false,
    };
  },
  computed: {
    ...mapGetters({
      getMemberList: "staffModule/getMemberList",
      staffList: "staffModule/getStaffListData",
      getTokenDetails: "commonModule/getTokenDetails",
      getIsOpenMissingEmailsDialog: "staffModule/getIsOpenMissingEmailsDialog",
      getMissingEmails: "staffModule/getMissingEmails",
      getCurrentSiteIndex: "commonModule/getCurrentSiteIndex",
      getRedirectFrom: "commonModule/getRedirectFrom",
      getUser: "commonModule/getUser",
      getInviteStaffDialogMembers: "commonModule/getInviteStaffDialogMembers",
    }),
    isDisplayButton(): boolean {
      const totalUsers = this.getMemberList.length + this.staffList.length;
      return totalUsers > this.getTokenDetails.maxUsers;
    },
    isStaffListValid(): boolean {
      const filteredStaff = this.membersData.filter(
        (item: IStaff) => item.email && item.email.includes("@")
      );

      return filteredStaff.length === this.membersData.length;
    },
    isActiveUserHaveResourceId(): boolean {
      const { email } = this.getTokenDetails;
      const currentUser = this.staffList.find((user: any) => {
        return user.email === email;
      });

      return currentUser && currentUser.resourceId === "";
    },
    getMembersData: {
      get(): IStaff[] {
        return this.membersData;
      },
      set(val: IStaff[]): void {
        this.membersData = val;

        this.$store.commit("staffModule/setSelectedMember", val);

        if (!this.getMissingEmails.length)
          this.$store.commit("staffModule/setMemberList", val);
      },
    },
  },
  methods: {
    ...mapActions({
      getSiteStaffFromWix: "staffModule/getSiteStaffFromWix",
      addStaffMembers: "staffModule/addStaffMembers",
      redirectToPackageUpgradeReq: "staffModule/redirectToPackageUpgrade",
      refreshToken: "commonModule/refreshToken",
    }),
    closeMissingEmail(): void {
      this.$store.commit("staffModule/setIsOpenMissingEmailsDialog", false);
    },
    async redirectToPackageUpgrade(): Promise<void> {
      if (this.isStaffListValid) {
        const membersData = this.getMembersToSave(
          this.getInviteStaffDialogMembers
        );

        sessionStorage.setItem("membersData", JSON.stringify(membersData));

        const instanceId =
          this.getUser?.sites[this.getCurrentSiteIndex]?.instanceId;
        const wixUrl = `https://www.wix.com/apps/upgrade/${config.WIX_APP_ID}?appInstanceId=${instanceId}`;

        window.open(wixUrl, "_blank");

        this.$store.commit("commonModule/setRedirectFrom", this.$route.path);
      } else {
        const missingEmails = this.membersData.filter(
          (item: IStaff) => !item.email || !emailReg.test(item.email)
        );

        this.$store.commit("staffModule/setMissingEmails", missingEmails);
        this.$store.commit("staffModule/setIsOpenMissingEmailsDialog", true);
      }
    },
    onBack(): void {
      this.$store.commit("staffModule/setMemberList", []);
      this.$router.push("/staff");
    },
    async onConfirm(): Promise<void> {
      if (this.isStaffListValid) {
        await this.saveStaffMembers();
      } else {
        const missingEmails = this.membersData.filter(
          (item: IStaff) => !item.email || !emailReg.test(item.email)
        );

        this.$store.commit("staffModule/setMissingEmails", missingEmails);
        this.$store.commit("staffModule/setIsOpenMissingEmailsDialog", true);
      }
    },
    getMembersToSave(members?: IStaff[]): IStaff[] {
      this.membersData =
        members && members.length
          ? [...this.membersData, ...members]
          : this.membersData;
      return this.membersData.map((staff: IStaff) => {
        const id = staff.id;
        delete staff.id;
        return {
          ...staff,
          wixId: id,
        };
      });
    },
    async saveStaffMembers(members?: IStaff[]): Promise<void> {
      const memberData = this.getMembersToSave(members);
      await this.addStaffMembers({
        memberData,
        isSendInviteEmails: this.isSendInviteEmails,
      });
      if (this.isSendInviteEmails) {
        this.$toast.success("Email invitations have been sent successfully", {
          pauseOnHover: false,
          dismissible: true,
        });
      }
      return this.$router.push("/staff");
    },
  },
  async created(): Promise<void> {
    if (this.getRedirectFrom) {
      const members: any = sessionStorage.getItem("membersData");
      const membersFormatted = JSON.parse(members);
      const { email, password } = this.getTokenDetails;

      const rsp: any = await login(email, password);

      if (rsp.success) {
        sessionStorage.removeItem(constants.commonConstants.ID_TOKEN_KEY);
        sessionStorage.setItem(
          constants.commonConstants.ID_TOKEN_KEY,
          rsp.authToken
        );
        const jwtDetails: { permission: string } = jwt_decode(rsp.authToken);

        this.$store.commit("commonModule/setTokenDetails", jwtDetails);
      }

      await this.saveStaffMembers(membersFormatted);

      sessionStorage.removeItem("membersData");
      this.$store.commit("commonModule/setRedirectFrom", null);
    }

    const data = {
      isOnBoarding: this.isActiveUserHaveResourceId,
    };
    await this.getSiteStaffFromWix(data);
  },
};
