import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { IStaffRootState } from "./types";

export const state: IStaffRootState = {
  staffList: [],
  wixMembersList: [],
  staffEmailsList: [],
  memberList: [],
  selectedMembers: [],
  isOpenMissingEmailsDialog: false,
  missingEmails: [],
};

const namespaced = true;

export default {
  namespaced,
  state,
  mutations,
  getters,
  actions,
};
