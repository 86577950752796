
import moment from "moment";
import ReportsDateRange from "../reports/ReportsDateRange.vue";
export default {
  name: "TimeSheetsMainHeader",
  components: { ReportsDateRange },
  props: {
    chosenDates: {
      type: Array,
      default: (): [] => [],
    },
    isReportPage: {
      type: Boolean,
      default: false,
    },
  },
  data(): { headerDates: string[] } {
    return {
      headerDates: [],
    };
  },
  methods: {
    moment,
    updateDates(dates: string[]): void {
      this.headerDates = dates;
      const sortedDates = this.headerDates.sort();
      this.$emit("getTimeSheetsListData", sortedDates);
    },
  },
  created(): void {
    this.headerDates = this.chosenDates;
  },
};
