
import { pngList, svgList } from "../utils/assets.util";
import helper from "../utils/helper.util";
import { mapActions, mapGetters } from "vuex";
import { rootColorNames } from "../utils/root.util";
import Tracker from "./Tracker.vue";
import { IAppRoute } from "../types/appRoutes.interface";
import { ISites } from "../types/userHeader.interface";
import get from "lodash/get";
import headerHelper from "../utils/headerHelper.util";

export default {
  name: "AppHeaderMobile",
  components: { Tracker },
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      appLogo: pngList.appLogo,
      trackerLogo: svgList.tracker,
      applicationColor: rootColorNames.applicationColor,
      isShowTracker: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "commonModule/getUser",
      isUserActive: "commonModule/getIsUserActive",
      isAdmin: "commonModule/getIsAdmin",
      getCurrentSiteIndex: "commonModule/getCurrentSiteIndex",
      getTokenDetails: "commonModule/getTokenDetails",
    }),
    isRouteActive(): any {
      return (activeLinkName: string) => {
        return this.$route.path === activeLinkName;
      };
    },
    sites(): [ISites] {
      return get(this.user, "sites", []);
    },
    appRoutes(): IAppRoute[] {
      return [
        {
          name: "Reports",
          activeLinkName: "/reports",
          to: "/reports",
          disabled: false,
        },
        {
          name: "Staff Members",
          activeLinkName: "/staff",
          to: "/staff",
          disabled: false,
        },
        {
          name: "Pay Rates",
          activeLinkName: "/staffPay",
          to: "/staffPay",
          disabled: !this.isAdmin,
        },
        {
          name: "Manage Timesheets",
          activeLinkName: "/timesheets",
          to: "/timesheets",
          disabled: false,
        },
      ];
    },
    srcAvatarPath(): string | null {
      const { avatarFile: userAvatar } = this.user;
      const avatarFile = userAvatar || this.avatarFile;

      if (avatarFile) return helper.getAvatarSrc(avatarFile);
      return null;
    },
    shortName(): string {
      const isUserExists = this.user && this.user.userName;
      return isUserExists && helper.getShortName(this.user.userName);
    },
    isOpenDrawer: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    ...mapActions({
      changeSiteReq: "commonModule/changeSite",
    }),
    changeSite(shopIndex: number, instanceId: string): void {
      this.$store.commit("commonModule/setIsLoading", true);
      this.$store.commit("commonModule/setCurrentShopIndex", shopIndex);
      sessionStorage.setItem("currentShopIndex", JSON.stringify(shopIndex));
      this.changeSiteReq(instanceId)
        .then(() => {
          window.location.reload(true);
        })
        .finally(() => {
          this.$store.commit("commonModule/setIsLoading", false);
        });
    },
    changeRoute(): void {
      this.$store.commit("commonModule/setActiveRoute", this.$route.path);
    },
    logout(): void {
      sessionStorage.clear();
      this.$cookies.remove("authenticateUserDetails");
      this.$emit("input", false);
      return this.$router.push("/");
    },
    async setUserDefaultSite(instanceId: string): Promise<void> {
      this.$store.commit("commonModule/setIsLoading", true);
      try {
        const rsp: any = await headerHelper.setUserDefaultSite(
          instanceId,
          this.getTokenDetails
        );

        if (rsp.data.success) {
          const userData = {
            ...this.user,
            defaultSite: instanceId,
          };

          this.$store.commit("commonModule/setUserDetails", userData);
        }
      } catch (err) {
        console.error(`set user default site: ${err}`);
      } finally {
        this.$store.commit("commonModule/setIsLoading", false);
      }
    },
  },
};
