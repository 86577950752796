import staffConstants from "./staff.constatnts";
import commonConstants from "./common.constants";
import onboardingConstants from "./onboarding.constants";

const LABELS = {
  LOGIN_PAGE: {
    TITLE: "We’re glad to see you.",
  },
  RESET_PASSWORD: {
    TITLE: "Create a new Password",
  },
  MEMBER_REGISTER: {
    TITLE: "Create an account",
  },
  FORGOT_PASSWORD: {
    TITLE: "Forgot your password?",
    FIRST_DESCRIPTION:
      "Enter your email bellow. <br/> We'll send you an email with instructions to reset your password.",
    SECOND_DESCRIPTION:
      "For security reasons, we have sent resetting instructions to your email.",
  },
  MISSING_EMAILS_DIALOG: {
    TITLE: "Missing Emails",
    FIRST_DESCRIPTION:
      "We’re missing some of your staff’s email addresses. <br />There will be no email from us to your staff unless you've selected to send them an invitation.",
    CHECKBOX_TEXT:
      "I agree to share all the staff email addresses with Wix Bookings",
  },
  STAFF_PAYMENT_SETTINGS: {
    TITLE: "Staff Payment Models",
    FIRST_DESCRIPTION:
      " Choose which payment models you want to display on your pay rate list.",
    DIALOG_ACTIONS_PREFIX:
      "*The pay rates you enter are saved, and will be visible anytime you switch on a payment model.",
  },
  REPORTS_EMPTY_SCREEN: {
    TITLE: "Create a report to calculate payroll",
  },
  INVITE_STAFF_DIALOG: {
    TITLE: "Invite your new staff members",
    FIRST_DESCRIPTION:
      "Choose which staff you want to sync with EasyTeam. They’ll receive an email invite.",
  },
};

const HOURLY_RATE_SERVICE = "Hourly Rate";

const ALL_STAFF = "All staff";

const constants = {
  staffConstants,
  commonConstants,
  onboardingConstants,
  LABELS,
  HOURLY_RATE_SERVICE,
  ALL_STAFF,
};

export default constants;
