export const rootColorNames = {
  lightGreen: "#0FD07F",
  orange: "#F39A48",
  lightBlue: "#0FA2D0",
  purple: "#484FF3",
  yellow: "#F2C94C",
  red: "#FF1414",
  applicationColor: "#f50057",
  darkBlue: "#13123C",
};

export const hex2rgba = (hex: any, alpha = 1): string => {
  const [r, g, b] = hex.match(/\w\w/g).map((x: string) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};
