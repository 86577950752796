
import { pngList, svgList } from "../utils/assets.util";
import AppRouters from "./AppRouters.vue";
import UserHeader from "./UserHeader.vue";
import { IAppHeader } from "../types/appHeader.interface";
import Tracker from "./Tracker.vue";
import { mapGetters } from "vuex";

export default {
  name: "AppHeader",
  components: { Tracker, UserHeader, AppRouters },
  data(): IAppHeader {
    return {
      appLogo: pngList.appLogo,
      trackerLogo: svgList.tracker,
      isShowTracker: false,
    };
  },
  computed: {
    ...mapGetters({
      isUserActive: "commonModule/getIsUserActive",
    }),
  },
};
