
import BaseDialog from "../BaseDialog.vue";
import { mapGetters } from "vuex";
import Member from "../Member.vue";
import {
  ITimeSheetsDetails,
  ITimeSheetsViewDialogHeaders,
} from "../../types/timeSheets.interface";
import get from "lodash/get";
import moment from "moment";
import reportUtil from "../../utils/reports.util";
import helper from "../../utils/helper.util";
import { IReportsBookingView } from "../../types/reportsPage.interface";
export default {
  name: "ReportsBookingView",
  components: { Member, BaseDialog },
  props: {
    memberDetails: {
      type: Object,
    },
    value: {
      type: Boolean,
    },
    chosenDates: {
      type: Array,
    },
  },
  data(): IReportsBookingView {
    return {
      sortBy: "start",
      userBookingCardData: [],
      sortDesc: true,
      totalPayOut: 0,
      countByMap: {
        Bookings: "Booked Participants",
        CheckIns: "Checked-In Participants",
      },
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: "commonModule/getIsAdmin",
      getCurrentCurrency: "commonModule/getSiteCurrency",
      getSiteSettings: "payRatesModule/getSettings",
    }),
    tooltipFormatted() {
      return (tooltip: string): string => {
        return tooltip
          .replaceAll("#", this.getCurrentCurrency.mark)
          .replaceAll(",", "<br/>");
      };
    },
    getCurrency() {
      return (rate: string): string => {
        const isPercentage = rate[rate.length - 1] === "%";
        return rate !== "N/A" && !isPercentage
          ? this.getCurrentCurrency.mark
          : "";
      };
    },
    dateFormatted() {
      return (date: string): string => {
        const shiftDate = new Date(date);
        return moment(shiftDate).format("ddd, DD MMM, HH:mm");
      };
    },
    getViewData() {
      return this.userBookingCardData;
    },
    headers(): ITimeSheetsViewDialogHeaders[] {
      return [
        { text: "Date & Time", value: "start", align: "start", width: 150 },
        { text: "Service", value: "serviceName", align: "start", width: 150 },
        {
          text: this.countByMap[this.getSiteSettings.countBy],
          value: "participantsCount",
          align: "start",
          width: 150,
        },
        {
          text: "Session Rate",
          value: "sessionRate",
          align: "start",
          width: 100,
        },
        {
          text: "Participants Payout",
          value: "participantPayout",
          align: "start",
          width: 150,
        },
        { text: "Payout", value: "payOut", align: "start", width: 50 },
      ];
    },
    isOpenBookingDialog: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    memberDetails: {
      handler(val: ITimeSheetsDetails[] & { sessionsCardTotal: number }): void {
        if (val) {
          this.totalPayOut =
            val.sessionsCardTotal && val.sessionsCardTotal.toFixed(2);
          this.userBookingCardData = get(val, "sessionsCardData", []);
        }
      },
      deep: true,
    },
  },
  methods: {
    async getBookingCSV(): Promise<void> {
      const [fromDate, toDate] = this.chosenDates;
      const dateRange = [
        moment(fromDate).format("LL"),
        moment(toDate).format("LL"),
      ].join("-");

      const viewDataSorted = this.getViewData.sort((a: any, b: any) => {
        return a.start.localeCompare(b.start);
      });

      const csvString = viewDataSorted.map((booking: any) => {
        return {
          Date: moment(booking.start).format("ddd, DD MMM, HH:mm"),
          Service: booking.serviceName,
          [this.countByMap[this.getSiteSettings.countBy]]:
            booking.participantsCount,
          Rate: booking.sessionRate,
          "Participants Payout": booking.participantPayout,
          Payout: booking.payOut,
        };
      });

      const csvStringFormatted = await reportUtil.getResultCSVString(csvString);

      helper.downloadCSV(
        csvStringFormatted,
        `${this.memberDetails.userName}-Bookings Payroll-${dateRange}`
      );
    },
  },
  created(): void {
    this.totalPayOut = this.memberDetails.sessionsCardTotal || 0;
    this.userBookingCardData = get(this.memberDetails, "sessionsCardData", []);
  },
};
