import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
import { Route } from "vue-router";
import { getIdToken } from "../utils/auth";
import constants from "./utils/constants/constants.util";
import Toast, { POSITION } from "vue-toastification";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Stonly from "./plugins/stonly";
import Clarity from "./plugins/clarity";
import Crisp from "./plugins/crisp";
import config from "../config";

Vue.use(Toast, {
  // Setting the global default position
  position: POSITION.TOP_CENTER,
});

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(Stonly);
Vue.use(Clarity);
Vue.use(Crisp);

// set default config
Vue.$cookies.config("7d");

Sentry.init({
  Vue,
  dsn: config.SENTRY_DNS,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [config.WIX_DNS, /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true,
});

new Vue({
  router,
  store,
  vuetify,
  watch: {
    $route: {
      handler(to: Route): void {
        if (
          !getIdToken() &&
          !constants.commonConstants.PUBLIC_PATHS.includes(to.path)
        )
          this.$router.push("/");
      },
      immediate: true,
    },
  },
  render: (h) => h(App),
}).$mount("#app");
