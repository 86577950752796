
import InviteStaff from "../../components/InviteStaff.vue";
import { IStaff } from "../../types/inviteStaff.interface";
import constants from "../../utils/constants/constants.util";

export default {
  name: "OnBoardingInviteStaff",
  props: {
    value: {
      type: Array,
      default: (): any => [],
    },
    isOpenMissingEmailsDialog: {
      type: Boolean,
      default: false,
    },
    missingEmails: {
      type: Array,
      default: (): any => [],
    },
    isOnBoarding: {
      type: Boolean,
      default: false,
    },
  },
  data(): { labels: object } {
    return {
      labels: constants.onboardingConstants.ONBOARDING_LABELS,
    };
  },
  components: { InviteStaff },
  computed: {
    members: {
      get(): string {
        return this.value;
      },
      set(val: IStaff): void {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    saveStaffMembers(memberData: IStaff[]): void {
      this.$emit("saveStaffMembers", memberData);
    },
    redirectToPackageUpgrade(memberData: IStaff[]): void {
      this.$emit("redirectToPackageUpgrade", memberData);
    },
  },
};
