
import { mapGetters } from "vuex";
import { IService, IStaffPayServices } from "../../types/staffPay.interface";

export default {
  name: "PayRatesCopyMenu",
  props: {
    serviceName: {
      type: String,
    },
    serviceType: {
      type: String,
    },
    mode: {
      type: String,
      default: "paymentType",
    },
    payRates: {
      type: Object,
    },
    memberEmail: {
      type: String,
    },
    isOnBoarding: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    isDisplayTooltip: boolean;
    selected: [];
    selectedAll: string;
    servicesTitleMap: { [key: string]: string };
    menu: null;
  } {
    return {
      selectedAll: "",
      selected: [],
      menu: null,
      servicesTitleMap: {
        Appointment: "Appointments",
        Class: "Classes",
        Course: "Courses",
      },
      isDisplayTooltip: false,
    };
  },
  computed: {
    ...mapGetters({
      siteServicesList: "commonModule/getSiteServicesList",
      siteTable: "onBoardingModule/getTableData",
      payRatePageData: "payRatesModule/getPayRatePageData",
    }),
    getSelected: {
      get(): string[] {
        return this.selected;
      },
      set(val: string[]): void {
        this.selected = val;
        if (val.length !== this.getFilteredServices.length) {
          this.selectedAll = "";
        } else {
          this.selectedAll = `All ${this.servicesTitleMap[this.serviceType]}`;
        }
      },
    },
    getSelectedAll: {
      get(): string {
        return this.selectedAll;
      },
      set(val: string): void {
        if (val) {
          this.selectedAll = val;
          this.selected = this.getFilteredServices.map(
            (service: { name: string }) => service.name
          );
        } else {
          this.selected = [];
        }
      },
    },
    getFilteredServices(): IService[] {
      return this.siteServicesList.filter(
        (service: { serviceType: string }) =>
          service.serviceType === this.serviceType
      );
    },
  },
  methods: {
    changePayRates(
      services: IStaffPayServices[],
      email: string
    ): IStaffPayServices[] {
      if (email === this.memberEmail) {
        for (const service in services) {
          if (this.selectedAll !== "") {
            const serviceNames = this.getFilteredServices.map(
              (service: { name: string }) => service.name
            );

            if (serviceNames.includes(service)) {
              services[service] = {
                serviceId: services[service].serviceId,
                isParticipant: this.payRates.isParticipant,
                isSelected: this.payRates.isSelected,
                paymentType: this.payRates.paymentType,
                payRates: this.payRates.payRates,
              };
            }
          } else if (this.selected.length > 0) {
            if (this.selected.includes(service)) {
              services[service] = {
                serviceId: services[service].serviceId,
                isParticipant: this.payRates.isParticipant,
                isSelected: this.payRates.isSelected,
                paymentType: this.payRates.paymentType,
                payRates: this.payRates.payRates,
              };
            }
          }
        }
      }

      return services;
    },
    changeServiceType(services: IStaffPayServices[]): IStaffPayServices[] {
      const tableData = this.isOnBoarding
        ? this.siteTable
        : this.payRatePageData;
      for (const service in services) {
        if (this.selectedAll !== "") {
          const serviceNames = this.getFilteredServices.map(
            (service: { name: string }) => service.name
          );

          if (serviceNames.includes(service)) {
            const { paymentType, isParticipant } =
              tableData[0].services[this.serviceName];
            services[service].paymentType = paymentType;
            services[service].isParticipant = isParticipant;
          }
        } else if (this.selected.length > 0) {
          const { paymentType, isParticipant } =
            tableData[0].services[this.serviceName];
          if (this.selected.includes(service)) {
            services[service].paymentType = paymentType;
            services[service].isParticipant = isParticipant;
          }
        }
      }

      return services;
    },
    copyPaymentType(): void {
      const tableData = this.isOnBoarding
        ? this.siteTable
        : this.payRatePageData;

      const formattedTable = tableData.map((member: any) => {
        return {
          ...member,
          services:
            this.mode === "payRates"
              ? this.changePayRates(member.services, member.email)
              : this.changeServiceType(member.services),
        };
      });

      if (this.isOnBoarding)
        this.$store.commit("onBoardingModule/setTableData", formattedTable);
      else {
        this.$store.commit("payRatesModule/setPayRatePageData", formattedTable);
      }
      this.isDisplayTooltip = true;

      setTimeout(() => {
        this.isDisplayTooltip = false;
        this.menu = false;
        this.selected = [];
        this.selectedAll = "";
      }, 1000);
    },
  },
};
