
import {
  IShowUserDetails,
  IStaffDetails,
  ISwatchStyle,
} from "../../types/staffPage.interface";
import { mapActions, mapGetters } from "vuex";
import {
  IGeneralData,
  ISelectedDepartment,
} from "../../types/common.interface";
import get from "lodash/get";

export default {
  name: "ShowUserDetails",
  props: {
    member: {
      type: Object,
    },
  },
  data(): IShowUserDetails {
    return {
      name: "",
      email: "",
      selectedPosition: "",
      selectedDepartment: {} as ISelectedDepartment,
      siteDepartments: [],
      sitePositions: [],
      memberDetails: {} as IStaffDetails,
      generalDataList: {} as IGeneralData,
      permissionsList: [
        { name: "Admin", value: 1, id: "admin" },
        { name: "Staff", value: 0, id: "staff" },
      ],
      permissionsMap: {
        Staff: 0,
        Admin: 1,
      },
      memberPermission: "",
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: "commonModule/getIsAdmin",
      getGeneralDataByName: "commonModule/getGeneralDataByName",
      user: "commonModule/getUser",
      getCurrentCurrency: "commonModule/getSiteCurrency",
      getTokenDetails: "commonModule/getTokenDetails",
    }),
    getSitePositions(): string[] {
      return this.sitePositions;
    },
    getSiteDepartments(): ISelectedDepartment[] {
      return this.siteDepartments.map((item: ISelectedDepartment) => {
        return {
          ...item,
          color: item.color === "" ? "#f50057" : item.color,
        };
      });
    },
    isActiveUser(): boolean {
      return this.memberDetails.email !== this.getTokenDetails.email;
    },
    swatchStyle() {
      return (color: string): ISwatchStyle => {
        return {
          backgroundColor: color,
          cursor: "pointer",
          height: "20px",
          width: "20px",
          borderRadius: "4px",
          transition: "border-radius 200ms ease-in-out",
        };
      };
    },
  },
  watch: {
    member: {
      handler(newValue: IStaffDetails): void {
        this.memberDetails = newValue;

        const { userName, email, permission, position, department } =
          this.memberDetails;

        const departmentList = this.getGeneralDataByName("departments") || [];
        const departmentData = departmentList.filter(
          (color: { name: string }) => color.name === department
        );

        this.name = userName || "";
        this.email = email || "";
        this.memberPermission = this.permissionsMap[permission];
        this.selectedPosition = position;
        this.selectedDepartment = departmentData[0] || {
          name: "Management",
          color: "#ff0000",
        };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      deleteSiteDepartment: "commonModule/deleteDepartment",
      deleteSitePosition: "commonModule/deletePosition",
      updateStaffMemberData: "staffModule/updateStaffMemberData",
      addPositions: "commonModule/addPositions",
      addDepartments: "commonModule/addDepartments",
    }),
    async saveUserDetails(): Promise<void> {
      const staffMemberData = {
        position: this.selectedPosition,
        permission: Object.keys(this.permissionsMap).find(
          (key) => this.permissionsMap[key] === this.memberPermission
        ),
        department: this.selectedDepartment ? this.selectedDepartment.name : "",
        email: this.email || "",
        name: this.name || "",
      };

      await this.updateStaffMemberData(staffMemberData);
      await this.addPositions({ positions: this.getSitePositions });
      await this.addDepartments({ departments: this.getSiteDepartments });

      this.$toast.success("Details updated successfully", {
        pauseOnHover: false,
        dismissible: true,
      });

      this.$emit("closeExpand", this.member, true);
    },
    deleteDepartment(item: { item: ISelectedDepartment }): void {
      const departmentData = item.item;

      this.siteDepartments = this.siteDepartments.filter(
        (department: ISelectedDepartment) =>
          department.name !== departmentData.name
      );

      const data = {
        department: departmentData.name,
      };

      this.deleteSiteDepartment(data);
    },
    deletePosition(item: { item: string }): void {
      const positionName = item.item;
      this.sitePositions = this.sitePositions.filter(
        (position: string) => position !== positionName
      );

      const data = {
        position: positionName,
      };

      this.deleteSitePosition(data);
    },
    addDepartment(): void {
      const department = { name: this.selectedDepartment, color: "" };
      this.siteDepartments.push(department);
    },
    addPosition(): void {
      this.sitePositions.push(this.selectedPosition);
    },
  },
  created(): void {
    this.generalDataList = this.getGeneralDataByName("all");
    this.sitePositions = get(this.generalDataList, "positions", []);
    this.siteDepartments = get(this.generalDataList, "departments", []);
  },
};
