import { MutationTree } from "vuex";
import { IWorkClockRootState } from "./types";

export const mutations: MutationTree<IWorkClockRootState> = {
  setIsUserActive(state: IWorkClockRootState, data: boolean) {
    state.isUserActive = data;
  },
  setShiftDetails(state, data) {
    state.currentShiftDetails = data;
  },
  setTotalShiftTIme(state, data) {
    state.totalShiftTime = data;
  },
};
