import axios, {
  AxiosBasicCredentials,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from "axios";
import constants from "../src/utils/constants/constants.util";
import config from "../config";
import * as Sentry from "@sentry/vue";

const defaultHeaders = (authToken: string | AxiosBasicCredentials) => ({
  Authorization: `Bearer ${getIdToken("") || authToken}`,
  "Content-Type": "application/json; charset=utf-8",
  EZTAuth: getIdToken("") || authToken,
});
const defaultFetchOptions = (method: Method | undefined) => ({
  method: method || "GET",
  mode: "cors",
  cache: "no-cache",
  body: {},
  headers: {},
});

export function axiosRequest(
  path: string,
  options?: AxiosRequestConfig & any
): Promise<AxiosResponse> {
  const authToken = options.authToken ? options.authToken : null;
  const headers = defaultHeaders(authToken);
  const fetchOptions = defaultFetchOptions(options.method);
  if (options.body) fetchOptions.body = options.body;

  if (options.headers) {
    options.headers.forEach(([key, val]: [string, string]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headers[key] = val;
      if (val === null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete headers[key];
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fetchOptions.headers = new Headers(headers);

  return axios({
    method: fetchOptions.method,
    url: config.BASE_URL + path,
    headers,
    data: fetchOptions.body,
  })
    .then((response) => {
      if (response.status === 200) return response.data;
    })
    .catch((err) => {
      Sentry.captureException(`axiosRequest error: ${err}`);
    });
}

export function getIdToken(token?: string): string | null {
  if (token) return token;
  return sessionStorage.getItem(constants.commonConstants.ID_TOKEN_KEY);
}

export const isLoggedIn = (): boolean => {
  const idToken = getIdToken();
  return !!idToken;
};

export const login = async (
  email: string,
  password: string,
  instanceId?: string
): Promise<AxiosResponse> => {
  const rsqData = {
    ...(instanceId && { instanceId }),
    email,
    password,
  };
  const options = {
    method: "POST",
    body: JSON.stringify(rsqData),
  };

  return axiosRequest("login/login", options)
    .then((res) => {
      if (res.data.success)
        sessionStorage.setItem(
          constants.commonConstants.ID_TOKEN_KEY,
          res.data.authToken
        );

      return res.data;
    })
    .catch((err: Error) => console.info("Login Failed", err));
};

export const refreshingData = (): boolean => {
  return !!(
    sessionStorage.getItem("userDetails") &&
    sessionStorage.getItem("generalData")
  );
};
