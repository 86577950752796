
import BaseDialog from "../components/BaseDialog.vue";
import { pngList } from "../utils/assets.util";
import { IMissingEmailsDialog, IStaff } from "../types/inviteStaff.interface";
import constants from "../utils/constants/constants.util";
import { IDialogActions } from "../types/baseDialog.interface";
import formRules from "../utils/formRules.util";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MissingEmailsDialog",
  components: { BaseDialog },
  props: {
    missingEmails: {
      type: Array,
      default: (): IStaff[] => [],
    },
    value: {
      type: Boolean,
    },
  },
  data(): IMissingEmailsDialog {
    return {
      placeholder: pngList.placeholder,
      labels: constants.LABELS,
      formRules: formRules(),
      membersData: [] as IStaff[],
    };
  },
  computed: {
    ...mapGetters({
      getTokenDetails: "commonModule/getTokenDetails",
      getMemberList: "staffModule/getMemberList",
      staffList: "staffModule/getStaffListData",
      getUser: "commonModule/getUser",
      getSiteStaffFromWix: "staffModule/getWixMembersList",
      getCurrentSiteIndex: "commonModule/getCurrentSiteIndex",
    }),
    isOpenMissingEmailsDialog: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit("input", val);
      },
    },
    isDisplayButton(): boolean {
      const totalUsers = this.getMemberList.length + this.staffList.length;
      return totalUsers > this.getTokenDetails.maxUsers;
    },
    dialogActions(): IDialogActions[] {
      return [
        {
          label: "Cancel",
          outlined: true,
          onClick: () => {
            this.$emit("closeMissingEmailDialog");
          },
        },
        {
          label: !this.isDisplayButton ? "Add Staff" : "Upgrade",
          color: !this.isDisplayButton ? "primary" : "#1479f2",
          textColor: "white",
          onClick: () => {
            if (this.$refs.form.validate()) {
              if (this.isDisplayButton) {
                return this.$emit("redirectToPackageUpgrade");
              }

              const activeUserFromStaff = this.staffList.find((user: any) => {
                const { email } = this.getTokenDetails;
                return user.email === email;
              });

              const activeUser = this.missingEmails.find((user: any) => {
                return (
                  user.email === activeUserFromStaff?.email &&
                  !activeUserFromStaff?.resourceId
                );
              });

              if (activeUser) {
                const activeUserDataFromWix = this.getSiteStaffFromWix.find(
                  (member: IStaff) => {
                    return member.email === activeUser.email;
                  }
                );

                this.updateStaffMemberData(activeUserDataFromWix);
              }

              const existingUsers = this.staffList.filter((staff: any) => {
                return this.missingEmails.some(
                  (user: any) => staff.email === user.email && !user.resourceId
                );
              });

              if (!existingUsers.length) {
                this.$emit("saveStaffMembers", this.missingEmails);
                this.$emit("closeMissingEmailDialog");
              } else {
                const isUsers = existingUsers.length > 1;
                const userEmails = existingUsers.map(
                  (staff: any) => staff.email
                );

                const toastText = `The ${
                  isUsers ? "emails" : "email"
                } ${userEmails.join(", ")} already exists.`;
                this.$toast.warning(toastText, {
                  pauseOnHover: false,
                  dismissible: true,
                });
              }
            }
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      updateStaffMemberData: "staffModule/updateStaffMemberData",
    }),
  },
};
