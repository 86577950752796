import { GetterTree } from "vuex";
import { IPayRates } from "./types";
import { RootState } from "../../types";

export const getters: GetterTree<IPayRates, RootState> = {
  getSettings: (state) => state.settings,
  getPaymentTypes: (state) => state.paymentTypes,
  getTabIndex: (state) => state.tabIndex,
  getPayRatePageData: (state) => state.payRatePageData,
  getIsDisabledPayRates: (state) => state.isDisabledPayRates,
  getServiceNamesOnDisabled: (state) => state.serviceNamesOnDisabled,
  getIsPayRatesExists: (state) => state.isPayRatesExists,
};
