import { ActionTree } from "vuex";
import { RootState } from "../../types";
import timeSheetsApi from "../../../utils/api/timeSheets.api";
import { ITimeSheetsRootState } from "./types";
import { AxiosResponse } from "axios";
import commonHelper from "../../../utils/commonHelper.util";

export const actions: ActionTree<ITimeSheetsRootState, RootState> = {
  async getTimeSheetsPageData(
    { commit, rootState, rootGetters },
    payload
  ): Promise<void> {
    const {
      user: { sites, defaultSite },
      tokenDetails,
      currentShopIndex,
    } = rootState.commonModule;
    commit("commonModule/setIsLoading", true, { root: true });

    const { fromDate, toDate } = commonHelper.getDatesRange();

    const data = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
      email: tokenDetails.email,
      fromDate: payload?.fromDate || fromDate,
      toDate: payload?.toDate || toDate,
    };

    try {
      const rsp: any = await timeSheetsApi.getTimeSheetsPageData(data);
      if (rsp.success) {
        commit(
          "setTimeSheetsList",
          rootGetters["commonModule/sortByOnlineUser"](rsp.data.timeSheets)
        );
      }
    } catch (err) {
      console.error(`get time sheets request: ${err}`);
    } finally {
      commit("commonModule/setIsLoading", false, { root: true });
      // eslint-disable-next-line no-undef,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      $crisp.push(["do", "chat:show"]);
    }
  },
  async addShift({ rootState }, payload): Promise<AxiosResponse> {
    const {
      user: { sites },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      shiftData: payload,
      instanceId: sites[currentShopIndex].instanceId,
    };

    return timeSheetsApi.addShift(data);
  },
  async deleteShift({ rootState }, payload): Promise<AxiosResponse> {
    const {
      user: { sites },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      shiftId: payload,
      instanceId: sites[currentShopIndex].instanceId,
    };

    return timeSheetsApi.deleteShift(data);
  },
  async updateShift({ rootState }, payload): Promise<AxiosResponse> {
    const {
      user: { sites },
      currentShopIndex,
    } = rootState.commonModule;

    const shiftId = payload.shiftId;

    delete payload.shiftId;

    const data = {
      shiftId,
      shiftData: payload,
      instanceId: sites[currentShopIndex].instanceId,
    };

    return timeSheetsApi.updateShift(data);
  },
};
