
import ReportsMainHeader from "../components/reports/ReportsMainHeader.vue";
import ReportsFooter from "../components/reports/ReportsFooter.vue";
import ReportsTable from "../components/reports/ReportsTable.vue";
import ReportsEmptyScreen from "../components/reports/ReportsEmptyScreen.vue";
import ReportsSettings from "../components/reports/ReportsSettings.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { IReportPage } from "../types/reportsPage.interface";
import constants from "../utils/constants/constants.util";
import reportUtil from "../utils/reports.util";

export default {
  name: "Reports",
  components: {
    ReportsSettings,
    ReportsEmptyScreen,
    ReportsTable,
    ReportsFooter,
    ReportsMainHeader,
  },
  data(): { isOpenSettingsDialog: boolean; chosenDates: string[] } {
    return {
      isOpenSettingsDialog: false,
      chosenDates: [],
    };
  },
  computed: {
    ...mapGetters({
      getReportsPage: "reportsModule/getReportsPageData",
      getMemberReports: "reportsModule/getReports",
      getTokenDetails: "commonModule/getTokenDetails",
      getUser: "commonModule/getUser",
      getSettingsData: "payRatesModule/getSettings",
      getCurrentSiteIndex: "commonModule/getCurrentSiteIndex",
      payRatePageData: "payRatesModule/getPayRatePageData",
    }),
  },
  methods: {
    ...mapActions({
      getReports: "reportsModule/getReportsData",
      getSiteSettings: "payRatesModule/getSiteSettings",
      getReportsPageData: "reportsModule/getReportsPageData",
      getPayRatesData: "payRatesModule/getPayRatesPageData",
    }),
    async getSettings(): Promise<void> {
      const rsp = await this.getSiteSettings();
      this.settingsData = {
        ...rsp.data,
        isPerParticipant: rsp.data.isPerParticipant ? "Yes" : "No",
      };

      this.$store.commit("payRatesModule/setSettings", this.settingsData);
    },
    async getReportData(
      reportData = { dates: [], calculate: "Both", emails: ["ALL"] }
    ): Promise<void> {
      this.chosenDates = reportData.dates;

      const displayBy =
        this.getSettingsData.payFrequency === "Monthly" ? "month" : "week";
      let fromDate = moment().startOf(displayBy).startOf("day").format();
      let toDate = moment().endOf(displayBy).endOf("day").format();

      if (!reportData?.dates) {
        this.chosenDates = [fromDate, toDate];
      }

      if (reportData.dates && reportData.dates.length > 0) {
        fromDate = moment(reportData.dates[0]).startOf("day").format();
        toDate = moment(reportData.dates[1]).endOf("day").format();
      }

      const emailsData =
        reportData.emails.length === this.getReportsPage.length ||
        reportData.emails.includes(constants.ALL_STAFF)
          ? ["ALL"]
          : this.getReportsPage
              .filter((report: IReportPage) => {
                return reportData.emails.includes(report.email);
              })
              .map((item: IReportPage) => item.email);

      const data = {
        fromDate: new Date(fromDate).toISOString(),
        toDate: new Date(toDate).toISOString(),
        calculate: reportData.calculate,
        emails: emailsData,
      };
      await this.getReports(data);
    },
  },
  async created(): Promise<void> {
    this.$store.commit("onBoardingModule/setIsOnBoarding", false);

    await this.getReportsPageData();
    if (this.getTokenDetails.status !== "Blocked") {
      try {
        this.$store.commit("commonModule/setIsLoading", true, { root: true });
        await Promise.all([this.getSettings(), this.getPayRatesData()]);

        this.$store.commit(
          "payRatesModule/setIsPayRatesExists",
          reportUtil.isPayRatesExists(this.payRatePageData)
        );
      } catch (err) {
        console.error(`Report page: ${err}`);
      } finally {
        this.$store.commit("commonModule/setIsLoading", false, { root: true });
      }
    }
  },
};
