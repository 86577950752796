import Vue from "vue";
import Vuex from "vuex";
import staffModule from "./modules/staff";
import commonModule from "./modules/common";
import timeSheetsModule from "./modules/timeSheets";
import workClockModule from "./modules/workClock";
import onBoardingModule from "./modules/onboarding";
import payRatesModule from "./modules/payRates";
import reportsModule from "./modules/reports";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    staffModule,
    commonModule,
    timeSheetsModule,
    workClockModule,
    onBoardingModule,
    payRatesModule,
    reportsModule,
  },
  plugins: [
    createPersistedState({
      paths: [
        "commonModule.user",
        "commonModule.generalData",
        "commonModule.tokenDetails",
        "staffModule.staffList",
        "commonModule.currentShopIndex",
        "commonModule.activeRoute",
        "onBoardingModule.isOnBoarding",
        "commonModule.redirectFrom",
        "commonModule.isUserActive",
        "payRatesModule.isPayRatesExists",
      ],
      storage: window.sessionStorage,
    }),
  ],
});
