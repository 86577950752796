const twoDigits = (number: number): string => {
  return `0${number}`.slice(-2);
};

const formatNumberToTimeString = (number: number): string => {
  const hours = twoDigits(Math.floor(number));
  const minutes = twoDigits(Math.floor((number % 1) * 60));
  const seconds = twoDigits(Math.floor((number % 1) * 3600) % 60);

  return `${hours}:${minutes}:${seconds}`;
};

const formatUtil = {
  twoDigits,
  formatNumberToTimeString,
};

export default formatUtil;
