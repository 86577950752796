
import BackButton from "../BackButton.vue";
export default {
  name: "OnBoardingStepperHeader",
  components: { BackButton },
  props: {
    step: {
      type: Number,
      default: 25,
    },
    stepDetails: {
      type: Object,
    },
    isDisplayBackButton: {
      type: Boolean,
      default: false,
    },
    isPerHour: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stepperTitle(): string {
      return `${this.stepDetails[this.step].step} / ${
        this.isPerHour ? "3" : "4"
      }`;
    },
  },
};
