
import AppHeader from "./components/AppHeader.vue";
import ProgressBar from "./components/ProgressBar.vue";
import constants from "./utils/constants/constants.util";
import { mapActions, mapGetters } from "vuex";
import { isLoggedIn } from "../utils/auth";

import "vue-toastification/dist/index.css";
import { IStaff } from "./types/inviteStaff.interface";
import { emailReg } from "./utils/formRules.util";
import AppHeaderMobile from "./components/AppHeaderMobile.vue";
import { pngList } from "./utils/assets.util";
import jwt_decode from "jwt-decode";
import { login } from "../utils/auth";
import BaseAlert from "./components/BaseAlert.vue";
import config from "../config";

export default {
  name: "App",
  components: { BaseAlert, AppHeaderMobile, ProgressBar, AppHeader },
  data(): { publicPaths: string[]; drawer: false; appLogo: string } {
    return {
      publicPaths: constants.commonConstants.PUBLIC_PATHS,
      drawer: false,
      appLogo: pngList.appLogo,
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: "commonModule/getIsAdmin",
      user: "commonModule/getUser",
      getCurrentSiteIndex: "commonModule/getCurrentSiteIndex",
      getTokenDetails: "commonModule/getTokenDetails",
      getMemberList: "staffModule/getMemberList",
      staffList: "staffModule/getStaffListData",
      getSelectedMember: "staffModule/getSelectedMember",
      getActiveRoute: "commonModule/getActiveRoute",
      getIsOnBoarding: "onBoardingModule/getIsOnBoarding",
      getReportsPageData: "reportsModule/getReportsPageData",
      getRedirectFrom: "commonModule/getRedirectFrom",
      getPayRatePageData: "payRatesModule/getPayRatePageData",
      getIsPayRatesExists: "payRatesModule/getIsPayRatesExists",
    }),
    isDisplayUpgradeAlert(): boolean {
      return (
        (this.getTokenDetails.status === "Blocked" && this.isShowHeader) ||
        this.isMemberUpgrade
      );
    },
    isDisplayMobileDrawer(): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return false;
      }
    },
    isMemberUpgrade(): Boolean {
      const totalMembers = this.getMemberList.length + this.staffList.length;
      return (
        this.$route.path === "/inviteStaff" &&
        totalMembers > this.getTokenDetails.maxUsers
      );
    },
    path(): string {
      return this.$route.path;
    },
    isShowHeader(): boolean {
      return !this.publicPaths.includes(this.path);
    },
    isStaffListValid(): boolean {
      const filteredStaff = this.getSelectedMember.filter(
        (item: IStaff) => item.email && item.email.includes("@")
      );

      return filteredStaff.length === this.getSelectedMember.length;
    },
    alertText(): string {
      return this.isAdmin
        ? "Upgrade now to manage your employees' payroll"
        : "Unable to generate reports. Please contact your business owner for more information.";
    },
  },
  methods: {
    ...mapActions({
      getEmployeesStatusForShopByEmails:
        "workClockModule/getEmployeesStatusForShopByEmails",
      loginToPlatform: "commonModule/loginToPlatform",
      refreshToken: "commonModule/refreshToken",
    }),
    redirectReq(): void {
      const instanceId = this.user?.sites[this.getCurrentSiteIndex]?.instanceId;
      const wixUrl = `https://www.wix.com/apps/upgrade/${config.WIX_APP_ID}?appInstanceId=${instanceId}`;

      window.open(wixUrl, "_blank");
    },
    async redirectToPackageUpgrade(): Promise<void> {
      if (this.$route.path === "/inviteStaff") {
        if (this.isStaffListValid) {
          this.redirectReq();
        } else {
          const missingEmails = this.getSelectedMember.filter(
            (item: IStaff) => !item.email || !emailReg.test(item.email)
          );

          this.$store.commit("staffModule/setMissingEmails", missingEmails);
          this.$store.commit("staffModule/setIsOpenMissingEmailsDialog", true);
        }
      } else {
        this.redirectReq();
      }
    },
  },
  async created(): Promise<void> {
    if (isLoggedIn() && this.$route.path !== "/onboarding/*") {
      if (this.getRedirectFrom) {
        const { email, password } = this.getTokenDetails;
        const rsp: any = await login(email, password);

        if (rsp.success) {
          sessionStorage.removeItem(constants.commonConstants.ID_TOKEN_KEY);
          sessionStorage.setItem(
            constants.commonConstants.ID_TOKEN_KEY,
            rsp.authToken
          );
          const jwtDetails: { permission: string } = jwt_decode(rsp.authToken);

          this.$store.commit("commonModule/setTokenDetails", jwtDetails);

          if (this.getRedirectFrom === "/inviteStaff") {
            return this.$router.push("/inviteStaff");
          } else {
            this.$store.commit("commonModule/setRedirectFrom", null);
          }
        }
      }
      await this.getEmployeesStatusForShopByEmails();

      if (this.$route.path === "/login/login") {
        if (this.getIsOnBoarding) {
          return this.$router.push("/onboarding");
        }
        return this.$router.push("/reports");
      }
    } else {
      const authenticateUserDetails = this.$cookies.get(
        "authenticateUserDetails"
      );

      if (authenticateUserDetails) {
        const { email, password } = authenticateUserDetails;
        this.email = email;
        this.password = password;

        const data = {
          email: this.email,
          password: this.password,
          redirectTo: "/reports",
        };

        await this.loginToPlatform(data);
      }

      const { email, access } = this.$route.query;

      if (email) {
        const data = {
          email,
          password: access,
          isHasPassword: true,
          redirectTo: "/reports",
        };

        await this.loginToPlatform(data);
      }
    }
  },
};
