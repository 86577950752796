
import formRules from "../../utils/formRules.util";
import { pngList } from "../../utils/assets.util";
import { rootColorNames } from "../../utils/root.util";
import { mapActions, mapGetters } from "vuex";
import constants from "../../utils/constants/constants.util";
import { IOnboardingTypes } from "../../types/onboarding.interface";

export default {
  name: "LoginPage",
  props: {
    registrationMode: {
      type: String,
    },
    isRegisterViaSignIn: {
      type: Boolean,
    },
    userToken: {
      type: String,
    },
  },
  data(): IOnboardingTypes {
    return {
      password: "",
      email: "",
      formRules: formRules(),
      isValid: "",
      isShowPass: false,
      appLogo: pngList.appLogo,
      isRememberMe: false,
      applicationColor: rootColorNames.applicationColor,
      loginListData: constants.onboardingConstants.LOGIN_LIST_DATA,
      appName: constants.commonConstants.APP_NAME,
      loginSubtitle: constants.onboardingConstants.LOGIN_SUBTITLE,
    };
  },
  computed: {
    ...mapGetters({
      isApiError: "commonModule/getIsApiError",
      errorMessage: "commonModule/getErrorMessage",
    }),
    height(): number | undefined {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 4;
        case "sm":
          return 400;
        case "md":
          return 6;
        case "lg":
          return 600;
        default:
          return 7;
      }
    },
  },
  created(): void {
    const authenticateUserDetails = this.$cookies.get(
      "authenticateUserDetails"
    );

    if (authenticateUserDetails)
      this.isRememberMe = authenticateUserDetails.isRememberMe;
  },
  methods: {
    ...mapActions("commonModule", ["loginToPlatform"]),
    handleSubmit(): void {
      const data = {
        email: this.email,
        password: this.password,
        redirectTo: "/reports",
      };
      if (this.$refs.form.validate()) this.loginToPlatform(data);
    },
  },
};
