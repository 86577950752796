
import BaseDialog from "../BaseDialog.vue";
import constants from "../../utils/constants/constants.util";
import { IDeleteStaffDialog } from "../../types/staffPage.interface";
import { IDialogActions } from "../../types/baseDialog.interface";
import { mapActions, mapGetters } from "vuex";
import jwt_decode from "jwt-decode";
import { login } from "../../../utils/auth";

export default {
  name: "DeleteStaffDialog",
  components: { BaseDialog },
  props: {
    value: {
      type: Boolean,
    },
    dialogText: {
      type: String,
    },
    memberEmail: {
      type: String,
    },
  },
  data(): IDeleteStaffDialog {
    return {
      labels: constants.staffConstants.STAFF_LABELS,
    };
  },
  computed: {
    ...mapGetters({
      getTokenDetails: "commonModule/getTokenDetails",
    }),
    isOpenDeleteDialog: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit("input", val);
      },
    },
    dialogActions(): IDialogActions[] {
      return [
        {
          label: "no",
          outlined: true,
          color: "primary",
          onClick: () => this.$emit("closeDialog"),
        },
        {
          label: "delete",
          color: "primary",
          onClick: () => this.deleteMember(),
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      deleteMemberReq: "staffModule/deleteMember",
      getStaffList: "staffModule/getStaffList",
      refreshToken: "commonModule/refreshToken",
    }),
    deleteMember(): void {
      this.$store.commit("commonModule/setIsLoading", true);
      this.deleteMemberReq(this.memberEmail)
        .then(async (rsp: any) => {
          if (rsp.data.success) {
            const { email, password } = this.getTokenDetails;
            const rsp: any = await Promise.all([
              login(email, password),
              this.getStaffList(),
            ]);

            if (rsp[0].success) {
              sessionStorage.removeItem(constants.commonConstants.ID_TOKEN_KEY);
              sessionStorage.setItem(
                constants.commonConstants.ID_TOKEN_KEY,
                rsp[0].authToken
              );
              const jwtDetails: { permission: string } = jwt_decode(
                rsp[0].authToken
              );

              this.$store.commit("commonModule/setTokenDetails", jwtDetails);
            }
          }
        })
        .catch((err: Error) => {
          console.error(`delete member request: ${err}`);
        })
        .finally(() => {
          this.$store.commit("commonModule/setIsLoading", false);
          this.$emit("closeDialog");
        });
    },
  },
};
