
import { mapActions, mapGetters } from "vuex";
import StaffTable from "../components/staff/StaffTable.vue";
import DeleteStaffDialog from "../components/staff/DeleteStaffDialog.vue";
import { IStaffDetails, IStaffPage } from "../types/staffPage.interface";
import StaffMainHeader from "../components/staff/StaffMainHeader.vue";

export default {
  name: "Staff",
  components: { StaffMainHeader, DeleteStaffDialog, StaffTable },
  data(): IStaffPage {
    return {
      isOpenDeleteDialog: false,
      dialogText: "",
      memberEmail: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "commonModule/getUser",
    }),
    ...mapActions({
      getStaffList: "staffModule/getStaffList",
    }),
  },
  methods: {
    openDeleteDialog(item: IStaffDetails): void {
      this.isOpenDeleteDialog = true;
      this.memberEmail = item.email;
      this.dialogText = `Are you sure you want to remove ${item.userName} from your team?`;
    },
  },
  async created(): Promise<void> {
    await this.getStaffList;
  },
};
