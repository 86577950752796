import config from "../../config";

export default () => {
  try {
    window.STONLY_WID = config.STONLY_WID;
    !(function (s, t, o, n, l, y, w, g) {
      s.StonlyWidget ||
        (((w = s.StonlyWidget =
          function () {
            w._api ? w._api.apply(w, arguments) : w.queue.push(arguments);
          }).queue = []),
        ((y = t.createElement(o)).async = !0),
        (g = new XMLHttpRequest()).open(
          "GET",
          n + "version?v=" + Date.now(),
          !0
        ),
        (g.onreadystatechange = function () {
          g.readyState === 4 &&
            ((y.src =
              n +
              "stonly-widget.js?v=" +
              (g.status === 200 ? g.responseText : Date.now())),
            (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l));
        }),
        g.send());
    })(window, document, "script", "https://stonly.com/js/widget/v2/");
  } catch (e) {
    console.error("stonly", e);
  }
};
