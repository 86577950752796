
import { mapActions, mapGetters } from "vuex";
import payRateHelper from "../../utils/payRatesTable.util";
import reportUtil from "../../utils/reports.util";

export default {
  name: "PayRatesHeader",
  props: {
    isOnBoarding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      siteServicesList: "commonModule/getSiteServicesList",
      siteTable: "onBoardingModule/getTableData",
      payRatePageData: "payRatesModule/getPayRatePageData",
      isDisabledPayRates: "payRatesModule/getIsDisabledPayRates",
    }),
  },
  methods: {
    ...mapActions({
      savePayRatesTableData: "onBoardingModule/savePayRatesTableData",
      getPayRatesData: "payRatesModule/getPayRatesPageData",
    }),
    async savePayRates(): Promise<void> {
      const tableData = this.isOnboarding
        ? this.siteTable
        : this.payRatePageData;

      const formattedData =
        payRateHelper.getFormattedTableDataForRequest(tableData);

      const data = {
        payRatesTableData: formattedData,
      };

      this.$store.commit("commonModule/setIsLoading", true);

      try {
        const rsp = await this.savePayRatesTableData(data);

        if (rsp.success) {
          await this.getPayRatesData();
          this.$store.commit("payRatesModule/setTabIndex", 0);
          this.$store.commit(
            "payRatesModule/setIsPayRatesExists",
            reportUtil.isPayRatesExists(this.payRatePageData)
          );
          this.$toast.success("The pay rates have been saved successfully.", {
            pauseOnHover: false,
            dismissible: true,
          });
        }
      } catch (err) {
        console.error(`save pay rates table request ${err}`);
      } finally {
        this.$store.commit("commonModule/setIsLoading", false);
      }
    },
  },
};
