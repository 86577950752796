
import formRules from "../../utils/formRules.util";
import { mapActions, mapGetters } from "vuex";
import {
  IPayRatesParticipantSteps,
  IStep,
} from "../../types/staffPay.interface";

export default {
  name: "PayRatesParticipantSteps",
  props: {
    value: {
      type: Array,
    },
    mode: {
      type: String,
    },
  },
  data(): IPayRatesParticipantSteps {
    return {
      isOpenDialog: false,
      formRules: formRules(),
      stepsIndex: 0,
      valid: true,
      isSetDefault: false,
      baseSteps: [
        {
          index: 1,
          from: 0,
          to: 1,
          rate: 0,
        },
        {
          index: 2,
          from: 2,
          to: 3,
          rate: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCurrentCurrency: "commonModule/getSiteCurrency",
      getSiteSettings: "payRatesModule/getSettings",
    }),
    getSteps: {
      get(): IStep[] {
        return this.value;
      },
      set(val: IStep[]): void {
        this.$emit("input", val);
      },
    },
    isAddNewStepBtnDisabled(): boolean {
      return this.getSteps?.length === 4;
    },
  },
  methods: {
    ...mapActions({
      saveSettings: "payRatesModule/saveSiteSettings",
    }),
    onApply(): void {
      if (this.isSetDefault) {
        this.saveDefaultSteps();
      }
      this.isOpenDialog = false;
    },
    setTo(val: number, index: number): void {
      this.getSteps[index].to = val;

      this.getSteps.forEach((step: IStep, i: number) => {
        step.from = i > index ? Number(this.getSteps[i - 1].to) + 1 : step.from;
        step.to = i > index ? Number(this.getSteps[i - 1].to) + 2 : step.to;
      });
    },
    saveDefaultSteps(): void {
      const settings = {
        defaultTiers: this.getSteps,
      };

      this.saveSettings(settings)
        .then(() => {
          console.log("settings");
        })
        .catch((err: Error) => {
          console.error(`saveDefaultSteps: ${err}`);
        });
    },
    removeStep(index: number): void {
      this.getSteps = this.getSteps.filter(
        (item: IStep, i: number) => index !== i
      );
      this.stepsIndex = this.getSteps.length - 1;
    },
    addNewStep(): void {
      this.stepsIndex = this.getSteps.length;
      if (this.getSteps.length === 1) {
        this.getSteps[0].to = 1;
      }
      this.getSteps.push({
        index: this.getSteps.length + 1,
        from: parseInt(this.getSteps[this.getSteps.length - 1].to, 10) + 1,
        to: parseInt(this.getSteps[this.getSteps.length - 1].to, 10) + 2,
        rate: 0,
      });
    },
  },
  created(): void {
    if (Array.isArray(this.value)) {
      this.getSteps = this.value;
    } else if (this.getSiteSettings.defaultTiers) {
      this.getSteps = this.getSiteSettings.defaultTiers;
    } else {
      this.getSteps = this.baseSteps;
    }
  },
};
