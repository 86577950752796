
import {
  ITimeSheetsDetails,
  ITimeSheetsTable,
} from "../../types/timeSheets.interface";
import { mapGetters } from "vuex";
import Member from "../Member.vue";
import TimeSheetsViewDialog from "./TimeSheetsViewDialog.vue";

export default {
  name: "TimeSheetsTable",
  props: {
    chosenDates: {
      type: Array,
    },
  },
  components: { TimeSheetsViewDialog, Member },
  data(): ITimeSheetsTable {
    return {
      sortBy: "userId",
      userTimeSheetsDetails: {},
      headers: [
        { text: "Staff", value: "userName", class: "subtitle-1 py-2 py-6" },
        { text: "Total Hours", value: "totalHours", class: "subtitle-1" },
        { text: "Hourly Rate", value: "paymentAmount", class: "subtitle-1" },
        { text: "Amount", value: "totalPay.amount", class: "subtitle-1" },
        {
          text: "",
          value: "key",
          sortable: false,
        },
      ],
      isOpenViewDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      timeSheetsList: "timeSheetsModule/getTimeSheetsListData",
      getSiteCurrency: "commonModule/getSiteCurrency",
    }),
    getTimeSheetsList(): [ITimeSheetsDetails] {
      return this.timeSheetsList.map((item: ITimeSheetsDetails) => {
        return {
          ...item,
          salary: item.salaryWageString,
          totalSalary: item.totalPay.amountString,
        };
      });
    },
  },
  methods: {
    openViewDialog(item: ITimeSheetsDetails): void {
      this.userTimeSheetsDetails = item;
      this.isOpenViewDialog = true;
    },
  },
};
