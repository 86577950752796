import { GetterTree } from "vuex";
import { RootState } from "../../types";
import { IWorkClockRootState } from "./types";

export const getters: GetterTree<IWorkClockRootState, RootState> = {
  getUsersActiveMap: (state: IWorkClockRootState): object =>
    state.usersActiveMap,
  getIsActiveUser: (state: IWorkClockRootState): boolean => state.isUserActive,
  getTotalShiftTime: (state: IWorkClockRootState): number =>
    state.totalShiftTime,
};
