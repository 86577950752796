
import { mapActions, mapGetters } from "vuex";
import {
  IStaffDetails,
  IStaffTable,
  IToggleExpand,
} from "../../types/staffPage.interface";
import constants from "../../utils/constants/constants.util";
import Member from "../Member.vue";
import ShowUserDetails from "./ShowUserDetails.vue";

export default {
  name: "StaffTable",
  components: { ShowUserDetails, Member },
  data(): IStaffTable {
    return {
      tableHeight: 0,
      transitioned: [],
      statusColorsMap: constants.staffConstants.STATUS_COLORS_MAP,
      headers: [
        {
          text: "Picture",
          value: "userId",
          class: "subtitle-1 pr-0 py-6",
          align: "start",
          width: "100px",
        },
        {
          text: "Name",
          value: "userName",
          class: "subtitle-1",
          align: "start",
        },
        {
          text: "Department",
          value: "department",
          class: "subtitle-1",
          align: "start",
        },
        {
          text: "Position",
          value: "position",
          class: "subtitle-1",
          align: "start",
        },
        {
          text: "Status",
          value: "status",
          class: "subtitle-1",
          align: "start",
        },
        { text: "", value: "data-table-expand", sortable: false },
        { text: "", value: "confirm", sortable: false },
        { text: "", value: "delete", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: "commonModule/getIsAdmin",
      staffList: "staffModule/getStaffListData",
      user: "commonModule/getUser",
      getDepartmentColorByDepartmentName:
        "commonModule/getDepartmentColorByDepartmentName",
      tokenDetails: "commonModule/getTokenDetails",
    }),
    staffUserListSorted(): [IStaffDetails] {
      return this.staffList;
    },
    isActiveUser() {
      return (email: string): boolean => {
        return this.tokenDetails.email === email;
      };
    },
  },
  methods: {
    ...mapActions({
      getStaffList: "staffModule/getStaffList",
      updateStaffMemberData: "staffModule/updateStaffMemberData",
    }),
    onResize(): void {
      this.tableHeight =
        window.innerHeight -
        this.$refs.resizableDiv.getBoundingClientRect().y -
        59;
    },
    async changeStatus(item: IStaffDetails): Promise<void> {
      const { wixId } = item;
      const staffMemberData = {
        status: "Active",
      };

      const data = {
        ...staffMemberData,
        wixId,
      };

      await this.updateStaffMemberData(data);
    },
    toggleExpand(props: IToggleExpand): void {
      const { item, isExpanded, expand } = props;

      const id = this.getItemId(item);

      if (isExpanded && this.transitioned[id]) this.closeExpand(item);
      else {
        expand(true);
        this.$nextTick(() => this.$set(this.transitioned, id, true));
      }
    },
    closeExpand(item: IStaffDetails, isSaveDetails?: boolean): void {
      const id = this.getItemId(item);
      this.$set(this.transitioned, id, false);
      if (isSaveDetails) {
        this.$nextTick(() => {
          this.getStaffList();
        });
      }
    },
    getItemId(item: IStaffDetails): string {
      return item.email;
    },
    expandOnClickRow(item: IStaffDetails, row: any): void {
      // if (this.isAdmin && item.status !== "Unconfirmed")
      this.toggleExpand(row);
    },
    openDeleteDialog(item: IStaffDetails): void {
      this.$emit("openDeleteDialog", item);
    },
    async saveConfirm(): Promise<void> {
      const staffMemberData = {
        status: "Active",
      };

      const data = {
        ...staffMemberData,
      };

      await this.updateStaffMemberData(data);
    },
  },
};
