import { rootColorNames } from "../root.util";

const STATUS_COLORS_MAP = {
  Active: rootColorNames.lightGreen,
  Unconfirmed: "#8E9FAD",
  Pending: "#ffea8a",
};

const STAFF_LABELS = {
  DELETE_DIALOG: {
    TITLE: "Delete Staff",
  },
};

const staffConstants = {
  STATUS_COLORS_MAP,
  STAFF_LABELS,
};

export default staffConstants;
