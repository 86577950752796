
import { pngList } from "../../utils/assets.util";
import { IOnboardingStepperFooterTypes } from "../../types/onboarding.interface";

export default {
  name: "OnBoardingStepperFooter",
  props: {
    confirmBtnText: {
      type: String,
      default: "confirm",
    },
    skipBtnText: {
      type: String,
      default: "skip",
    },
    checkboxText: {
      type: String,
    },
    isShowIcon: {
      type: Boolean,
      default: false,
    },
    selectedMembers: {
      type: Array,
      default: (): [] => [],
    },
    isUserSkipStep: {
      type: Boolean,
      default: false,
    },
    isFinishFetching: {
      type: Boolean,
    },
  },
  data(): IOnboardingStepperFooterTypes {
    return {
      appLogo: pngList.appLogoFooter,
    };
  },
  computed: {
    width(): string {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return "900";
        case "lg":
          return "1185";
        default:
          return "1185";
      }
    },
  },
  methods: {
    handleCheckbox(val: boolean): void {
      this.$emit("handleCheckbox", val);
    },
  },
};
