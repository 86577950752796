
import { mapActions, mapGetters } from "vuex";
import { pngList } from "../utils/assets.util";
import payRateHelper from "../utils/payRatesTable.util";
import constants from "../utils/constants/constants.util";
import { IStaffPayRates } from "../types/staffPay.interface";

export default {
  name: "StaffPayRates",
  props: {
    isSaveRatesTable: {
      type: Boolean,
      default: false,
    },
    isOnBoarding: {
      type: Boolean,
      default: false,
    },
  },
  data(): IStaffPayRates {
    return {
      placeholder: pngList.placeholder,
      hourlyRateService: constants.HOURLY_RATE_SERVICE,
      servicePaymentTypeMap: payRateHelper.servicePaymentTypeMap,
    };
  },
  computed: {
    ...mapGetters({
      siteTable: "onBoardingModule/getTableData",
      siteServicesList: "commonModule/getSiteServicesList",
      user: "commonModule/getUser",
      getCurrentCurrency: "commonModule/getSiteCurrency",
      payRatePageData: "payRatesModule/getPayRatePageData",
    }),
    getStaffPayRatesData() {
      return this.isOnBoarding ? this.siteTable : this.payRatePageData;
    },
  },
  watch: {
    async isSaveRatesTable(val: boolean): Promise<void> {
      if (val) {
        const formattedData = payRateHelper.getFormattedTableDataForRequest(
          this.siteTable
        );

        const data = {
          payRatesTableData: formattedData,
        };

        const rsp = await this.savePayRatesTableData(data);
        if (rsp.success) {
          this.$emit("changeSaveRateTable");
          return this.$router.push("/Reports");
        }
      }
    },
  },
  methods: {
    ...mapActions({
      savePayRatesTableData: "onBoardingModule/savePayRatesTableData",
    }),
  },
};
