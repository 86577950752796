
export default {
  name: "BaseDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: (): any => [],
    },
    maxWidth: {
      type: String,
      default: "1200px",
    },
    persistent: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
    isShowAction: {
      type: Boolean,
      default: true,
    },
    overlay: {
      type: String,
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    dialogSubtitle: {
      type: String,
      default: "",
    },
    actionsPrefix: {
      type: String,
      default: null,
    },
    transition: {
      type: String,
      default: "dialog-transition",
    },
    width: {
      type: String,
    },
    isDisplayDivider: {
      type: Boolean,
      default: true,
    },
    titleIcon: {
      type: String,
    },
    containerWidth: {
      type: String,
    },
  },
  computed: {
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        if (!value) this.$emit("closeDialog");
      },
    },
    hasDivider(): boolean {
      return this.actions && this.actions.length;
    },
  },
  watch: {
    value: function (val: boolean): void {
      if (val) this.$emit("onOpen");
    },
  },
};
