import converter from "json-2-csv";
import { IMember } from "../types/staffPay.interface";
import { isEmpty } from "lodash";

const getResultCSVString = async (data: any): Promise<string> => {
  let resultCSVString = await converter.json2csvAsync(data);
  resultCSVString = sanitizeCSVString(resultCSVString);
  return resultCSVString;
};

const sanitizeCSVString = (csvStringToClean: string): string => {
  let resultCSVString = csvStringToClean;
  resultCSVString = resultCSVString.replace(/undefined/gi, "");
  resultCSVString = resultCSVString.replace(/null/gi, "");
  resultCSVString = resultCSVString.replace(/false/gi, "");
  return resultCSVString;
};

const explodeStandardNameComponents = (
  name: string
): { firstName: string; lastName: string; initials: any } => {
  let employeeFirstName = "";
  let employeeLastName = "";
  let employeeInitials = "";
  const arr = name ? name.split(" ") : [];
  if (arr.length > 1) {
    employeeFirstName = arr[0];
    employeeLastName = arr[1];
    employeeInitials = arr[0].substr(0, 1) + arr[1].substr(0, 1);
  } else if (arr.length === 1) {
    employeeFirstName = arr[0];
    employeeInitials = arr[0].substr(0, 1);
  }
  return {
    firstName: employeeFirstName,
    lastName: employeeLastName,
    initials: employeeInitials,
  };
};

const isPayRatesExists = (payRatePageData: Array<IMember>): boolean => {
  return (
    payRatePageData.filter((item: IMember) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars

      return Object.entries(item.services).some(([key, value]: any) => {
        const isPayRatesEmpty = isEmpty(value?.payRates?.flat);

        const isHourlyRate =
          value.payRates?.hourlyRate !== 0 &&
          value.payRates?.hourlyRate !== undefined;

        return !isPayRatesEmpty || isHourlyRate;
      });
    }).length > 0
  );
};

const reportUtil = {
  sanitizeCSVString,
  getResultCSVString,
  explodeStandardNameComponents,
  isPayRatesExists,
};

export default reportUtil;
