import { GetterTree } from "vuex";
import { IReports } from "./types";
import { RootState } from "../../types";

export const getters: GetterTree<IReports, RootState> = {
  getReports: (state) => {
    // return [
    //   {
    //     name: "Harel Ishay",
    //     email: "kerebi6002@goonby.com",
    //     avatarFile: "",
    //     totalPayout: "23.15",
    //     sessionsCount: 6,
    //     hourlyRate: { paymentAmount: 32, paymentCurrencyId: 0 },
    //     sessionsCardData: [
    //       {
    //         start: "2022-02-07T02:30:00Z",
    //         amountReceived: 0,
    //         bookedParticipants: 5,
    //         checkedInParticipants: 0,
    //         payOut: "0.00",
    //         serviceName: "Class - recurring  session",
    //         sessionRate: "N/A",
    //         participantRate: "N/A",
    //         percentage: "7.70",
    //         participantsCount: 0,
    //       },
    //       {
    //         start: "2022-02-10T18:00:00Z",
    //         amountReceived: 94.73,
    //         bookedParticipants: 1,
    //         checkedInParticipants: 0,
    //         payOut: "7.70",
    //         serviceName: "JS private appointment",
    //         sessionRate: "7.70",
    //         participantRate: "7.70",
    //         percentage: "N/A",
    //         participantsCount: 0,
    //       },
    //       {
    //         start: "2022-02-10T12:00:00Z",
    //         amountReceived: 0,
    //         bookedParticipants: 1,
    //         checkedInParticipants: 0,
    //         payOut: "7.70",
    //         serviceName: "JS private appointment",
    //         sessionRate: "7.70",
    //         participantRate: "7.70",
    //         percentage: "N/A",
    //         participantsCount: 0,
    //       },
    //       {
    //         start: "2022-01-10T12:00:00Z",
    //         amountReceived: 100,
    //         bookedParticipants: 1,
    //         checkedInParticipants: 0,
    //         payOut: "7.70",
    //         serviceName: "JS private appointment",
    //         sessionRate: "7.70",
    //         participantRate: "7.70",
    //         percentage: "N/A",
    //         participantsCount: 0,
    //       },
    //       {
    //         start: "2022-01-10T12:00:00Z",
    //         timestamp: "2022-01-07T19:00:00Z",
    //         title: "course to loaders",
    //         participantsCount: 5,
    //         payOut: "N/A",
    //         sessionRate: "N/A",
    //         participantRate: "N/A",
    //         percentage: "N/A",
    //       },
    //       {
    //         start: "2022-01-10T12:00:00Z",
    //         timestamp: "2022-01-10T18:00:00Z",
    //         title: "test course",
    //         participantsCount: 0,
    //         payOut: "N/A",
    //         sessionRate: "N/A",
    //         participantRate: "N/A",
    //         percentage: "N/A",
    //       },
    //     ],
    //     sessionsCardTotal: 23.1,
    //     timeCardData: {
    //       totalHours: "0.00",
    //       totalHoursTooltip: "0 Hours and 0 Minutes",
    //       totalPay: "0.05",
    //       shiftsData: [
    //         {
    //           id: 91,
    //           instanceId: "6b6ebf96-55bf-4363-9102-73db7f1ed082",
    //           email: "kerebi6002@goonby.com",
    //           start: "2022-02-14T19:59:42.211Z",
    //           end: "2022-02-14T19:59:48.728Z",
    //           timeInSeconds: 6,
    //           timezone: "Asia/Jerusalem",
    //           hours: "0.00",
    //         },
    //       ],
    //     },
    //   },
    // ];
    return state.reports;
  },
  getReportsPageData: (state) => state.reportsPageData,
  getReportType: (state) => state.reportType,
  getTableTotal: (state) => state.tableTotal,
};
