var render = function render(){var _vm=this,_c=_vm._self._c;return _c('welcome-page-wrapper',[_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"5","align-self":"center"}},[_c('div',{staticClass:"welcome-screens-title pb-4"},[_vm._v(" "+_vm._s(_vm.labels.MEMBER_REGISTER.TITLE)+" ")]),_c('v-form',{ref:"form",staticStyle:{"width":"300px"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{staticClass:"px-0 pb-0"},[_c('label',[_vm._v("Password")]),_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"id":"passwordField","type":_vm.isShowPass ? 'text' : 'password',"append-icon":_vm.isShowPass ? 'mdi-eye' : 'mdi-eye-off',"outlined":"","color":"primary","placeholder":"Enter new password","dense":"","error":_vm.isApiError,"rules":[
                _vm.formRules.required,
                _vm.formRules.minCharacters(8),
                _vm.formRules.oneDigit,
                _vm.formRules.oneUpperCase,
                _vm.formRules.oneLowerCase,
              ]},on:{"click:append":function($event){_vm.isShowPass = !_vm.isShowPass}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-0 pb-0"},[_c('label',[_vm._v("Confirm password")]),_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"id":"confirmPasswordField","append-icon":_vm.isVerifyPassword ? 'mdi-eye' : 'mdi-eye-off',"color":"primary","rules":[
                _vm.formRules.required,
                _vm.formRules.passwordConfirmation(_vm.password, _vm.verifyPassword),
              ],"type":_vm.isVerifyPassword ? 'text' : 'password',"dense":"","outlined":"","placeholder":"Confirm new password","error":_vm.isApiError},on:{"click:append":function($event){_vm.isVerifyPassword = !_vm.isVerifyPassword}},model:{value:(_vm.verifyPassword),callback:function ($$v) {_vm.verifyPassword=$$v},expression:"verifyPassword"}})],1)],1)],1),(_vm.isApiError)?_c('v-card-actions',{staticClass:"px-0"},[_c('v-alert',{staticStyle:{"font-size":"11px"},attrs:{"value":_vm.isApiError,"dense":"","outlined":"","width":"100%","type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1):_vm._e(),_c('v-card-actions',{staticClass:"px-1 page-btn"},[_c('v-btn',{staticClass:"text-transform-capitalize mt-5",style:(`background-color: ${_vm.applicationColor}`),attrs:{"id":"ProceedBtn","block":"","type":"submit","color":"white","outlined":"","large":""}},[_vm._v(" Register ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }