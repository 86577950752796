
import BaseDialog from "../BaseDialog.vue";
import Member from "../Member.vue";
import { mapActions, mapGetters } from "vuex";
import { ITimeSheetsCreateShift } from "../../types/timeSheets.interface";
import moment from "moment";
import { IDialogActions } from "../../types/baseDialog.interface";
import { AxiosResponse } from "axios";
export default {
  name: "TimeSheetsCreateShift",
  props: {
    value: {
      type: Boolean,
    },
    memberDetails: {
      type: Object,
    },
    shift: {
      type: Object,
    },
    chosenDates: {
      type: Array,
    },
  },
  data(): ITimeSheetsCreateShift {
    return {
      dateStartShift: new Date().toISOString().substr(0, 10),
      dateEndShift: new Date().toISOString().substr(0, 10),
      menuShiftStart: false,
      menuShiftEnd: false,
      shiftStartTime: { name: "", date: "" },
      shiftEndTime: { name: "", date: "" },
      menuShiftStartTime: false,
      menuShiftEndTime: false,
    };
  },
  components: { Member, BaseDialog },
  computed: {
    ...mapGetters({
      getDepartmentColorByDepartmentName:
        "commonModule/getDepartmentColorByDepartmentName",
    }),
    dialogTitle(): string {
      return this.shift.mode === "edit" ? "Edit shift" : "Create shift";
    },
    dialogActions(): IDialogActions[] {
      return [
        {
          label: "cancel",
          outlined: true,
          onClick: () => this.$emit("closeDialog"),
        },
        {
          label: "save",
          color: "primary",
          onClick: () => this.save(),
        },
      ];
    },
    getFormattedText() {
      return (date: string): string => {
        return moment(date, "h:mm").format("h:mm A");
      };
    },
    computedDateFormattedMomentJs() {
      return (date: string): string => {
        return date ? moment(date).format("dddd, MMMM Do YYYY") : "";
      };
    },
    isOpenCreateShift: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit("input", val);
      },
    },
  },
  created(): void {
    const { start, end, id } = this.shift;

    if (id) {
      this.shiftStartTime.name = moment(start).format("HH:mm");
      this.shiftEndTime.name = moment(end).format("HH:mm");
      this.shiftStartTime.date = moment(start).format();
      this.shiftEndTime.date = moment(end).format();
      this.dateStartShift = moment(start).format("YYYY-MM-DD");
      this.dateEndShift = moment(end).format("YYYY-MM-DD");
    } else {
      this.shiftStartTime.name = `${moment().hours()}:${moment().minutes()}`;
      this.shiftEndTime.name = `${moment().hours()}:${moment().minutes()}`;
      this.shiftStartTime.date = moment(start).format();
      this.shiftEndTime.date = moment(end).format();
      this.dateStartShift = moment(start).format("YYYY-MM-DD");
    }
  },
  methods: {
    ...mapActions({
      addShift: "timeSheetsModule/addShift",
      getTimeSheetsList: "timeSheetsModule/getTimeSheetsPageData",
      updateShift: "timeSheetsModule/updateShift",
    }),
    moment,
    async save(): Promise<void> {
      const currentHourStart = moment(this.shiftStartTime.name, "h:mm").hour();
      const currentMinStart = moment(
        this.shiftStartTime.name,
        "h:mm"
      ).minutes();

      const currentHourEnd = moment(this.shiftEndTime.name, "h:mm").hour();
      const currentMinEnd = moment(this.shiftEndTime.name, "h:mm").minutes();

      const startDate = moment(this.dateStartShift).set({
        hours: currentHourStart,
        minutes: currentMinStart,
      });
      const endDate = moment(this.dateEndShift).set({
        hours: currentHourEnd,
        minutes: currentMinEnd,
      });
      const startShift = startDate.format();
      const endShift = endDate.format();

      let shiftData: {
        start: string;
        end: string;
        timezone: string;
        shiftId?: string;
        email: string;
      } = {
        start: startShift,
        end: endShift,
        email: this.memberDetails.email,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (this.shift.mode === "edit") {
        shiftData.shiftId = this.shift.id;
      }

      this.$store.commit("commonModule/setIsLoading", true);

      const timeSheetsRequest =
        this.shift.mode === "edit" ? this.updateShift : this.addShift;

      timeSheetsRequest(shiftData)
        .then((rsp: AxiosResponse & { success: boolean }) => {
          if (rsp.success) {
            this.$emit("closeDialog");

            let fromDate = moment().startOf("month").startOf("day").format();
            let toDate = moment().endOf("month").endOf("day").format();

            if (this.chosenDates && this.chosenDates.length > 0) {
              fromDate = moment(this.chosenDates[0]).startOf("day").format();
              toDate = moment(this.chosenDates[1]).endOf("day").format();
            }

            const data = {
              fromDate,
              toDate,
            };

            this.getTimeSheetsList(data).then(() => {
              this.$toast.success("New shift created successfully", {
                pauseOnHover: false,
                dismissible: true,
              });
              this.$emit("openViewDialog");
            });
          }
        })
        .catch((err: Error) => {
          console.error(`add shift request: ${err}`);
        })
        .finally(() => {
          this.$store.commit("commonModule/setIsLoading", false);
        });
    },
    saveStartDate(date: Date): void {
      this.$refs.saveStartDate.save(date);
    },
    saveEndDate(date: Date): void {
      this.$refs.saveEndDate.save(date);
    },
    // allowedDates(val: string): boolean {
    //   const currentDate = moment(val).format("D/M/YYYY");
    //   const filteredList = this.shiftsList[currentDate] || [];
    //   if (filteredList.length === 0) return true;
    //   return filteredList.some((shift) => shift.userId !== this.person.userId);
    // },
  },
};
