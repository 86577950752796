import { MutationTree } from "vuex";
import { IOnboardingRootState } from "./types";
import constants from "../../../utils/constants/constants.util";

export const mutations: MutationTree<IOnboardingRootState> = {
  setTableData(state: IOnboardingRootState, data): void {
    state.tableData = data.map((item: any) => {
      return {
        ...item,
        services: {
          ...item.services,
          [constants.HOURLY_RATE_SERVICE]: {
            isSelected: !!item.paymentAmount,
            payRates: {
              hourlyRate: item.paymentAmount,
            },
          },
        },
      };
    });
  },
  setIsOnBoarding(state: IOnboardingRootState, data): void {
    state.isOnBoarding = data;
  },
};
