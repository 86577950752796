import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import LoginPage from "../views/LoginPage.vue";
import Staff from "../views/Staff.vue";
import Register from "../views/onBoarding/RegisterPage.vue";
import OnBoardingStepper from "../views/onBoarding/OnBoardingStepper.vue";
import Reports from "../views/Reports.vue";
import Timesheets from "../views/Timesheets.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import OnBoardingLogin from "../views/onBoarding/LoginPage.vue";
import MemberRegister from "../views/onBoarding/MemberRegister.vue";
import InviteStaff from "../views/InviteStaff.vue";
import StaffPay from "../views/StaffPay.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/staff",
    name: "Staff",
    component: Staff,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/timesheets",
    name: "Timesheets",
    component: Timesheets,
  },
  {
    path: "/onboarding/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/onboarding",
    name: "OnBoardingStepper",
    component: OnBoardingStepper,
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/onboarding/login",
    name: "onBoardingLogin",
    component: OnBoardingLogin,
  },
  {
    path: "/onboarding/memberRegister",
    name: "onBoardingCreateRegister",
    component: MemberRegister,
  },
  {
    path: "/inviteStaff",
    name: "inviteStaff",
    component: InviteStaff,
  },
  {
    path: "/staffPay",
    name: "staffPay",
    component: StaffPay,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
