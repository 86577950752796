import moment from "moment";

const getDatesRange = <T>(
  dates?: Array<T>
): { fromDate: string; toDate: string } => {
  let fromDate = moment().startOf("month").startOf("day").format();
  let toDate = moment().endOf("month").endOf("day").format();

  if (dates && dates.length > 0) {
    fromDate = moment(dates[0]).startOf("day").format();
    toDate = moment(dates[1]).endOf("day").format();
  }

  return { fromDate, toDate };
};

const commonHelper = {
  getDatesRange,
};

export default commonHelper;
