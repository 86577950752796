
import { mapGetters } from "vuex";
import PayRateHeaderBlock from "../PayRateHeaderBlock.vue";
import PayRatesCopyMenu from "./PayRatesCopyMenu.vue";
import { IService } from "../../types/staffPay.interface";

export default {
  name: "PayRatesServices",
  props: {
    isOnBoarding: {
      type: Boolean,
      default: false,
    },
    onBoardingRateType: {
      type: String,
    },
    isParticipant: {
      type: Boolean,
    },
  },
  components: { PayRatesCopyMenu, PayRateHeaderBlock },
  computed: {
    ...mapGetters({
      siteServicesList: "commonModule/getSiteServicesList",
      getIsDisabledPayRates: "payRatesModule/getIsDisabledPayRates",
      serviceNamesOnDisabled: "payRatesModule/getServiceNamesOnDisabled",
    }),
    getSiteServicesList(): IService[] {
      return this.siteServicesList.filter((service: IService) => {
        return service.name !== "Hourly Rate";
      });
    },
  },
};
