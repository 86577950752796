
import { mapGetters } from "vuex";
import payRateHelper from "../../utils/payRatesTable.util";
import helper from "../../utils/helper.util";
import PayRatesCopyMenu from "./PayRatesCopyMenu.vue";
import PayRatesCopyAllStaff from "./PayRatesCopyAllStaff.vue";
import PayRatesParticipantSteps from "./PayRatesParticipantSteps.vue";
import { IStep } from "../../types/staffPay.interface";

export default {
  name: "PayRatesStaffMembers",
  components: {
    PayRatesParticipantSteps,
    PayRatesCopyAllStaff,
    PayRatesCopyMenu,
  },
  props: {
    isOnBoarding: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    servicePaymentTypeMap: { [key: string]: string };
    defaultStaff: number;
  } {
    return {
      defaultStaff: 0,
      servicePaymentTypeMap: payRateHelper.servicePaymentTypeMap,
    };
  },
  computed: {
    ...mapGetters({
      siteTable: "onBoardingModule/getTableData",
      payRatePageData: "payRatesModule/getPayRatePageData",
      siteServicesList: "commonModule/getSiteServicesList",
      getCurrentCurrency: "commonModule/getSiteCurrency",
      getSettings: "payRatesModule/getSettings",
    }),
    getParticipantRange() {
      return (participants: IStep[]): string => {
        const min = Math.min(...participants.map((item) => item.rate));
        const max = Math.max(...participants.map((item) => item.rate));

        if (participants.length === 1) {
          return `+ ${this.getCurrentCurrency.mark}${participants[0].rate}`;
        }
        return `+ ${this.getCurrentCurrency.mark}${min} - ${this.getCurrentCurrency.mark}${max}`;
      };
    },
    servicePlaceholder() {
      return (name: string, type: string): string => {
        if (name === "Hourly Rate") {
          return "0.0 / hr";
        }
        return type === "Flat rate" ? "0.0" : "E.g. 20%";
      };
    },
    getSafeHourlyRate() {
      return (services: any) => {
        if (
          services &&
          services["Hourly Rate"] !== undefined &&
          services["Hourly Rate"].payRates[
            this.servicePaymentTypeMap["Hourly Rate"]
          ]
        ) {
          return services["Hourly Rate"].payRates[
            this.servicePaymentTypeMap["Hourly Rate"]
          ].toString();
        }
        return "0";
      };
    },
    getShortName() {
      return (name: string): string => {
        return helper.getShortName(name);
      };
    },
    getInputText() {
      return (name: string, services: any): string => {
        if (name === "Hourly Rate") return "Hourly rate";
        return services[name] && services[name].paymentType;
      };
    },
    getSiteTable() {
      return this.isOnBoarding ? this.siteTable : this.payRatePageData;
    },
    getSiteServicesList() {
      if (this.getSettings.payPer === "Session") {
        return this.siteServicesList.filter((service: any) => {
          return service.name !== "Hourly Rate";
        });
      }
      return this.siteServicesList;
    },
  },
  methods: {
    textChange(val: string): void {
      if (val) {
        this.$store.commit("payRatesModule/setIsDisabledPayRates", false);
      }
    },
  },
};
