import { MutationTree } from "vuex";
import { IStaffRootState } from "./types";
import { IStaffDetails } from "../../../types/staffPage.interface";
import { IStaff } from "../../../types/inviteStaff.interface";

export const mutations: MutationTree<IStaffRootState> = {
  setStaffList(state: IStaffRootState, data: IStaffDetails[]): void {
    state.staffList = data;
  },
  setWixMembersList(state, data) {
    const formattedWixList = data.reduce((acc: any, current: any) => {
      const member = acc.find((item: any) => {
        if (item.email) {
          return item.email === current.email;
        } else {
          return item.resourceId === current.resourceId;
        }
      });
      if (!member) {
        return acc.concat([current]);
      }
      return acc;
    }, []);

    state.wixMembersList = formattedWixList.map((member: IStaff) => {
      return {
        ...member,
        email: member.email || "",
      };
    });
  },
  setStaffEmailsList(state, data) {
    state.staffEmailsList = data;
  },
  setMemberList(state, data) {
    state.memberList = data;
  },
  setSelectedMember(state, data) {
    state.selectedMembers = data;
  },
  setIsOpenMissingEmailsDialog(state, data) {
    state.isOpenMissingEmailsDialog = data;
  },
  setMissingEmails(state, data) {
    state.missingEmails = data;
  },
};
