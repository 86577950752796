import { GetterTree } from "vuex";
import get from "lodash/get";
import { ICommonRootState, IUser } from "./types";
import { RootState } from "../../types";
import { ICurrencies } from "../../../types/common.interface";

export const getters: GetterTree<ICommonRootState, RootState> = {
  getIsLoading: (state: ICommonRootState): boolean => state.isLoading,
  getIsAdmin: (state: ICommonRootState): boolean =>
    state.user && get(state.user, "permission") === "Admin",
  getUser: (state: ICommonRootState): IUser => <IUser>state.user,
  getTokenDetails: (state: ICommonRootState) => <IUser>state.tokenDetails,
  getDepartmentColorByDepartmentName: (state: ICommonRootState) => {
    const { departments } = state.generalData;
    return (name: string) => {
      const filteredDepartment = (departments || []).filter(
        (color: { name: string }) => color.name === name
      );
      if (filteredDepartment[0] && filteredDepartment[0].color)
        return filteredDepartment[0].color !== ""
          ? filteredDepartment[0].color
          : "#f50057";
      else return "#f50057";
    };
  },
  getSiteServicesList: (state: ICommonRootState): Array<object> =>
    state.siteServicesList,
  getIsApiError: (state: ICommonRootState): boolean => state.isApiError,
  getErrorMessage: (state: ICommonRootState): string => state.errorMessage,
  getIsUserActive: (state: ICommonRootState): boolean => state.isUserActive,
  sortByOnlineUser(state) {
    return (items: any) => {
      const email = get(state.tokenDetails, "email");

      const sortItem = items.splice(
        items.findIndex((item: any) => item.email === email),
        1
      );
      return [...sortItem, ...items];
    };
  },
  getGeneralDataByName(state) {
    return (name: string) => {
      return name !== "all" ? state.generalData[name] : state.generalData;
    };
  },
  getSiteCurrency(state) {
    const { siteData } = state.user;

    const defaultCurrency = {
      code: "USD",
      id: 2,
      mark: "$",
      name: "US Dollar",
    };
    const currenciesList = state.generalData["currencies"];

    const [currentCurrency] = currenciesList.filter(
      (currency: ICurrencies) => currency.code === siteData.paymentCurrency
    );

    return currentCurrency || defaultCurrency;
  },
  getRedirectFrom: (state: ICommonRootState): string | null =>
    state.redirectFrom,
  getCurrentSiteIndex: (state: ICommonRootState): number =>
    state.currentShopIndex,
  getActiveRoute: (state: ICommonRootState): string => state.activeRoute,
  getInviteStaffDialogMembers: (state: ICommonRootState) =>
    state.inviteStaffDialogMembers,
};
