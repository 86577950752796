
import OnBoardingStepperHeader from "../../components/onBoarding/OnBoardingStepperHeader.vue";
import OnBoardingInviteStaff from "../../components/onBoarding/OnBoardingInviteStaff.vue";
import {
  IOnboardingStepperTypes,
  IStepsDetails,
} from "../../types/onboarding.interface";
import OnBoardingStepperFooter from "../../components/onBoarding/OnBoardingStepperFooter.vue";
import OnBoardingDesktopOptions from "../../components/onBoarding/OnBoardingDesktopOptions.vue";
import constants from "../../utils/constants/constants.util";
import OnBoardingPayRates from "../../components/onBoarding/onBoardingPayRates.vue";
import { IStaff } from "../../types/inviteStaff.interface";
import OnBoardingStaffPayRates from "../../components/onBoarding/onBoardingStaffPayRates.vue";
import { mapActions, mapGetters } from "vuex";
import onBoardingStepperHelper from "../../utils/onBoardingStepper.util";

export default {
  name: "OnBoardingStepper",
  components: {
    OnBoardingStaffPayRates,
    OnBoardingPayRates,
    OnBoardingDesktopOptions,
    OnBoardingStepperFooter,
    OnBoardingInviteStaff,
    OnBoardingStepperHeader,
  },
  data(): IOnboardingStepperTypes {
    return {
      carousel: 0,
      isUserSkipStep: false,
      isOpenSettingsDialog: false,
      isOpenMissingEmailsDialog: false,
      isSendInviteEmails: false,
      step: 25,
      desktopStep: 1,
      stepsMaps: constants.onboardingConstants.STEPS_MAP,
      paymentOptions: constants.onboardingConstants.PAYMENT_OPTIONS,
      payFrequencyOptions:
        constants.onboardingConstants.PAYMENT_FREQUENCY_OPTIONS,
      ratesOptions: constants.onboardingConstants.RATES_OPTIONS,
      participantsOptionsStepOne:
        constants.onboardingConstants.PARTICIPANTS_OPTIONS_STEP_ONE,
      participantsOptionsStepTwo:
        constants.onboardingConstants.PARTICIPANTS_OPTIONS_STEP_TWO,
      missingEmails: [],
      onBoardingData: {
        staff: [],
        desktop: {
          payPer: "Both",
          rateType: "Both",
          countBy: "Bookings",
          isParticipant: true,
          payFrequency: "Monthly",
        },
      },
      isSaveRatesTable: false,
    };
  },
  async created(): Promise<void> {
    const data = {
      isOnBoarding: true,
    };

    this.$store.commit("onBoardingModule/setIsOnBoarding", true);

    await this.getSiteStaffFromWix(data);
  },
  computed: {
    ...mapGetters({
      siteTable: "onBoardingModule/getTableData",
    }),
    isPerHour(): boolean {
      return this.onBoardingData.desktop.payPer === "Hour";
    },
    isSelectedRate(): boolean {
      return this.onBoardingData.desktop.rateType === "Both";
    },
    isStaffListValid(): boolean {
      const filteredStaff = this.onBoardingData.staff.filter(
        (item: IStaff) => item.email
      );

      return filteredStaff.length === this.onBoardingData.staff.length;
    },
    stepsDetails(): IStepsDetails {
      return {
        25: {
          step: "1",
          confirmBtnText: "Add Staff & Continue",
          skipBtnText: "skip for now",
          checkboxText: "Send email invitations",
          onConfirm: async () => {
            if (this.isStaffListValid) {
              await this.saveStaffMembers();
            } else {
              this.missingEmails = this.onBoardingData.staff.filter(
                (item: IStaff) => !item.email
              );
              this.isOpenMissingEmailsDialog = true;
            }
          },
          onSkip: () => {
            this.isUserSkipStep = true;
            this.goToStep(2);
          },
          handleCheckbox: (val: boolean) => {
            this.isSendInviteEmails = val;
          },
        },
        50: {
          step: "2",
          confirmBtnText: "continue",
          isShowIcon: true,
          skipBtnText: "Skip for now",
          onConfirm: () => {
            const { rateType, isParticipant } = this.onBoardingData.desktop;

            if (this.isPerHour) {
              this.onBoardingData.desktop.rateType = "";
              this.onBoardingData.desktop.isParticipant = false;
              return this.setPerHourPage();
            } else if (this.desktopStep < 5) {
              if (
                (this.desktopStep === 2 && rateType === "Percentage rate") ||
                (this.desktopStep === 4 && !isParticipant)
              ) {
                return this.setPayRatesTable();
              }
              this.desktopStep += 1;
              this.$refs.carousel.next();
            } else {
              return this.setPayRatesTable();
            }
          },
          onSkip: () => {
            this.goToStep(3);
          },
          onBack: () => {
            if (this.desktopStep === 1) {
              this.isUserSkipStep = false;
              this.goToStep(1);
            } else {
              this.desktopStep -= 1;
              this.$refs.carousel.prev();
            }
          },
        },
        75: {
          step: "3",
          confirmBtnText: "continue",
          skipBtnText: "Skip for now",
          onConfirm: () => {
            this.goToStep(4);
          },
          onSkip: () => {
            return this.goToStep(4);
          },
          onBack: () => {
            this.goToStep(2);
            this.carousel = 4;
            this.desktopStep = 4;
          },
        },
        100: {
          step: "4",
          confirmBtnText: "Done",
          skipBtnText: "Skip for now",
          onConfirm: () => {
            this.isSaveRatesTable = true;
          },
          onSkip: () => {
            return this.$router.push("/Reports");
          },
          onBack: () => {
            this.goToStep(3);
          },
        },
        125: {
          step: "3",
          confirmBtnText: "Done",
          skipBtnText: "",
          onBack: () => {
            if (this.isPerHour) {
              this.goToStep(2);
            }
          },
          onConfirm: () => {
            this.isSaveRatesTable = true;
          },
        },
      };
    },
    confirmBtnText(): string {
      return this.stepsDetails[this.step].confirmBtnText;
    },
    skipBtnText(): string {
      return this.stepsDetails[this.step].skipBtnText;
    },
    checkboxText(): string {
      return this.stepsDetails[this.step].checkboxText;
    },
    backgroundColor(): string {
      return onBoardingStepperHelper.getBackgroundColor(
        this.stepsDetails[this.step].step
      );
    },
  },
  watch: {
    step(val: number): void {
      if (val === 50) {
        this.getPayRatesData();
      }
    },
  },
  methods: {
    ...mapActions({
      getSiteStaffFromWix: "staffModule/getSiteStaffFromWix",
      addStaffMembers: "staffModule/addStaffMembers",
      getPayRatesData: "onBoardingModule/getPayRatesTable",
    }),
    async saveStaffMembers(members?: IStaff[]): Promise<void> {
      this.onBoardingData.staff =
        members && members.length
          ? [...this.onBoardingData.staff, ...members]
          : this.onBoardingData.staff;
      const memberData = this.onBoardingData.staff.map((staff: IStaff) => {
        const id = staff.id;
        delete staff.id;
        return {
          ...staff,
          wixId: id,
        };
      });

      await this.addStaffMembers({
        memberData,
        isSendInviteEmails: this.isSendInviteEmails,
      });
      this.goToStep(2);
    },
    setIsParticipant(val: string): void {
      this.onBoardingData.desktop.isParticipant = val;
    },
    setPayRatesTable(): void {
      this.step = 75;
    },
    setPerHourPage(): void {
      this.goToStep(5);
    },
    goToStep(step: number): void {
      this.step = this.stepsMaps[step];
    },
  },
};
