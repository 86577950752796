import helper from "../helper.util";
import { AxiosResponse } from "axios";
import { axiosRequest } from "../../../utils/auth";

interface IPayRatesRequest {
  instanceId: string;
}

const controllerName = "payRates";

const getPayRatesPageData = (
  data: IPayRatesRequest
): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getPayRatesPageData`, options);
};

const getSiteSettings = (data: IPayRatesRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getSiteSettings`, options);
};

const saveSiteSettings = (data: IPayRatesRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/saveSiteSettings`, options);
};

const payRatesApi = {
  getPayRatesPageData,
  getSiteSettings,
  saveSiteSettings,
};

export default payRatesApi;
