import pagesApi from "./api/pages.api";
import { AxiosResponse } from "axios";

const setUserDefaultSite = (
  instanceId: string,
  tokenDetails: { email: string; password: string }
): Promise<AxiosResponse> => {
  const { email, password } = tokenDetails;
  const data = {
    instanceId,
    email,
    password,
  };

  return pagesApi.setUserDefaultSite(data);
};

const headerHelper = {
  setUserDefaultSite,
};

export default headerHelper;
