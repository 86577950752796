
import { mapGetters } from "vuex";
import { IMember } from "../../types/staffPay.interface";

export default {
  name: "PayRatesCopyAllStaff",
  props: {
    userName: {
      type: String,
      default: "",
    },
    userEmail: {
      type: String,
    },
    paymentAmount: {
      type: String,
    },
    member: {
      type: Object,
    },
    isOnBoarding: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    selectedAll: string;
    selected: string[];
    menu: null;
    isDisplayTooltip: boolean;
  } {
    return {
      selectedAll: "",
      selected: [],
      menu: null,
      isDisplayTooltip: false,
    };
  },
  computed: {
    ...mapGetters({
      siteServicesList: "commonModule/getSiteServicesList",
      siteTable: "onBoardingModule/getTableData",
      payRatePageData: "payRatesModule/getPayRatePageData",
    }),
    getSelectedAll: {
      get(): string {
        return this.selectedAll;
      },
      set(val: string): void {
        if (val) {
          this.selectedAll = val;
          this.selected = this.getFilteredMembers.map(
            (member: { name: string }) => member.name
          );
        } else {
          this.selected = [];
        }
      },
    },
    getSelected: {
      get(): string[] {
        return this.selected;
      },
      set(val: string[]): void {
        this.selected = val;
        if (val.length !== this.getFilteredMembers.length) {
          this.selectedAll = "";
        } else {
          this.selectedAll = `All Staff`;
        }
      },
    },
    getFilteredMembers(): IMember[] {
      const tableData = this.isOnBoarding
        ? this.siteTable
        : this.payRatePageData;

      return tableData.filter((member: IMember) => {
        return member.email !== this.userEmail;
      });
    },
  },
  methods: {
    copyAllStaff(): void {
      const tableData = this.isOnBoarding
        ? this.siteTable
        : this.payRatePageData;

      const formattedTable = tableData.map((member: IMember) => {
        if (this.selected.includes(member.name)) {
          return {
            ...member,
            paymentAmount: this.paymentAmount,
            services: this.member.services,
          };
        }
        return member;
      });

      if (this.isOnBoarding)
        this.$store.commit("onBoardingModule/setTableData", formattedTable);
      else {
        this.$store.commit("payRatesModule/setPayRatePageData", formattedTable);
      }

      this.isDisplayTooltip = true;
      setTimeout(() => {
        this.isDisplayTooltip = false;
        this.menu = false;
        this.selected = [];
        this.selectedAll = "";
      }, 1000);
    },
  },
};
