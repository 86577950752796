
import constants from "../../utils/constants/constants.util";
import { IOnBoardingStaffPayRates } from "../../types/onboarding.interface";
import StaffPayRates from "../StaffPayRates.vue";
import { mapActions } from "vuex";

export default {
  name: "onBoardingStaffPayRates",
  props: {
    isSaveRatesTable: {
      type: Boolean,
      default: false,
    },
  },
  components: { StaffPayRates },
  data(): IOnBoardingStaffPayRates {
    return {
      labels: constants.onboardingConstants.ONBOARDING_LABELS,
    };
  },
  methods: {
    ...mapActions({
      getPayRatesData: "onBoardingModule/getPayRatesTable",
    }),
  },
  created(): void {
    this.getPayRatesData();
  },
};
