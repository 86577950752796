import helper from "../helper.util";
import { axiosRequest } from "../../../utils/auth";
import { AxiosResponse } from "axios";

interface IReportsRequest {
  instanceId: string;
  email?: string;
  fromDate?: string;
  toDate?: string;
  timezone?: string;
  calculate?: string;
}

const controllerName = "reports";

const generateReport = (data: IReportsRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/generateReport`, options);
};

const getReportsPageData = (data: IReportsRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getReportsPageData`, options);
};

const generateReportSummaryCSV = (data: {
  reportData?: any;
}): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/generateReportSummaryCSV`, options);
};

const reportsApi = {
  generateReport,
  getReportsPageData,
  generateReportSummaryCSV,
};

export default reportsApi;
