
import formRules from "../utils/formRules.util";
import { pngList } from "../utils/assets.util";
import { rootColorNames } from "../utils/root.util";
import constants from "../utils/constants/constants.util";
import { IWelcomePage } from "../types/welcomePages.interface";
import WelcomePageWrapper from "../components/WelcomePageWrapper.vue";
import { mapState } from "vuex";
import loginApi from "../utils/api/login.api";

export default {
  name: "ForgotPassword",
  components: { WelcomePageWrapper },
  props: {
    registrationMode: {
      type: String,
    },
    isRegisterViaSignIn: {
      type: Boolean,
    },
    userToken: {
      type: String,
    },
  },
  data(): IWelcomePage {
    return {
      labels: constants.LABELS,
      email: "",
      formRules: formRules(),
      isValid: "",
      appLogo: pngList.appLogo,
      applicationColor: rootColorNames.applicationColor,
      appName: constants.commonConstants.APP_NAME,
      mainLogo: pngList.main,
      isSendEmail: false,
    };
  },
  computed: {
    ...mapState(["isError"]),
  },
  created(): void {
    const authenticateUserDetails = this.$cookies.get(
      "authenticateUserDetails"
    );

    if (authenticateUserDetails)
      this.isRememberMe = authenticateUserDetails.isRememberMe;
  },
  methods: {
    handleSubmit(): void {
      if (this.$refs.form.validate()) {
        this.$store.commit("commonModule/setIsLoading", true);
        loginApi
          .resetPassword({ email: this.email })
          .then((rsp: any) => {
            if (rsp.success) {
              this.isSendEmail = true;
            } else {
              this.isError = true;
              this.errorMessage = rsp.msg;
              setTimeout(() => {
                this.isError = false;
              }, 3000);
            }
          })
          .catch((err) => console.info(err))
          .finally(() => {
            this.$store.commit("commonModule/setIsLoading", false);
          });
      }
    },
  },
};
