export default () => {
  try {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "5b18ab1d-d87a-4fd6-bbda-aa1b290a4cb7";
    (function () {
      let d = document;
      let s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  } catch (e) {
    console.error(`crisp: ${e}`);
  }
};
