import { IPayRates } from "./types";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { ISettings } from "../../../types/settings.interface";

export const state: IPayRates = {
  settings: {} as ISettings,
  paymentTypes: {},
  tabIndex: 0,
  payRatePageData: [],
  isDisabledPayRates: false,
  serviceNamesOnDisabled: [],
  isPayRatesExists: false,
};

const namespaced = true;

export default {
  namespaced,
  state,
  mutations,
  getters,
  actions,
};
