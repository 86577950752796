import { AxiosResponse } from "axios";
import { axiosRequest } from "../../../utils/auth";
import helper from "../helper.util";

interface IStaffPage {
  instanceId?: string;
  userEmail?: string;
  staffMemberDate?: object;
  siteName?: string;
  invitorName?: string;
  password?: string;
  name?: string;
  email?: string;
}

const controllerName = "staffPage";

const getStaffPageData = (data: IStaffPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getStaffPageData`, options);
};

const getSiteStaffFromWix = (data: IStaffPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getSiteStaffFromWix`, options);
};

const updateStaffMemberData = (data: IStaffPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/updateStaffMemberData`, options);
};

const deleteStaffMember = (data: IStaffPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/deleteStaffMember`, options);
};

const addStaffMembers = (data: IStaffPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/addStaffMembers`, options);
};

const deleteDepartment = (data: IStaffPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/deleteDepartment`, options);
};

const deletePosition = (data: IStaffPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/deletePosition`, options);
};

const addPositions = (data: IStaffPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/addPositions`, options);
};

const addDepartments = (data: IStaffPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/addDepartments`, options);
};

const redirectToPackageUpgrade = (data: IStaffPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getPackageUpgradeURL`, options);
};

export default {
  getSiteStaffFromWix,
  updateStaffMemberData,
  deleteStaffMember,
  addStaffMembers,
  getStaffPageData,
  deleteDepartment,
  deletePosition,
  addPositions,
  addDepartments,
  redirectToPackageUpgrade,
};
