
import BaseDialog from "./BaseDialog.vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { ITracker } from "../types/tracker.interface";
import formatUtil from "../utils/format.util";

export default {
  name: "Tracker",
  components: { BaseDialog },
  props: {
    value: {
      type: Boolean,
    },
  },
  data(): ITracker {
    return {
      isStart: false,
      counter: "00:00:00",
      interval: null,
      shiftId: "",
      shiftDate: "",
      todayActivity: "00:00:00",
      secs: 0,
    };
  },
  computed: {
    ...mapGetters({
      totalShiftTime: "workClockModule/getTotalShiftTime",
    }),
    isShowTracker: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit("input", val);
      },
    },
    showTodayActivity(): string {
      const isShiftToday =
        moment(this.shiftDate)
          .startOf("days")
          .diff(moment().startOf("days"), "days") === 0;
      return isShiftToday ? this.totalShiftTime : "00:00:00";
    },
  },
  watch: {
    secs(): void {
      this.counter = formatUtil.formatNumberToTimeString(this.secs / 3600);
    },
    value(val: boolean): void {
      if (val) this.getShiftState();
    },
  },
  mounted(): void {
    this.getShiftState();
  },
  methods: {
    moment,
    ...mapActions({
      clockIn: "workClockModule/clockIn",
      clockOut: "workClockModule/clockOut",
      getShiftStateData: "workClockModule/getShiftState",
      getTimeSheetsList: "timeSheetsModule/getTimeSheetsPageData",
    }),
    async getShiftState(): Promise<void> {
      this.$store.commit("commonModule/setIsLoading", true);
      try {
        const rsp = await this.getShiftStateData();
        const { shiftId, started, startedAt, success, totalTime } = rsp.data;

        if (success) {
          this.$store.commit("workClockModule/setShiftDetails", rsp.data);

          if (totalTime && totalTime.totalTimeForToday)
            this.$store.commit(
              "workClockModule/setTotalShiftTIme",
              totalTime.totalTimeForToday
            );

          this.shiftDate = startedAt;
          this.shiftId = shiftId;

          if (started) {
            this.isStart = true;
            this.startOrStopCounter(1);
          }
        }
      } catch (err) {
        console.error(`get shift state request: ${err}`);
      } finally {
        this.$store.commit("commonModule/setIsLoading", false);
      }
    },
    startOrStopCounter(status: number): void {
      if (status === 1) {
        this.secs = moment().diff(this.shiftDate, "seconds");

        this.todayActivity = this.secs;

        let counter = this.secs;
        this.interval = setInterval(() => {
          counter += 1;
          this.secs = counter;
        }, 1000);
      } else {
        this.todayActivity = this.secs;
        clearInterval(this.interval);
        this.counter = "00:00:00";
      }
    },
    async stopTimer(): Promise<void> {
      const rsp = await this.clockOut(this.shiftId);
      const { success } = rsp.data;

      if (success) {
        this.startOrStopCounter(0);
        this.isStart = false;
        this.$store.commit("commonModule/setIsActive", false);
        this.$emit("closeDialog");
        this.$toast.success("Clocked Out Successfully", {
          pauseOnHover: false,
          dismissible: true,
        });

        await this.getTimeSheetsList();
      }
    },
    async startTimer(): Promise<void> {
      try {
        await this.clockIn();
        this.startOrStopCounter(1);
        this.isStart = true;
        this.$store.commit("commonModule/setIsActive", true);
        this.$emit("closeDialog");
        this.$toast.success("Clocked In Successfully", {
          pauseOnHover: false,
          dismissible: true,
        });
      } catch (err) {
        console.info(`clock in request: ${err}`);
      }
    },
  },
};
