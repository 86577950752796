interface imagesList {
  [key: string]: string;
}

export const pngList: imagesList = {};
pngList.appLogo = require("../static/easyTeamLogo.png");
pngList.appLogoFooter = require("../static/easyteam.png");
pngList.placeholder = require("../static/placeholder.png");
pngList.schedule = require("../static/onboarding/schedule.png");
pngList.clock = require("../static/onboarding/clock.png");
pngList.frame = require("../static/onboarding/frame.png");
pngList.frameRates = require("../static/onboarding/frame-rates.png");
pngList.discount = require("../static/onboarding/discount.png");
pngList.currencty = require("../static/onboarding/currency.png");
pngList.contentCopy = require("../static/contentCopy.png");
pngList.tracker = require("../static/tracker.png");
pngList.main = require("../static/mainLogo.png");
pngList.emptyReport = require("../static/POS.png");
pngList.emptyMembers = require("../static/emptyMembers.svg");
pngList.allSetUp = require("../static/onboarding/all-set-up.png");

export const svgList: imagesList = {};
svgList.tracker = require("../static/navbar-clock-logo.svg");

export const animations: imagesList = {};
animations.logo = require("../static/logo.gif");
