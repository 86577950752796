import { ISiteEmployees } from "../types/onBoardingPayRates.interface";
import constants from "./constants/constants.util";
import { IStaffPayServices } from "../types/staffPay.interface";

interface ITypeMap {
  [key: string]: string;
}

const servicePaymentTypeMap: ITypeMap = {
  "Flat rate": "flat",
  "Hourly Rate": "hourlyRate",
  "Percentage rate": "percentage",
  Both: "Both",
};

const servicePaymentTypeToTitle: ITypeMap = {
  percentage: "Percentage rate",
  flatParticipants: "Flat rate",
  flat: "Flat rate",
  Both: "Flat rate",
  Percentage: "Percentage rate",
};

const paymentTypeMaps: ITypeMap = {
  "Per session": "Session",
  "Per hour": "Hour",
  Both: "Both",
};

const participantTypeMap: ITypeMap = {
  "Booked participants": "Bookings",
  "Checked-in participants": "CheckIns",
};

const getFormattedTableDataForRequest = (
  siteEmployees: ISiteEmployees[]
): ISiteEmployees[] => {
  return siteEmployees.map((employee: ISiteEmployees) => {
    delete employee.payRates;

    for (const key in employee.services) {
      const serviceData = employee.services[key];

      if (
        key === constants.HOURLY_RATE_SERVICE &&
        Object.keys(serviceData.payRates).length !== 0
      ) {
        if (serviceData.payRates.hourlyRate) {
          employee.hourlyRate = {
            paymentCurrencyId: 0,
            paymentAmount: employee.services[key].payRates.hourlyRate,
          };
          delete employee.services[key];
          delete employee.paymentAmount;
          delete employee.paymentCurrencyId;
        } else delete employee.services[key];
      }

      delete serviceData.isSelected;
      if (serviceData.paymentType === "") delete serviceData.paymentType;

      if (serviceData.payRates["participant"]?.length) {
        const participants = serviceData.payRates["participant"];
        participants[participants.length - 1].to = "and above";
      }

      if (Object.keys(serviceData.payRates).length === 0) {
        delete employee.services[key];
      } else {
        const paymentTypes = Object.keys(serviceData.payRates);
        if (paymentTypes.includes("participant")) {
          if (serviceData.isParticipant) {
            serviceData.paymentType = "flatParticipants";
          } else {
            delete serviceData.payRates.participant;
            serviceData.isParticipant = false;
            const hasKey = "flat" in serviceData.payRates;
            serviceData.paymentType = hasKey ? "flat" : "percentage";
          }

          // check if key exists
          const hasKey = "flat" in serviceData.payRates;

          if (!hasKey) {
            serviceData.payRates.flat = "0";
          }
        } else {
          serviceData.paymentType = paymentTypes[0];
        }
      }

      if (Object.keys(employee.services).length === 0) delete employee.services;
    }

    return employee;
  });
};

const formattedServices = (
  services: any,
  mapServiceIds: { [key: string]: string },
  isOnBoarding: boolean,
  firstMemberServices?: any
): IStaffPayServices[] => {
  for (const service in services) {
    if (services[service].paymentType !== "" && !isOnBoarding) {
      if (
        services[service].paymentType === "flatParticipants" ||
        firstMemberServices[service]?.isParticipant
      ) {
        services[service].isParticipant = true;
        services[service].paymentType = "Flat rate";
      } else if (services[service].paymentType) {
        services[service].paymentType =
          servicePaymentTypeToTitle[services[service].paymentType];
      }
    }
    services[service].serviceId = mapServiceIds[service];
  }

  return services;
};

const payRateHelper = {
  servicePaymentTypeMap,
  getFormattedTableDataForRequest,
  participantTypeMap,
  paymentTypeMaps,
  servicePaymentTypeToTitle,
  formattedServices,
};

export default payRateHelper;
