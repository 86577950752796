
import { pngList } from "../../utils/assets.util";
import { IReportsEmptyScreen } from "../../types/reportsPage.interface";
import constants from "../../utils/constants/constants.util";
import InviteStaffDialog from "../InviteStaffDialog.vue";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ReportsEmptyScreen",
  props: {
    dates: {
      type: Array,
    },
  },
  components: { InviteStaffDialog },
  data(): IReportsEmptyScreen {
    return {
      emptyMembersLogo: pngList.emptyMembers,
      labels: constants.LABELS,
      isOpenInviteStaffDialog: false,
      headerDates: [],
    };
  },
  computed: {
    ...mapGetters({
      getTokenDetails: "commonModule/getTokenDetails",
    }),
    isDisabledSubmit(): boolean {
      const isValidDates =
        moment(this.headerDates[1], "YYYY-MM-DD").diff(
          moment(this.headerDates[0], "YYYY-MM-DD")
        ) > 0;

      return this.getTokenDetails.status === "Blocked" || !isValidDates;
    },
  },
};
