
import formRules from "../../utils/formRules.util";
import { pngList } from "../../utils/assets.util";
import { rootColorNames } from "../../utils/root.util";
import constants from "../../utils/constants/constants.util";
import { IWelcomePage } from "../../types/welcomePages.interface";
import WelcomePageWrapper from "../../components/WelcomePageWrapper.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MemberRegister",
  components: { WelcomePageWrapper },
  data(): IWelcomePage {
    return {
      password: "",
      email: "",
      formRules: formRules(),
      isShowPass: false,
      appLogo: pngList.appLogo,
      applicationColor: rootColorNames.applicationColor,
      appName: constants.commonConstants.APP_NAME,
      mainLogo: pngList.main,
      labels: constants.LABELS,
      verifyPassword: "",
      isVerifyPassword: false,
    };
  },
  computed: {
    ...mapGetters({
      isApiError: "commonModule/getIsApiError",
      errorMessage: "commonModule/getErrorMessage",
    }),
  },
  methods: {
    ...mapActions({
      mailRegister: "commonModule/mailRegister",
    }),
    async handleSubmit(): Promise<void> {
      const { inviteKey, instanceId } = this.$route.query;

      if (this.$refs.form.validate()) {
        const data = {
          password: this.password,
          inviteKey,
          instanceId,
        };

        await this.mailRegister(data);
      }
    },
  },
};
