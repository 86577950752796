import { AxiosResponse } from "axios";
import helper from "../helper.util";
import { axiosRequest } from "../../../utils/auth";

interface IPagesRequest {
  instanceId: string;
}

const controllerName = "pages";

const getGeneralData = (data: IPagesRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getGeneralData`, options);
};

const getPositions = (data: IPagesRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getPositions`, options);
};

const getCurrenciesData = (data: IPagesRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getCurrenciesData`, options);
};

const getDepartments = (data: IPagesRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getDepartments`, options);
};

const setUserDefaultSite = (data: IPagesRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/setUserDefaultSite`, options);
};

const pagesApi = {
  getGeneralData,
  getPositions,
  getCurrenciesData,
  getDepartments,
  setUserDefaultSite,
};

export default pagesApi;
