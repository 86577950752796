
import PayRatesHeader from "../components/payRates/PayRatesHeader.vue";
import PayRatesRoutes from "../components/payRates/PayRatesRoutes.vue";
import PayRatesContent from "../components/payRates/PayRatesContent.vue";
import { mapActions, mapGetters } from "vuex";
import StaffPaymentSettings from "../components/settings/StaffPaymentSettings.vue";
import StaffPayRates from "../components/StaffPayRates.vue";
import { ISettings } from "../types/settings.interface";
import { IStaffPay } from "../types/staffPay.interface";
export default {
  name: "StaffPay",
  components: {
    StaffPayRates,
    StaffPaymentSettings,
    PayRatesContent,
    PayRatesRoutes,
    PayRatesHeader,
  },
  data(): IStaffPay {
    return {
      isOpenSettingsDialog: false,
      settingsData: {} as ISettings,
      isSavePayRates: false,
      isFinishFetch: false,
    };
  },
  computed: {
    ...mapGetters({
      getSettingsData: "payRatesModule/getSettings",
    }),
    settings(): ISettings {
      return this.settingsData;
    },
  },
  methods: {
    ...mapActions({
      getPayRatesData: "payRatesModule/getPayRatesPageData",
      getSiteSettings: "payRatesModule/getSiteSettings",
      saveSiteSettings: "payRatesModule/saveSiteSettings",
    }),
    async getSettings(): Promise<void> {
      const rsp = await this.getSiteSettings();

      this.settingsData = {
        ...rsp.data,
      };

      this.$store.commit("payRatesModule/setSettings", this.settingsData);
    },
  },
  async created(): Promise<void> {
    this.$store.commit("payRatesModule/setTabIndex", 0);
    const [rsp] = await Promise.all([
      this.getPayRatesData(),
      this.getSettings(),
    ]);
    if (rsp) {
      this.isFinishFetch = true;
    }
  },
  beforeDestroy(): void {
    this.$store.commit("payRatesModule/setIsDisabledPayRates", false);
    this.$store.commit("payRatesModule/setServiceNamesOnDisabled", []);
  },
};
