const getBackgroundColor = (step: string): string => {
  return step === "1" || step === "3"
    ? "background-color: white"
    : "background-color: #f0f4f7";
};

const onBoardingStepperHelper = {
  getBackgroundColor,
};

export default onBoardingStepperHelper;
