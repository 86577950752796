import { axiosRequest } from "../../../utils/auth";
import { AxiosResponse } from "axios";
import helper from "../helper.util";

interface ILoginPage {
  email?: string;
  password?: string;
  resetToken?: string;
}

const controllerName = "login";

const setPassword = (data: object): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/setPassword`, options);
};

const resetPassword = (data: ILoginPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/resetPassword`, options);
};

const getDetailsOnRefresh = (): Promise<AxiosResponse> => {
  return axiosRequest(`${controllerName}/refreshLoginData`);
};

const mailRegister = (data: ILoginPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/mailRegister`, options);
};

const publicURLRegister = (data: ILoginPage): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/publicURLRegister`, options);
};

export default {
  setPassword,
  resetPassword,
  getDetailsOnRefresh,
  mailRegister,
  publicURLRegister,
};
