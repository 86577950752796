import { IShiftDetails, IWorkClockRootState } from "./types";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";

export const state: IWorkClockRootState = {
  usersActiveMap: {},
  isUserActive: false,
  currentShiftDetails: {} as IShiftDetails,
  totalShiftTime: 0,
};

const namespaced = true;

export default {
  namespaced,
  state,
  mutations,
  getters,
  actions,
};
