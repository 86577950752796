import { axiosRequest } from "../../../utils/auth";
import { AxiosResponse } from "axios";
import helper from "../helper.util";

export interface IOnBoardingRequest {
  instanceId?: string;
  email?: string;
  password?: string;
  name?: string;
  siteOwnerId?: string;
}

const controllerName = "onBoarding";

const createOwner = (data: IOnBoardingRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/createOwner`, options);
};

const getSiteServices = (data: IOnBoardingRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getSiteServices`, options);
};

const getPayRatesTable = (data: IOnBoardingRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getPayRatesTable`, options);
};

const saveSiteSettings = (data: IOnBoardingRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/saveSiteSettings`, options);
};

const savePayRatesTableData = (
  data: IOnBoardingRequest
): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/savePayRatesTableData`, options);
};

const onBoardingApi = {
  createOwner,
  getSiteServices,
  getPayRatesTable,
  saveSiteSettings,
  savePayRatesTableData,
};

export default onBoardingApi;
