import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { ITimeSheetsRootState } from "./types";

export const state: ITimeSheetsRootState = {
  timeSheetsList: [],
};

const namespaced = true;

export default {
  namespaced,
  state,
  mutations,
  getters,
  actions,
};
