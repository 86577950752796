import { AxiosResponse } from "axios";
import helper from "../helper.util";
import { axiosRequest } from "../../../utils/auth";

interface IWorkClockRequest {
  instanceId?: string;
  emails?: string[];
  email?: string;
  timezone?: string;
  shiftId?: string;
}

const controllerName = "workClock";

const getEmployeesStatusForShopByEmails = (
  data: IWorkClockRequest
): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(
    `${controllerName}/getEmployeesStatusForShopByEmails`,
    options
  );
};

const getShiftState = (data: IWorkClockRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/getShiftState`, options);
};

const clockIn = (data: IWorkClockRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/clockIn`, options);
};

const clockOut = (data: IWorkClockRequest): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/clockOut`, options);
};

const workClockApi = {
  getEmployeesStatusForShopByEmails,
  clockIn,
  clockOut,
  getShiftState,
};

export default workClockApi;
