import { GetterTree } from "vuex";
import { IStaffRootState } from "./types";
import { RootState } from "../../types";
import { IStaffDetails } from "../../../types/staffPage.interface";

export const getters: GetterTree<IStaffRootState, RootState> = {
  getStaffListData: (state: IStaffRootState): IStaffDetails[] =>
    state.staffList,
  getStaffEmailsList: (state: IStaffRootState): Array<object> => {
    return state.staffEmailsList;
  },
  getWixMembersList: (state) => state.wixMembersList,
  getMemberList: (state) => state.memberList,
  getSelectedMember: (state) => state.selectedMembers,
  getIsOpenMissingEmailsDialog: (state) => state.isOpenMissingEmailsDialog,
  getMissingEmails: (state) => state.missingEmails,
};
