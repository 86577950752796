
import BaseDialog from "../BaseDialog.vue";
import formRules from "../../utils/formRules.util";
import {
  IInviteStaffDialog,
  IInviteStaffDialogMember,
} from "../../types/inviteStaff.interface";
import constants from "../../utils/constants/constants.util";
import { IDialogActions } from "../../types/baseDialog.interface";
import { mapGetters } from "vuex";

export default {
  name: "InviteStaffDialog",
  props: {
    value: {
      type: Boolean,
    },
  },
  components: { BaseDialog },
  data(): IInviteStaffDialog {
    return {
      labels: constants.onboardingConstants.ONBOARDING_LABELS,
      userDetails: [
        {
          name: null,
          email: null,
          isEmailError: false,
          isNameError: false,
        },
        {
          name: null,
          email: null,
          isEmailError: false,
          isNameError: false,
        },
      ],
      formRules: formRules(),
    };
  },
  computed: {
    ...mapGetters({
      getStaffListData: "staffModule/getStaffListData",
      getTokenDetails: "commonModule/getTokenDetails",
      getMemberList: "staffModule/getMemberList",
    }),
    isOpenInviteStaffDialog: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit("input", val);
      },
    },
    isDisplayButton(): boolean {
      const members = this.userDetails.filter(
        (member: IInviteStaffDialogMember) => {
          return (
            (member.name !== null && member.name !== "") ||
            (member.email !== null && member.email !== "")
          );
        }
      );

      const totalUsers =
        members.length +
        this.getStaffListData.length +
        this.getMemberList.length;
      return totalUsers > this.getTokenDetails.maxUsers;
    },
    dialogActions(): IDialogActions[] {
      return [
        {
          label: "Cancel",
          outlined: true,
          onClick: () => this.closeDialog(),
        },
        {
          label: !this.isDisplayButton ? "Add Staff" : "Upgrade",
          color: !this.isDisplayButton ? "primary" : "#1479f2",
          textColor: "white",
          onClick: () => {
            if (this.$refs.form.validate()) {
              const userDetailsFiltered = this.userDetails.filter(
                ({ name, email }: IInviteStaffDialogMember) => name || email
              );

              const userDetails = userDetailsFiltered.map(
                (user: IInviteStaffDialogMember) => {
                  if (user.email && user.name) {
                    delete user.isEmailError;
                    delete user.isNameError;
                    return user;
                  }
                }
              );

              if (this.isDisplayButton) {
                this.$store.commit(
                  "commonModule/setInviteStaffDialogMembers",
                  userDetails
                );
                return this.$emit("redirectToPackageUpgrade");
              }

              const existingUsers = this.getStaffListData.filter(
                (staff: any) => {
                  return userDetailsFiltered.some(
                    (user: any) => staff.email === user.email
                  );
                }
              );

              if (!existingUsers.length) {
                this.$emit("saveStaffMembers", userDetails);
                this.$emit("closeDialog");
              } else {
                const isUsers = existingUsers.length > 1;
                const userEmails = existingUsers.map(
                  (staff: any) => staff.email
                );

                const toastText = `The ${
                  isUsers ? "emails" : "email"
                } ${userEmails.join(", ")} already exists.`;
                this.$toast.warning(toastText, {
                  pauseOnHover: false,
                  dismissible: true,
                });
              }
            }
          },
        },
      ];
    },
  },
  methods: {
    closeDialog(): void {
      this.userDetails = [
        {
          name: null,
          email: null,
          isEmailError: false,
          isNameError: false,
        },
        {
          name: null,
          email: null,
          isEmailError: false,
          isNameError: false,
        },
      ];
      this.$emit("closeDialog");
    },
    addNewStaff(): void {
      this.userDetails.push({
        name: "",
        email: "",
        isEmailError: false,
        isNameError: false,
      });
    },
  },
};
