
import BaseDialog from "../BaseDialog.vue";
import { IBaseDialog } from "../../types/baseDialog.interface";
import {
  ISetting,
  IStaffPaymentSettings,
} from "../../types/staffPaymentSettings.interface";
import constants from "../../utils/constants/constants.util";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "StaffPaymentSettings",
  components: { BaseDialog },
  props: {
    selectedRate: {
      type: String,
    },
    isParticipant: {
      type: Boolean,
    },
    countBy: {
      type: String,
    },
    rateType: {
      type: String,
    },
    value: {
      type: Boolean,
    },
    isOnBoarding: {
      type: Boolean,
      default: false,
    },
  },
  data(): IStaffPaymentSettings {
    return {
      labels: constants.LABELS,
      selectedOption: "",
      payoutSettingsMap: {
        Hour: "By hourly rate",
        Session: "By Bookings sessions",
        Both: "Both",
        "By hourly rate": "Hour",
        "By Bookings sessions": "Session",
      },
      rateTypeSettingsMap: {
        Flat: "Flat rate",
        Percentage: "Percentage rate",
        Both: "Both",
        "Flat rate": "Flat",
        "Percentage rate": "Percentage",
      },
      participantsTypeMap: {
        Bookings: "Booked participants",
        CheckIns: "Checked-in participants",
        "Booked participants": "Bookings",
        "Checked-in participants": "checkIns",
      },
    };
  },
  computed: {
    ...mapGetters({
      getSettingsData: "payRatesModule/getSettings",
    }),
    selectedSettings(): { [key: string]: string } {
      const { payPer, rateType, countBy } = this.getSettingsData;

      return {
        payPer: this.payoutSettingsMap[payPer] || "",
        rateType: this.rateTypeSettingsMap[rateType] || "",
        countBy: this.participantsTypeMap[countBy] || "",
      };
    },
    settingsData(): ISetting[] {
      return [
        {
          title: "Payout",
          key: "payPer",
          options: ["By Bookings sessions", "By hourly rate", "Both"],
          defaultTitle: "How do you pay your staff?",
          selectedOption: "",
        },
        {
          title: "Bookings Services",
          key: "rateType",
          selectedOption: "",
          options: ["Flat rate", "Percentage rate", "Both"],
          defaultTitle: "How do you set your Bookings pay rates?",
          isSelected: this.selectedRate !== "",
        },
        {
          title: "Participant rate",
          isSelected: this.isParticipant,
          key: "countBy",
          participantOptions: [
            "Booked participants",
            "Checked-in participants",
          ],
        },
      ];
    },
    isOpenSettingsDialog: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit("input", val);
      },
    },
    dialogActions(): IBaseDialog[] {
      return [
        {
          label: "cancel",
          outlined: true,
          color: "primary",
          onClick: () => {
            this.$emit("closeDialog");
          },
        },
        {
          label: "save",
          color: "primary",
          onClick: async () => {
            const { payPer, rateType } = this.selectedSettings;
            const formattedSettings = {
              payPer: this.payoutSettingsMap[payPer],
              rateType: this.rateTypeSettingsMap[rateType],
              countBy: this.participantsTypeMap[this.selectedOption],
            };
            this.$store.commit("commonModule/setIsLoading", true, {
              root: true,
            });
            this.saveSettings(formattedSettings)
              .then(() => {
                this.$emit("getSettings");
                this.$store.commit("payRatesModule/setTabIndex", 0);
              })
              .catch((err: Error) => {
                console.error(`save settings request ${err}`);
              })
              .finally(() => {
                this.$store.commit("commonModule/setIsLoading", false, {
                  root: true,
                });
                this.$emit("closeDialog");
              });
          },
        },
      ];
    },
  },
  watch: {
    getSettingsData(val: { countBy: string }): void {
      this.selectedOption = this.participantsTypeMap[val.countBy];
    },
  },
  methods: {
    ...mapActions({
      saveSettings: "payRatesModule/saveSiteSettings",
    }),
  },
  async mounted(): Promise<void> {
    this.selectedOption =
      this.participantsTypeMap[this.getSettingsData.countBy];
  },
};
