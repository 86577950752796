
import { mapGetters } from "vuex";
import PayRatesStaffMembers from "./PayRatesStaffMembers.vue";
import PayRatesServices from "./PayRatesServices.vue";

export default {
  name: "PayRatesContent",
  components: { PayRatesServices, PayRatesStaffMembers },
  data(): { tabIndex: number } {
    return {
      tabIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      getTabIndex: "payRatesModule/getTabIndex",
      siteServicesList: "commonModule/getSiteServicesList",
    }),
  },
  watch: {
    getTabIndex(val: number) {
      this.tabIndex = val;
    },
  },
};
