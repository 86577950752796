import { IReports } from "./types";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./action";

export const state: IReports = {
  reports: [],
  reportsPageData: [],
  reportType: "",
  tableTotal: {
    session: 0,
    payout: 0,
  },
};

const namespaced = true;

export default {
  namespaced,
  state,
  mutations,
  getters,
  actions,
};
