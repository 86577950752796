import { ActionTree } from "vuex";
import { RootState } from "../../types";
import { IWorkClockRootState } from "./types";
import workClockApi from "../../../utils/api/workClock.api";

export const actions: ActionTree<IWorkClockRootState, RootState> = {
  async getEmployeesStatusForShopByEmails({
    rootState,
    rootGetters,
  }): Promise<void> {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;
    const staffEmailsList = rootGetters["staffModule/getStaffEmailsList"];

    const data: any = {
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
      emails: staffEmailsList,
    };

    try {
      await workClockApi.getEmployeesStatusForShopByEmails(data);
    } catch (err) {
      console.error(`get employees by status request: ${err}`);
    }
  },

  async clockOut({ commit, rootState }, payload) {
    const {
      user: { sites, defaultSite },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
      shiftId: payload,
    };

    commit("commonModule/setIsLoading", true, { root: true });

    try {
      return workClockApi.clockOut(data);
    } catch (err) {
      console.error(`clock out request: ${err}`);
    } finally {
      commit("commonModule/setIsLoading", false, { root: true });
    }
  },

  async clockIn({ commit, rootState }) {
    const {
      user: { sites, defaultSite },
      tokenDetails,
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      email: tokenDetails.email,
    };

    commit("commonModule/setIsLoading", true, { root: true });

    try {
      const rsp: any = await workClockApi.clockIn(data);
      if (rsp.success) commit("setIsUserActive", true);
    } catch (err) {
      console.error(`clock in request: ${err}`);
    } finally {
      commit("commonModule/setIsLoading", false, { root: true });
    }
  },

  async getShiftState({ rootState }) {
    const {
      user: { sites, defaultSite },
      tokenDetails,
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      email: tokenDetails.email,
    };

    return workClockApi.getShiftState(data);
  },
};
