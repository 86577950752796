var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{staticClass:"py-0 list-height"},[_vm._l((_vm.getStaffPayRatesData),function(member,index){return [(index > 0)?_c('v-divider',{key:`divider-${member.name}`}):_vm._e(),_c('v-list-item',{key:`item-${member.name}`,attrs:{"value":member,"active-class":"active-class-item"}},[_c('v-list-item-content',{staticClass:"px-0 py-6"},[_c('div',{staticClass:"align-center"},[_c('v-avatar',{attrs:{"size":"30"}},[_c('img',{attrs:{"src":_vm.placeholder,"alt":"placeholder"}})]),_c('v-list-item-title',{staticClass:"member-name mr-3 ml-2",staticStyle:{"flex":"unset"},domProps:{"textContent":_vm._s(member.name)}}),_c('div',{staticClass:"ml-auto"},[_c('label',[_vm._v("Hourly rate")]),_c('v-text-field',{key:`rateInput${index}`,staticStyle:{"max-width":"225px"},attrs:{"full-width":"","outlined":"","dense":"","hide-details":"","color":"primary","type":"number","prefix":_vm.getCurrentCurrency.mark,"placeholder":"0.00 / hr"},model:{value:(
                member.services[_vm.hourlyRateService].payRates[
                  _vm.servicePaymentTypeMap[_vm.hourlyRateService]
                ]
              ),callback:function ($$v) {_vm.$set(member.services[_vm.hourlyRateService].payRates, 
                  _vm.servicePaymentTypeMap[_vm.hourlyRateService]
                , $$v)},expression:"\n                member.services[hourlyRateService].payRates[\n                  servicePaymentTypeMap[hourlyRateService]\n                ]\n              "}})],1)],1)])],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }