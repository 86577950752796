
import BaseDialog from "../BaseDialog.vue";
import { IBaseDialog } from "../../types/baseDialog.interface";
import constants from "../../utils/constants/constants.util";
import { ISetting } from "../../types/staffPaymentSettings.interface";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ReportsSettings",
  components: { BaseDialog },
  props: {
    value: {
      type: Boolean,
    },
  },
  data(): { labels: object } {
    return {
      labels: constants.LABELS,
    };
  },
  computed: {
    ...mapGetters({
      getSettings: "payRatesModule/getSettings",
    }),
    selectedSettings(): { [key: string]: string } {
      return {
        payFrequency: this.getSettings.payFrequency || "",
      };
    },
    settingsData(): ISetting[] {
      return [
        {
          title: "Payroll Frequency",
          key: "payFrequency",
          options: ["Weekly", "Bi Weekly", "Monthly"],
          defaultTitle: "How often do you pay your staff?",
        },
      ];
    },
    isOpenSettingsDialog: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit("input", val);
      },
    },
    dialogActions(): IBaseDialog[] {
      return [
        {
          label: "cancel",
          outlined: true,
          color: "primary",
          onClick: () => {
            this.$emit("closeDialog");
          },
        },
        {
          label: "save",
          color: "primary",
          onClick: async () => {
            this.$store.commit("commonModule/setIsLoading", true, {
              root: true,
            });
            this.saveSettings(this.selectedSettings)
              .catch((err: Error) => {
                console.error(`save settings request ${err}`);
              })
              .finally(() => {
                this.$store.commit("commonModule/setIsLoading", false, {
                  root: true,
                });
                this.$emit("closeDialog");
              });
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      saveSettings: "payRatesModule/saveSiteSettings",
    }),
  },
};
