import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { ICommonRootState, IUser } from "./types";

const namespaced = true;

export const state: ICommonRootState = {
  user: {} as IUser,
  isLoading: false,
  siteServicesList: [],
  instanceId: "",
  isApiError: false,
  errorMessage: "",
  currentShopIndex: 0,
  isUserActive: false,
  generalData: [
    {
      departments: [],
    },
  ],
  positions: [],
  tokenDetails: {},
  redirectFrom: null,
  activeRoute: "",
  inviteStaffDialogMembers: [],
};

export default {
  namespaced,
  state,
  mutations,
  getters,
  actions,
};
