import { pngList } from "../assets.util";

const LOGIN_LIST_DATA = [
  "Sync your staff’s info and schedule",
  "Set how you pay your staff and their rates",
  "Calculate total payment and export the report for payroll",
];

const REGISTER_LIST_DATA = [
  "Sync your staff’s info and schedule",
  "Set how you pay your staff and their rates",
  "Calculate total payment and export the report for payroll",
];

const LOGIN_SUBTITLE =
  "Calculate what to pay your staff based on their bookings and time sheets.";

const REGISTER_TITLE = "Get Your Business Payroll Ready";

const REGISTER_SUBTITLE =
  "Calculate what to pay your staff based on their bookings and time clock.";

const PAYMENT_FREQUENCY_OPTIONS = {
  title: "How often do you pay your staff?",
  items: [
    {
      title: "Weekly",
      key: "Weekly",
    },
    {
      title: "Bi-Weekly",
      key: "Bi Weekly",
    },
    {
      title: "Monthly",
      key: "Monthly",
    },
  ],
};

const PAYMENT_OPTIONS = {
  title: "How do you pay your staff?",
  items: [
    {
      title: "Per session",
      key: "Session",
      text: "You pay your staff based on the sessions they hold.",
      icon: pngList.frame,
    },
    {
      title: "Per hour",
      key: "Hour",
      text: "You pay your staff according to the logged hours in their timesheets.",
      icon: pngList.clock,
    },
    {
      title: "Both",
      key: "Both",
      text: "You pay your staff based on how many sessions they hold and the logged hours in their timesheets.",
      icon: pngList.schedule,
    },
  ],
};

const RATES_OPTIONS = {
  title: "How do you set your pay rates?",
  items: [
    {
      title: "Flat rate",
      key: "Flat",
      text: "Your staff gets a flat rate for each session",
      subtext: "e.g. A staff member earns $100 per session",
      icon: pngList.currencty,
    },
    {
      title: "Percentage rate",
      key: "Percentage",
      text: "Your staff gets a percentage of each session's revenue.",
      subtext:
        "e.g. if a staff member gets 50% of a class that makes $300, they would earn $150.",
      icon: pngList.discount,
    },
    {
      title: "Both",
      key: "Both",
      text: "Your payment model varies by service and can include both flat rates and percentage rates.",
      icon: pngList.frameRates,
    },
  ],
};

const PARTICIPANTS_OPTIONS_STEP_TWO = {
  title: "Who gets counted in the participant rate?",
  items: [
    {
      title: "Booked participants",
      key: "Bookings",
      text: "participants who book the session, regardless of attendance",
    },
    {
      title: "Checked-in participants",
      key: "CheckIns",
      text: "participants who attend the session and check-in via Wix Bookings",
    },
  ],
};

const PARTICIPANTS_OPTIONS_STEP_ONE = {
  title: "Do you have a participant rate?",
  subtitle:
    "Your staff gets paid for each participant that books or attends their session",
  items: [
    {
      title: "Yes",
      key: true,
      titleIcon: "mdi-check",
    },
    {
      title: "No",
      key: false,
      titleIcon: "mdi-close",
    },
  ],
};

const STEPS_MAP = {
  1: 25,
  2: 50,
  3: 75,
  4: 100,
  5: 125,
};

const ONBOARDING_LABELS = {
  INVITE_STAFF_PAGE: {
    TITLE: "Add Your Staff Members",
    FIRST_DESCRIPTION:
      "Select which staff you would like to sync from Wix Bookings.",
    SECOND_DESCRIPTION:
      "If you have hourly employees, you can add them as well by clicking on the ‘Add non-Bookings staff’ button.",
  },
  ONBOARDING_STAFF_PAY_RATES: {
    TITLE: "Set up your staff pay rates",
  },
  ONBOARDING_INVITE_STAFF_DIALOG: {
    TITLE: "Add staff members outside of Wix Bookings",
  },
};

const onboardingConstants = {
  LOGIN_LIST_DATA,
  REGISTER_LIST_DATA,
  LOGIN_SUBTITLE,
  REGISTER_TITLE,
  REGISTER_SUBTITLE,
  PAYMENT_OPTIONS,
  RATES_OPTIONS,
  PARTICIPANTS_OPTIONS_STEP_TWO,
  PARTICIPANTS_OPTIONS_STEP_ONE,
  STEPS_MAP,
  ONBOARDING_LABELS,
  PAYMENT_FREQUENCY_OPTIONS,
};

export default onboardingConstants;
