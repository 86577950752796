
import formRules from "../utils/formRules.util";
import { pngList } from "../utils/assets.util";
import { rootColorNames } from "../utils/root.util";
import constants from "../utils/constants/constants.util";
import { IWelcomePage } from "../types/welcomePages.interface";
import WelcomePageWrapper from "../components/WelcomePageWrapper.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoginPage",
  components: { WelcomePageWrapper },
  props: {
    registrationMode: {
      type: String,
    },
    isRegisterViaSignIn: {
      type: Boolean,
    },
    userToken: {
      type: String,
    },
  },
  data(): IWelcomePage {
    return {
      password: "",
      email: "",
      formRules: formRules(),
      isShowPass: false,
      appLogo: pngList.appLogo,
      isRememberMe: false,
      applicationColor: rootColorNames.applicationColor,
      appName: constants.commonConstants.APP_NAME,
      mainLogo: pngList.main,
      labels: constants.LABELS,
    };
  },
  computed: {
    ...mapGetters({
      isApiError: "commonModule/getIsApiError",
      errorMessage: "commonModule/getErrorMessage",
    }),
  },
  created(): void {
    const authenticateUserDetails = this.$cookies.get(
      "authenticateUserDetails"
    );

    if (authenticateUserDetails) {
      this.isRememberMe = authenticateUserDetails.isRememberMe;
    }
  },
  methods: {
    ...mapActions("commonModule", ["loginToPlatform"]),
    handleSubmit(): void {
      let data: {
        email: string;
        password: string;
        isRememberMe?: boolean;
        redirectTo: string;
      } = {
        email: this.email,
        password: this.password,
        redirectTo: "/reports",
      };

      if (this.isRememberMe) {
        (data.isRememberMe = this.isRememberMe),
          this.$cookies.set(
            "authenticateUserDetails",
            data,
            3600 * 1000 * 24 * 365 * 10
          );
      }
      if (this.$refs.form.validate()) this.loginToPlatform(data);
    },
  },
};
