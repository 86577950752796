import { MutationTree } from "vuex";
import { IPayRates } from "./types";
import constants from "../../../utils/constants/constants.util";

export const mutations: MutationTree<IPayRates> = {
  setSettings(state, data): void {
    state.settings = data;
  },
  setPaymentTypes(state, data): void {
    state.paymentTypes = data;
  },
  setTabIndex(state, data): void {
    state.tabIndex = data;
  },
  setIsDisabledPayRates(state, data): void {
    state.isDisabledPayRates = data;
  },
  setServiceNamesOnDisabled(state, data): void {
    state.serviceNamesOnDisabled = data;
  },
  setPayRatePageData(state, data): void {
    state.payRatePageData = data.map((item: any) => {
      return {
        ...item,
        services: {
          ...item.services,
          [constants.HOURLY_RATE_SERVICE]: {
            isSelected: !!item.paymentAmount,
            payRates: {
              hourlyRate: item.paymentAmount,
            },
          },
        },
      };
    });
  },
  setIsPayRatesExists(state, data) {
    state.isPayRatesExists = data;
  },
};
