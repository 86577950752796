
import moment from "moment";
import { mapGetters } from "vuex";
import formRules from "../../utils/formRules.util";

export default {
  name: "ReportsDateRange",
  props: {
    isDisplayLabel: {
      type: Boolean,
      default: true,
    },
    rangeDates: {
      type: Array,
    },
    isReportPage: {
      type: Boolean,
      default: false,
    },
  },
  data(): { dates: string[]; menu: boolean; formRules: object } {
    return {
      dates: [],
      menu: false,
      formRules: formRules(),
    };
  },
  computed: {
    ...mapGetters({
      getTokenDetails: "commonModule/getTokenDetails",
    }),
    isSubmitDisabled(): boolean {
      const isValidDates =
        moment(this.dates[1], "YYYY-MM-DD").diff(
          moment(this.dates[0], "YYYY-MM-DD")
        ) > 0;
      return !isValidDates;
    },
    dateRangeText(): string {
      const fromDate = moment(this.dates[0]).format("LL");
      const toDate = moment(this.dates[1]).format("LL");

      const dates = [fromDate, toDate];
      return dates.join(" - ");
    },
  },
  watch: {
    dates(val: string[]): void {
      if (val.length === 2 && this.isReportPage) {
        this.$refs.menu.save(this.dates);
        this.$emit("setDate", this.dates);
      }
    },
    rangeDates(val: string[]): void {
      this.dates = val;
    },
  },
  methods: {
    saveDatesRange(): void {
      this.$refs.menu.save(this.dates);
      this.$emit("setDate", this.dates);
    },
  },
  created(): void {
    this.dates = this.rangeDates;
  },
};
