import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { IOnboardingRootState } from "./types";

export const state: IOnboardingRootState = {
  tableData: [],
  isOnBoarding: false,
};

const namespaced = true;

export default {
  namespaced,
  state,
  mutations,
  getters,
  actions,
};
