import helper from "../helper.util";
import { axiosRequest } from "../../../utils/auth";
import { AxiosResponse } from "axios";

const controllerName = "auth";

const refreshToken = (data: { instanceId: string }): Promise<AxiosResponse> => {
  const options = helper.getRequestOptions(data);
  return axiosRequest(`${controllerName}/refreshToken`, options);
};

const authApi = {
  refreshToken,
};

export default authApi;
