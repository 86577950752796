
import { animations } from "../utils/assets.util";
import { mapGetters } from "vuex";

export default {
  data(): { logo: string } {
    return {
      logo: animations.logo,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "commonModule/getIsLoading",
    }),
  },
};
