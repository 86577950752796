import { ActionTree } from "vuex";
import payRatesApi from "../../../utils/api/payRates.api";
import { RootState } from "../../types";
import { IPayRates } from "./types";
import payRateHelper from "../../../utils/payRatesTable.util";

export const actions: ActionTree<IPayRates, RootState> = {
  async getPayRatesPageData({ commit, rootState }) {
    const {
      user: { sites, defaultSite },
      tokenDetails,
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
      email: tokenDetails.email,
    };

    commit("commonModule/setIsLoading", true, { root: true });

    try {
      const tableData: any = await payRatesApi.getPayRatesPageData(data);

      if (tableData.success) {
        const { table, services } = tableData;

        const mapServiceIds = services.reduce((acc: any, service: any) => {
          if (service.name) acc[service.name] = service.id;
          return acc;
        }, {});

        const formattedTable = table.map((member: any) => {
          return {
            ...member,
            services: payRateHelper.formattedServices(
              member.services,
              mapServiceIds,
              false,
              table[0]?.services
            ),
          };
        });

        commit("setPayRatePageData", formattedTable);

        commit("commonModule/setSiteServicesList", services, { root: true });

        return tableData.success;
      }
    } catch (err) {
      console.error(`get pay rates page data request ${err}`);
    } finally {
      commit("commonModule/setIsLoading", false, { root: true });
      // eslint-disable-next-line no-undef,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      $crisp.push(["do", "chat:show"]);
    }
  },
  async getSiteSettings({ rootState }) {
    const {
      user: { sites, defaultSite },
      tokenDetails,
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      instanceId: sites[currentShopIndex]?.instanceId || defaultSite,
      email: tokenDetails.email,
    };

    try {
      return payRatesApi.getSiteSettings(data);
    } catch (err) {
      console.error(`get site settings request ${err}`);
    }
  },
  async saveSiteSettings({ rootState }, payload) {
    const {
      user: { sites },
      currentShopIndex,
    } = rootState.commonModule;
    const data = {
      instanceId: sites[currentShopIndex].instanceId,
      ...payload,
    };
    try {
      return payRatesApi.saveSiteSettings(data);
    } catch (err) {
      console.error(`get site settings request ${err}`);
    }
  },
};
