
import helper from "../utils/helper.util";
import { mapGetters } from "vuex";
import moment from "moment";
import { IMember } from "../types/member.interface";

export default {
  name: "Member",
  props: {
    isOnlyDisplayName: {
      type: Boolean,
      default: false,
    },
    memberDetails: {
      type: Object,
    },
    isDisplayName: {
      type: Boolean,
      default: true,
    },
  },
  data(): IMember {
    return {
      avatarFile: "",
    };
  },
  computed: {
    ...mapGetters({
      getTokenDetails: "commonModule/getTokenDetails",
      user: "commonModule/getUser",
      isUserActive: "commonModule/getIsUserActive",
      usersActiveMap: "workClockModule/getUsersActiveMap",
      getDepartmentColorByDepartmentName:
        "commonModule/getDepartmentColorByDepartmentName",
    }),
    getName(): string {
      const { userName } = this.memberDetails;
      return this.memberDetails && userName ? userName : "";
    },
    getShortName(): string {
      const { userName } = this.memberDetails;

      const isUserExists = this.memberDetails && userName;
      return isUserExists && helper.getShortName(userName);
    },
    isAvatarUrlExist(): boolean {
      const { avatarFile } = this.memberDetails;
      return this.memberDetails && avatarFile;
    },
    avatarUrl(): string {
      const { userId, avatarFile } = this.user;
      const { userId: memberId, avatarFile: memberAvatar } = this.memberDetails;
      return this.user && memberId === userId ? avatarFile : memberAvatar;
    },
    tooltipText(): string[] {
      const { email } = this.memberDetails;
      const isActive =
        this.usersActiveMap[email] && this.usersActiveMap[email].updatedAt;
      const time =
        isActive &&
        moment(this.usersActiveMap[email].updatedAt).format("h:mm A");
      const totalActivity =
        isActive &&
        moment.duration(
          moment().diff(moment(this.usersActiveMap[email].updatedAt))
        );

      const text = `Clock in at ${time}, ${
        totalActivity && totalActivity.hours()
      } hours and ${totalActivity && totalActivity.minutes()} minutes`;
      return time && totalActivity ? text.split(",") : [];
    },
    isActiveStatus(): boolean {
      const { email } = this.getTokenDetails;

      if (this.isUserActive && this.memberDetails?.email === email) return true;
      return this.usersActiveMap[this.memberDetails?.email]
        ? this.usersActiveMap[this.memberDetails?.email].isActive
        : false;
    },
  },
  methods: {
    moment,
  },
  created(): void {
    this.avatarFile = this.memberDetails?.avatarFile;
  },
};
