var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pt-6 pl-3 section-padding-right pb-10",class:_vm.isOnBoarding ? 'card-att-on-boarding' : 'card-att'},[_c('v-expansion-panels',{model:{value:(_vm.defaultStaff),callback:function ($$v) {_vm.defaultStaff=$$v},expression:"defaultStaff"}},_vm._l((_vm.getSiteTable),function(member,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{staticClass:"py-4"},[(!member.avatarFile)?_c('v-avatar',{attrs:{"color":"#DFE3E8","size":"50","tile":""}},[_c('div',{staticClass:"avatar-text-att"},[_vm._v(_vm._s(_vm.getShortName(member.name)))])]):_vm._e(),(member.avatarFile)?_c('v-img',{staticStyle:{"border-radius":"8px"},attrs:{"width":"50","height":"50","src":member.avatarFile,"alt":"member avatar"}}):_vm._e(),_c('div',{staticClass:"pl-4"},[_vm._v(_vm._s(member.name))]),_c('div',{staticClass:"all-staff-wrapper"},[_c('pay-rates-copy-all-staff',{attrs:{"payment-amount":_vm.getSafeHourlyRate(member.services),"member":member,"user-name":member.name,"user-email":member.email,"is-on-boarding":_vm.isOnBoarding}})],1)],1),_c('v-expansion-panel-content',[_c('v-list',{staticClass:"px-4 list-att"},[_vm._l((_vm.getSiteServicesList),function(service,index){return [_c('v-list-item',{key:index,staticClass:"py-2"},[_c('v-list-item-content',{staticStyle:{"max-width":"300px"}},[(
                    service.name === 'Hourly Rate' &&
                    _vm.getSettings.payPer !== 'Session'
                  )?_c('v-list-item-title',[_c('v-icon',{attrs:{"size":"20","color":"#13123C"}},[_vm._v("mdi-alarm")]),_c('span',{staticClass:"px-2"},[_vm._v("Time Clock")])],1):_c('v-list-item-title',[_vm._v(" "+_vm._s(service.name)+" ")])],1),_c('div',{staticClass:"px-4"},[(service.serviceType)?_c('v-chip',{attrs:{"label":"","x-small":""}},[_vm._v(_vm._s(service.serviceType))]):_vm._e()],1),_c('v-list-item-action',{staticClass:"ml-auto align-center",staticStyle:{"flex-direction":"unset"}},[(
                    member.services &&
                    member.services[service.name] &&
                    member.services[service.name].isSelected
                  )?_c('div',{staticClass:"align-center"},[_c('span',{staticClass:"px-3"},[_vm._v(" "+_vm._s(_vm.getInputText(service.name, member.services))+" ")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"number","placeholder":_vm.servicePlaceholder(
                        service.name,
                        member.services[service.name].paymentType
                      ),"prefix":member.services[service.name].paymentType !==
                      'Percentage rate'
                        ? _vm.getCurrentCurrency.mark
                        : '',"suffix":member.services[service.name].paymentType ===
                      'Percentage rate'
                        ? '%'
                        : '',"hide-details":""},on:{"input":_vm.textChange},model:{value:(
                      member.services[service.name].payRates[
                        _vm.servicePaymentTypeMap[
                          service.name === 'Hourly Rate'
                            ? service.name
                            : member.services[service.name].paymentType
                        ]
                      ]
                    ),callback:function ($$v) {_vm.$set(member.services[service.name].payRates, 
                        _vm.servicePaymentTypeMap[
                          service.name === 'Hourly Rate'
                            ? service.name
                            : member.services[service.name].paymentType
                        ]
                      , $$v)},expression:"\n                      member.services[service.name].payRates[\n                        servicePaymentTypeMap[\n                          service.name === 'Hourly Rate'\n                            ? service.name\n                            : member.services[service.name].paymentType\n                        ]\n                      ]\n                    "}})],1):_vm._e(),(
                    member.services &&
                    member.services[service.name] &&
                    member.services[service.name].isParticipant &&
                    member.services[service.name].isSelected
                  )?_c('div',[(
                      member.services[service.name].payRates['participant'] &&
                      service.serviceType !== 'Course'
                    )?_c('div',{staticClass:"align-center"},[_c('div',[_c('div',{staticClass:"pl-5 participant-range"},[_vm._v(" "+_vm._s(_vm.getParticipantRange( member.services[service.name].payRates[ "participant" ] ))+" ")]),_c('div',{staticClass:"pl-5"},[_vm._v("Per participant")])]),_c('pay-rates-participant-steps',{attrs:{"mode":"manage"},model:{value:(
                        member.services[service.name].payRates['participant']
                      ),callback:function ($$v) {_vm.$set(member.services[service.name].payRates, 'participant', $$v)},expression:"\n                        member.services[service.name].payRates['participant']\n                      "}})],1):(service.serviceType !== 'Course')?_c('pay-rates-participant-steps',{model:{value:(
                      member.services[service.name].payRates['participant']
                    ),callback:function ($$v) {_vm.$set(member.services[service.name].payRates, 'participant', $$v)},expression:"\n                      member.services[service.name].payRates['participant']\n                    "}}):_vm._e()],1):_vm._e(),(
                    (member.services &&
                      member.services[service.name] &&
                      member.services[service.name].paymentType !== '') ||
                    service.name === 'Hourly Rate'
                  )?_c('div',[(
                      member.services &&
                      member.services[service.name] &&
                      !member.services[service.name].paymentType &&
                      service.name !== 'Hourly Rate'
                    )?_c('div',[_vm._v(" Missing payment model ")]):(
                      member.services &&
                      member.services[service.name] &&
                      !member.services[service.name].isSelected
                    )?_c('v-btn',{staticClass:"text-capitalize",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){member.services[service.name].isSelected = true}}},[_vm._v("+ Add Rates")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"pl-4"},[(
                      member.services &&
                      member.services[service.name] &&
                      member.services[service.name].payRates[
                        _vm.servicePaymentTypeMap[
                          member.services[service.name].paymentType
                        ]
                      ]
                    )?_c('pay-rates-copy-menu',{attrs:{"service-name":service.name,"service-type":service.serviceType,"mode":"payRates","pay-rates":member.services[service.name],"member-email":member.email,"is-on-boarding":_vm.isOnBoarding}}):_vm._e()],1)])],1),(index < _vm.getSiteServicesList.length - 1)?_c('v-divider',{key:`divider-${index}`}):_vm._e()]})],2)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }