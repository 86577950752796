import { render, staticRenderFns } from "./OnBoardingStepperFooter.vue?vue&type=template&id=cc604c50&scoped=true&"
import script from "./OnBoardingStepperFooter.vue?vue&type=script&lang=ts&"
export * from "./OnBoardingStepperFooter.vue?vue&type=script&lang=ts&"
import style0 from "./OnBoardingStepperFooter.vue?vue&type=style&index=0&id=cc604c50&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc604c50",
  null
  
)

export default component.exports