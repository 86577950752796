
import { mapGetters } from "vuex";
import constants from "../../utils/constants/constants.util";
import { IReportPage } from "../../types/reportsPage.interface";

export default {
  name: "ReportSelectStaff",
  props: {
    value: {
      type: Array,
    },
  },
  data(): { selectedStaff: string[]; defaultSelect: string } {
    return {
      selectedStaff: [],
      defaultSelect: constants.ALL_STAFF,
    };
  },
  computed: {
    ...mapGetters({
      getReportsPageData: "reportsModule/getReportsPageData",
    }),
    getTooltipData(): string {
      return this.selectedStaff.slice(2, this.selectedStaff.length).join(", ");
    },
    staff: {
      get(): string[] {
        const isSelectedAll = this.selectAllStaff;
        return isSelectedAll ? [this.defaultSelect] : this.value;
      },
      set(val: string[]): void {
        if (val.includes(this.defaultSelect)) {
          const filteredStaffList = val.filter(
            (item: string) => item !== this.defaultSelect
          );

          return this.$emit("input", filteredStaffList);
        }
        this.$emit("input", val);
      },
    },
    getStaffData(): IReportPage[] {
      return this.getReportsPageData || [];
    },
    selectAllStaff(): boolean {
      return this.selectedStaff.length === this.getStaffData.length;
    },
    selectSomeStaff(): boolean {
      return this.selectedStaff.length > 0 && !this.selectAllStaff;
    },
    icon(): string {
      if (this.selectAllStaff) return "mdi-checkbox-outline";
      if (this.selectSomeStaff) return "mdi-minus-box-outline";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    toggle(): void {
      this.$nextTick(() => {
        if (this.selectAllStaff) {
          this.selectedStaff = [];
          this.$emit("getSelectedStaff", []);
        } else {
          this.selectedStaff = this.getStaffData
            .slice()
            .map((item: { email: string }) => item.email);

          this.$emit("getSelectedStaff", this.selectedStaff);
        }
      });
    },
  },
  watch: {
    selectedStaff(val: string[]): void {
      this.$emit("getSelectedStaff", val);
    },
    getStaffData(val: IReportPage[]): void {
      this.selectedStaff = val.map((item: { email: string }) => item.email);
    },
  },
};
