
import BaseDialog from "../BaseDialog.vue";
import Member from "../Member.vue";
import {
  IShift,
  ITimeSheetsDetails,
  ITimeSheetsViewDialog,
  ITimeSheetsViewDialogHeaders,
  IUserTimeCardData,
} from "../../types/timeSheets.interface";
import { mapActions, mapGetters } from "vuex";
import get from "lodash/get";
import moment from "moment";
import TimeSheetsCreateShift from "./TimeSheetsCreateShift.vue";
import { IDialogActions } from "../../types/baseDialog.interface";
import reportUtil from "../../utils/reports.util";

import helper from "../../utils/helper.util";

export default {
  name: "TimeSheetsViewDialog",
  props: {
    memberDetails: {
      type: Object,
    },
    value: {
      type: Boolean,
    },
    isReportPage: {
      type: Boolean,
      default: false,
    },
    chosenDates: {
      type: Array,
    },
  },
  components: { TimeSheetsCreateShift, Member, BaseDialog },
  data(): ITimeSheetsViewDialog {
    return {
      isOpenDeleteDialog: false,
      sortBy: "start",
      sortDesc: true,
      userTimeCardData: [],
      dialogText: "",
      newShift: {} as IShift,
      isOpenCreateShift: false,
      shiftId: "",
      reportRate: 0,
      totalTimeSheets: 0,
    };
  },
  computed: {
    ...mapGetters({
      getDepartmentColorByDepartmentName:
        "commonModule/getDepartmentColorByDepartmentName",
      isAdmin: "commonModule/getIsAdmin",
      timeSheetsList: "timeSheetsModule/getTimeSheetsListData",
      getSiteCurrency: "commonModule/getSiteCurrency",
    }),
    getTimeSheetsTotal(): number {
      if (this.isReportPage) return this.totalTimeSheets.toFixed(2) || 0;
      return this.memberDetails.totalPay
        ? this.memberDetails.totalPay.amount
        : 0;
    },
    isOpenViewDialog: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit("input", val);
      },
    },
    getViewData(): IUserTimeCardData[] {
      return this.userTimeCardData;
    },
    headers(): ITimeSheetsViewDialogHeaders[] {
      return [
        { text: "Date & Time", value: "start", align: "start" },
        { text: "Hours", value: "hours", align: "start" },
        { text: "Rate", value: "rate", align: "start" },
        { text: "Amount", value: "amount", align: "start" },
        { text: "", value: "edit", sortable: false },
        { text: "", value: "delete", sortable: false },
      ].filter((x) => !!x);
    },
    dialogActions(): IDialogActions[] {
      return [
        {
          label: "cancel",
          outlined: true,
          onClick: () => (this.isOpenDeleteDialog = false),
        },
        {
          label: "delete",
          color: "primary",
          onClick: () => this.deleteShift(),
        },
      ];
    },
  },
  watch: {
    memberDetails: {
      handler(val: ITimeSheetsDetails): void {
        this.reportRate = val.paymentAmount;
        if (this.isReportPage) {
          this.userTimeCardData = get(val, "timeCardData.shiftsData", []);
          this.userTimeCardData.forEach((item: IUserTimeCardData) => {
            const amountFormatted = parseFloat(item.amount);
            this.totalTimeSheets += amountFormatted;
          });
        } else {
          this.userTimeCardData = get(val, "userTimeCardData.shifts", []);
        }
      },
      deep: true,
    },
    timeSheetsList: {
      handler(val: ITimeSheetsDetails[]): void {
        const timeSheetsFiltered = val.filter(
          (item) => item.email === this.memberDetails.email
        );

        this.userTimeCardData = get(
          timeSheetsFiltered[0],
          "userTimeCardData.shifts",
          []
        );
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      deleteCurrentShift: "timeSheetsModule/deleteShift",
      getTimeSheetsList: "timeSheetsModule/getTimeSheetsPageData",
    }),
    async getTimeSheetsCardCSV(): Promise<void> {
      const [fromDate, toDate] = this.chosenDates;

      const dateRange = [
        moment(fromDate).format("LL"),
        moment(toDate).format("LL"),
      ].join("-");

      const csvString = this.getViewData.map((timeSheet: any) => {
        return {
          Date: moment(timeSheet.start).format("LL"),
          Hours: timeSheet.hours,
          Rate: timeSheet.rate,
          Amount: timeSheet.amount,
        };
      });

      const csvStringFormatted = await reportUtil.getResultCSVString(csvString);

      helper.downloadCSV(
        csvStringFormatted,
        `${this.memberDetails.userName}-Timesheets Payroll-${dateRange}`
      );
    },
    closeCreateDialog(): void {
      this.isOpenCreateShift = false;
      this.$emit("openViewDialog");
    },
    openCreateShiftDialog(): void {
      this.isOpenCreateShift = true;
      this.$emit("closeDialog");
      this.newShift = {
        start: moment(),
        end: moment(),
        date: moment(),
      };
    },
    async deleteShift(): Promise<void> {
      this.$store.commit("commonModule/setIsLoading", true);
      this.deleteCurrentShift(this.shiftId)
        .then(() => {
          this.isOpenDeleteDialog = false;
          let fromDate = moment().startOf("month").startOf("day").format();
          let toDate = moment().endOf("month").endOf("day").format();

          if (this.chosenDates && this.chosenDates.length > 0) {
            fromDate = moment(this.chosenDates[0]).startOf("day").format();
            toDate = moment(this.chosenDates[1]).endOf("day").format();
          }

          const data = {
            fromDate,
            toDate,
          };

          this.getTimeSheetsList(data);
        })
        .finally(() => {
          this.$store.commit("commonModule/setIsLoading", true);
        });
    },
    dateFormatted(date: string): string {
      const shiftDate = new Date(date);
      return moment(shiftDate).format("DD MMMM hh:mm A");
    },
    openDeleteDialog(item: IUserTimeCardData): void {
      this.isOpenDeleteDialog = true;
      this.shiftId = item.id;
      this.dialogText = "Are you sure you want to remove this shift?";
    },
    openEditDialog(item: IUserTimeCardData): void {
      this.isOpenCreateShift = true;
      this.newShift = { ...item, mode: "edit" };
      this.$emit("closeDialog");
    },
  },
  mounted(): void {
    if (this.isReportPage) {
      this.userTimeCardData = get(
        this.memberDetails,
        "timeCardData.shiftsData",
        []
      );
      this.reportRate = get(this.memberDetails, "hourlyRate.paymentAmount", 0);
    } else {
      this.userTimeCardData = get(
        this.memberDetails,
        "userTimeCardData.shifts",
        []
      );
    }
  },
};
