
import helper from "../utils/helper.util";
import { rootColorNames } from "../utils/root.util";
import { IMenuData, ISites, IUserHeader } from "../types/userHeader.interface";
import { mapActions, mapGetters } from "vuex";
import get from "lodash/get";
import headerHelper from "../utils/headerHelper.util";

export default {
  name: "UserHeader",
  data(): IUserHeader {
    return {
      applicationColor: rootColorNames.applicationColor,
      isImageUploading: false,
      avatarFile: null,
      toggleMainMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "commonModule/getUser",
      isUserActive: "commonModule/getIsUserActive",
      getCurrentSiteIndex: "commonModule/getCurrentSiteIndex",
      getTokenDetails: "commonModule/getTokenDetails",
    }),
    srcAvatarPath(): string | null {
      const { avatarFile: userAvatar } = this.user;
      const avatarFile = userAvatar || this.avatarFile;

      if (avatarFile) return helper.getAvatarSrc(avatarFile);
      return null;
    },
    shortName(): string {
      const isUserExists = this.user && this.user.userName;
      return isUserExists && helper.getShortName(this.user.userName);
    },
    sites(): [ISites] {
      return get(this.user, "sites", []);
    },
    menuData(): IMenuData[] {
      return [
        {
          name: "My Sites",
          items: this.sites,
          isDisplayIcon: true,
        },
        {
          name: "Log out",
          action: () => {
            this.logout();
            this.toggleMainMenu = false;
          },
          isDisplayIcon: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      changeSiteReq: "commonModule/changeSite",
    }),
    logout(): void {
      sessionStorage.clear();
      this.$cookies.remove("authenticateUserDetails");
      return this.$router.push("/");
    },
    changeSite(shopIndex: number, instanceId: string): void {
      this.$store.commit("commonModule/setIsLoading", true);
      this.$store.commit("commonModule/setCurrentShopIndex", shopIndex);
      sessionStorage.setItem("currentShopIndex", JSON.stringify(shopIndex));
      this.changeSiteReq(instanceId)
        .then(() => {
          window.location.reload(true);
        })
        .finally(() => {
          this.$store.commit("commonModule/setIsLoading", false);
        });
    },
    async setUserDefaultSite(instanceId: string): Promise<void> {
      this.$store.commit("commonModule/setIsLoading", true);
      try {
        const rsp: any = await headerHelper.setUserDefaultSite(
          instanceId,
          this.getTokenDetails
        );

        if (rsp.data.success) {
          const userData = {
            ...this.user,
            defaultSite: instanceId,
          };

          this.$store.commit("commonModule/setUserDetails", userData);
        }
      } catch (err) {
        console.error(`set user default site: ${err}`);
      } finally {
        this.$store.commit("commonModule/setIsLoading", false);
      }
    },
  },
};
