
import { mapState } from "vuex";
import { pngList } from "../utils/assets.util";
import {
  IWelcomePage,
  IWelcomePageSize,
} from "../types/welcomePages.interface";
import BackButton from "./BackButton.vue";

export default {
  name: "WelcomePageWrapper",
  components: { BackButton },
  props: {
    isShowBackBtn: {
      type: Boolean,
      default: false,
    },
  },
  data(): IWelcomePage {
    return {
      appLogo: pngList.appLogo,
      mainLogo: pngList.main,
    };
  },
  computed: {
    ...mapState(["isError", "errorMessage"]),
    size(): IWelcomePageSize | undefined {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return { col: 4, image: "200" };
        case "sm":
          return { col: 4, image: "400" };
        case "md":
          return { col: 6, image: "400" };
        case "lg":
          return { col: 6, image: "500" };
        case "xl":
          return { col: 6, image: "600" };
        default:
          return { col: 7, image: "300" };
      }
    },
  },
};
