
import { mapActions, mapGetters } from "vuex";
import constants from "../../utils/constants/constants.util";
import { IOnBoardingPayRates } from "../../types/onBoardingPayRates.interface";
import PayRatesServices from "../payRates/PayRatesServices.vue";
import PayRatesStaffMembers from "../payRates/PayRatesStaffMembers.vue";
import payRateHelper from "../../utils/payRatesTable.util";

export default {
  name: "onBoardingPayRates",
  props: {
    selectedRateOption: {
      type: String,
      default: "",
    },
    selectedPaymentOption: {
      type: String,
      default: "",
    },
    isParticipant: {
      type: Boolean,
    },
    selectedParticipant: {
      type: String,
    },
    isSaveRatesTable: {
      type: Boolean,
      default: false,
    },
    selectedPayFrequency: {
      type: String,
    },
    isOpenSettingsDialog: {
      type: Boolean,
      default: false,
    },
    step: {
      type: String,
    },
  },
  components: {
    PayRatesStaffMembers,
    PayRatesServices,
  },
  data(): IOnBoardingPayRates {
    return {
      labels: constants.onboardingConstants.ONBOARDING_LABELS,
    };
  },
  computed: {
    ...mapGetters({
      siteServicesList: "commonModule/getSiteServicesList",
      siteTable: "onBoardingModule/getTableData",
    }),
    getSubText(): string {
      return this.step === "3"
        ? "Select the payment model you use for each service below"
        : "Add the rate you pay to each staff member below";
    },
  },
  watch: {
    async isSaveRatesTable(val: boolean): Promise<void> {
      if (val) {
        const copySiteTable = [...this.siteTable];
        const formattedData =
          payRateHelper.getFormattedTableDataForRequest(copySiteTable);

        const data = {
          payRatesTableData: formattedData,
        };

        this.$store.commit("commonModule/setIsLoading", true);
        try {
          const rsp = await this.savePayRatesTableData(data);
          if (rsp.success) {
            this.$emit("changeSaveRateTable");
            const data = {
              payPer: payRateHelper.paymentTypeMaps[this.selectedPaymentOption],
              rateType: this.selectedRateOption,
              isPerParticipant: this.isParticipant,
              countBy: this.selectedParticipant,
              payFrequency: this.selectedPayFrequency,
            };
            await this.saveSiteSettings(data);
            return this.$router.push("/Reports?onboarding");
          }
        } catch (err) {
          console.error(`onboarding save pay rates table request ${err}`);
        } finally {
          this.$store.commit("commonModule/setIsLoading", false);
        }
      }
    },
  },
  methods: {
    ...mapActions({
      getPayRatesData: "onBoardingModule/getPayRatesTable",
      savePayRatesTableData: "onBoardingModule/savePayRatesTableData",
      saveSiteSettings: "onBoardingModule/saveSiteSettings",
    }),
    changeSaveRateTable(): void {
      this.$emit("changeSaveRateTable");
    },
  },
};
