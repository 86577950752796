
import { IOptions } from "../../types/onboarding.interface";

export default {
  name: "OnBoardingDesktopOptions",
  props: {
    options: {
      type: Array,
    },
    title: {
      type: String,
      default: "",
    },
    isDisplayImg: {
      type: Boolean,
      default: true,
    },
    isParticipantRates: {
      type: Boolean,
      default: false,
    },
    value: {
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    cardHeight: {
      type: Number,
      default: 325,
    },
  },
  computed: {
    selectedOption: {
      get(): string {
        return this.value;
      },
      set(val: string): void {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    setSelectedValue(value: IOptions): void {
      this.$emit("input", value.key);
    },
  },
};
