<template>
  <v-btn
    @click="$emit('onBack')"
    class="text-transform-capitalize btn-text pl-2"
    text
  >
    <v-icon size="20">mdi-chevron-left</v-icon>
    Back
  </v-btn>
</template>

<script>
export default {
  name: "BackButton",
};
</script>

<style scoped>
.btn-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}
.v-btn > .v-btn__content .v-icon {
  color: black;
}
</style>
