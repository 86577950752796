<template>
  <v-row align="center">
    <v-col cols="8">
      <div class="display-flex align-center">
        <div class="members-title pr-1">Members</div>
        <div class="main-subtitle">{{ `(${staffList.length})` }}</div>
      </div>
    </v-col>
    <v-col align-self="center" class="pt-3 justify-flex-end" cols="4">
      <v-btn
        class="ml-4 text-capitalize"
        color="primary"
        @click="$router.push('/inviteStaff')"
      >
        Add New Staff
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StaffMainHeader",
  computed: {
    ...mapGetters({
      staffList: "staffModule/getStaffListData",
    }),
  },
};
</script>

<style scoped>
.members-title {
  font-size: 24px;
  font-weight: 700;
  color: #13123c;
  line-height: 20px;
}
.main-subtitle {
  font-size: 20px;
  line-height: 32px;
  text-transform: capitalize;
  font-weight: 300;
}
</style>
