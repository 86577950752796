
import { IInviteStaffTypes, IStaff } from "../types/inviteStaff.interface";
import { pngList } from "../utils/assets.util";
import MissingEmailsDialog from "./MissingEmailsDialog.vue";
import InviteStaffDialog from "./onBoarding/InviteStaffDialog.vue";
import { differenceWith, isEqual } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "InviteStaff",
  components: { InviteStaffDialog, MissingEmailsDialog },
  props: {
    value: {
      type: Array,
      default: (): IStaff[] => [],
    },
    isOpenMissingEmailsDialog: {
      type: Boolean,
      default: false,
    },
    missingEmails: {
      type: Array,
      default: (): any => [],
    },
    isOnBoarding: {
      type: Boolean,
      default: false,
    },
  },
  data(): IInviteStaffTypes {
    return {
      isOpenInviteStaffDialog: false,
      placeholder: pngList.placeholder,
      searchWixMember: "",
    };
  },
  computed: {
    ...mapGetters({
      getWixMembersList: "staffModule/getWixMembersList",
      getStaffListData: "staffModule/getStaffListData",
    }),
    inviteStaffBtnText(): string {
      return "Add non-Bookings staff";
    },
    members: {
      get(): IStaff[] {
        return this.value;
      },
      set(val: IStaff): void {
        this.$emit("input", val);
      },
    },
    getMembersList(): IStaff[] {
      return this.isOnBoarding
        ? this.getWixMembersList
        : this.getDifference(this.getStaffListData, this.getWixMembersList);
    },
  },
  methods: {
    ...mapActions({
      updateStaffMemberData: "staffModule/updateStaffMemberData",
    }),
    async updateStaffWithoutResourceId(): Promise<void> {
      const staffToResourceIdMap = this.getWixMembersList.reduce(
        (acc: any, staff: IStaff) => {
          if (staff.email) acc[staff.email] = staff.resourceId;
          return acc;
        },
        {}
      );

      const membersWithoutResourceId = this.getStaffListData.filter(
        (member: any) => {
          return !member.resourceId;
        }
      );

      if (membersWithoutResourceId.length > 0)
        await Promise.all(
          membersWithoutResourceId.map((member: any) => {
            if (staffToResourceIdMap[member.email]) {
              const staffMemberData = {
                email: member.email,
                resourceId: staffToResourceIdMap[member.email],
              };
              return this.updateStaffMemberData(staffMemberData);
            }
          })
        );
    },
    saveStaffMembers(memberData: IStaff[]): void {
      this.$emit("saveStaffMembers", memberData);
    },
    redirectToPackageUpgrade(memberData: IStaff[]): void {
      this.$emit("redirectToPackageUpgrade", memberData);
    },
    getDifference(members: IStaff[], wixMembers: IStaff[]): IStaff[] {
      return wixMembers.filter((wixMember) => {
        return !members.some((member) => {
          if (wixMember.email) return member.email === wixMember.email;
          return member.resourceId === wixMember.resourceId;
        });
      });
    },
  },
  async created(): Promise<void> {
    const difference = differenceWith(
      this.getWixMembersList,
      this.members,
      isEqual
    );
    this.wixMembersList = [...this.members, ...difference];

    await this.updateStaffWithoutResourceId();
  },
};
