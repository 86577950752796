
import { mapGetters } from "vuex";
import reportUtil from "../../utils/reports.util";
import helper from "../../utils/helper.util";
import moment from "moment";

export default {
  name: "ReportsFooter",
  computed: {
    ...mapGetters({
      getReports: "reportsModule/getReports",
      getReportType: "reportsModule/getReportType",
      getTableTotal: "reportsModule/getTableTotal",
      getSiteCurrency: "commonModule/getSiteCurrency",
      getTokenDetails: "commonModule/getTokenDetails",
    }),
  },
  methods: {
    moment,
    async getReportCSV(): Promise<void> {
      const csvString = this.getReports.map((report: any) => {
        const res: {
          hours?: string;
          wage?: string;
          name: string;
          email: string;
          total_wage?: string;
          sessions?: [];
        } = {
          name: report.userName,
          email: report.email,
        };
        if (
          this.getReportType === "Timesheets" ||
          this.getReportType === "Both"
        ) {
          res.hours = report.timeCardData
            ? report.timeCardData.totalHours
            : "0.00";
          res.wage = report.hourlyRate.paymentAmount;
        }
        if (
          this.getReportType === "Bookings" ||
          this.getReportType === "Both"
        ) {
          res.sessions = report.sessionsCount;
        }
        res.total_wage = report.totalPayout;
        return res;
      });
      const csvStringFormatted = await reportUtil.getResultCSVString(csvString);

      helper.downloadCSV(
        csvStringFormatted,
        `reports-${moment().format("YYYY-MM-DD")}`
      );
    },
    async getGustoReportCSV(): Promise<void> {
      const csvString = this.getReports.map((report: any) => {
        const { firstName, lastName } =
          reportUtil.explodeStandardNameComponents(report.userName);
        const res: {
          regular_hours?: string;
          first_name: string;
          last_name: string;
          email: string;
          ssn: string;
          title: string;
          commission?: string;
        } = {
          first_name: firstName,
          last_name: lastName,
          email: report.email,
          ssn: "",
          title: "",
        };
        if (
          this.getReportType === "Timesheets" ||
          this.getReportType === "Both"
        ) {
          res.regular_hours = report.timeCardData
            ? report.timeCardData.totalHours
            : "0.00";
        }
        if (
          this.getReportType === "Bookings" ||
          this.getReportType === "Both"
        ) {
          res.commission = report.sessionsCardTotal;
        }

        return res;
      });
      const csvStringFormatted = await reportUtil.getResultCSVString(csvString);

      helper.downloadCSV(
        csvStringFormatted,
        `gusto-reports-${moment().format("YYYY-MM-DD")}`
      );
    },
  },
};
