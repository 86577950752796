<template>
  <v-alert
    transition="slide-y-transition"
    :value="isDisplayAlert"
    dismissible
    color="#1479F2"
  >
    <template v-slot:close="{ toggle }">
      <v-icon color="white" @click="toggle">mdi-close</v-icon>
    </template>
    <slot />
  </v-alert>
</template>

<script>
export default {
  name: "BaseAlert",
  props: {
    isDisplayAlert: {
      type: Boolean,
    },
  },
};
</script>

<style scoped></style>
