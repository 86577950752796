
import { mapGetters } from "vuex";

export default {
  name: "PayRatesRoutes",
  data(): { items: string[] } {
    return {
      items: ["Bookings Services", "Rates"],
    };
  },
  computed: {
    ...mapGetters({
      getTabIndex: "payRatesModule/getTabIndex",
    }),
    tabIndex: {
      get(): number {
        return this.getTabIndex;
      },
      set(val: number): void {
        this.$store.commit("payRatesModule/setTabIndex", val);
      },
    },
  },
};
