
import { pngList } from "../../utils/assets.util";
import { mapGetters } from "vuex";
import Member from "../Member.vue";
import {
  IReportsData,
  IReportsTablePage,
} from "../../types/reportsPage.interface";
import TimeSheetsViewDialog from "../timeSheets/TimeSheetsViewDialog.vue";
import { ITimeSheetsDetails } from "../../types/timeSheets.interface";
import ReportsBookingView from "./ReportsBookingView.vue";

export default {
  name: "ReportsTable",
  components: { ReportsBookingView, TimeSheetsViewDialog, Member },
  props: {
    chosenDates: {
      type: Array,
    },
  },
  data(): IReportsTablePage {
    return {
      isOpenBookingView: false,
      userTimeSheetsDetails: {},
      isOpenViewDialog: false,
      emptyReportLogo: pngList.emptyReport,
      tableHeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      getReports: "reportsModule/getReports",
      getCurrentCurrency: "commonModule/getSiteCurrency",
      getReportType: "reportsModule/getReportType",
    }),
    getReportsData(): IReportsData {
      return this.getReports;
    },
    headers(): any {
      return [
        {
          text: "Staff",
          class: "subtitle-1 py-6",
          value: "userName",
          align: "start",
        },
        {
          text: "Bookings Sessions",
          value: "sessionsCount",
          class: "subtitle-1",
          width: this.getReportType !== "Bookings" ? 200 : "",
          align: "start",
        },
        this.getReportType !== "Bookings"
          ? {
              text: "Clocked Hours",
              value: "timeCardData.totalHours",
              class: "subtitle-1",
              align: "start",
              width: 200,
            }
          : null,
        this.getReportType !== "Bookings"
          ? {
              text: "Hourly Rate",
              value: "hourlyRate.paymentAmount",
              class: "subtitle-1",
              align: "start",
              width: 200,
            }
          : null,
        {
          text: "Total Payout",
          value: "totalPayout",
          class: "subtitle-1",
          align: "start",
        },
        {
          text: "",
          value: "key",
          sortable: false,
        },
      ].filter((x) => !!x);
    },
  },
  methods: {
    onResize(): void {
      this.tableHeight =
        window.innerHeight -
        this.$refs.resizableDiv.getBoundingClientRect().y -
        110;
    },
    openViewDialog(item: ITimeSheetsDetails): void {
      this.userTimeSheetsDetails = item;
      this.isOpenViewDialog = true;
    },
    openBookingDialog(item: ITimeSheetsDetails): void {
      this.userTimeSheetsDetails = item;
      this.isOpenBookingView = true;
    },
  },
};
