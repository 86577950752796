interface IApiHostsMap {
  [key: string]: string;
}

const apiHostsMap: IApiHostsMap = {
  local: "http://localhost:8080/",
  development: "https://easyteam-wix-backend-dev.azurewebsites.net/",
  production: "https://easyteam-wix-backend-prod.azurewebsites.net/",
};

const wixIdsMap: IApiHostsMap = {
  local: "f96abd79-28b9-409f-ac42-e7ff05c2ff96",
  development: "f96abd79-28b9-409f-ac42-e7ff05c2ff96",
  production: "aacd9f4f-b085-4348-9aed-2ad5e7768076",
};

const nodeEnv = process.env.NODE_ENV || "local";
const hostEnv = process.env.HOST;
const STONLY_WID = "c6fe4673-b3d1-11eb-8dbf-062882f67cfe";

if (process.env.NODE_ENV !== "production") {
  console.log(`Node Env: ${nodeEnv}`);
  console.log(`Host Env: ${hostEnv}`);
}

const BASE_URL = apiHostsMap[nodeEnv];
const WIX_APP_ID = wixIdsMap[nodeEnv];

const WIX_DNS = "https://wix.easyteam.com/";
const SENTRY_DNS =
  "https://0fcf123e27d940ed8f7808fc2d73bd0e@o1256700.ingest.sentry.io/6436851";

const config = {
  BASE_URL,
  STONLY_WID,
  WIX_DNS,
  SENTRY_DNS,
  WIX_APP_ID,
};

export default config;
