import { MutationTree } from "vuex";
import { IReports } from "./types";

export const mutations: MutationTree<IReports> = {
  setReports(state, data): void {
    state.reports = data;
  },
  setReportsPageData(state, data): void {
    state.reportsPageData = data;
  },
  setReportType(state, data): void {
    state.reportType = data;
  },
  setTableTotal(state, data): void {
    state.tableTotal = { ...data, payout: data.payout.toFixed(2) };
  },
};
