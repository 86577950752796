import { ActionTree } from "vuex";
import { IStaffRootState } from "./types";
import { RootState } from "../../types";
import staffApi from "../../../utils/api/staffPage.api";

import { IStaffDetails } from "../../../types/staffPage.interface";

export const actions: ActionTree<IStaffRootState, RootState> = {
  async getStaffList({ commit, rootState, rootGetters }): Promise<void> {
    const { user, currentShopIndex, tokenDetails } = rootState.commonModule;
    commit("commonModule/setIsLoading", true, { root: true });
    const data = {
      instanceId: user.sites[currentShopIndex]?.instanceId || user.defaultSite,
      email: tokenDetails.email,
    };

    try {
      const rsp: any = await staffApi.getStaffPageData(data);
      const { personnel } = rsp.data;
      if (rsp.success) {
        const staffEmails = personnel.map(
          (staff: IStaffDetails) => staff.email
        );

        commit("setStaffEmailsList", staffEmails);
        commit(
          "setStaffList",
          rootGetters["commonModule/sortByOnlineUser"](personnel)
        );
      }
    } catch (err) {
      console.error(`get staff request: ${err}`);
    } finally {
      commit("commonModule/setIsLoading", false, { root: true });
    }
  },
  async updateStaffMemberData(
    { dispatch, commit, rootState },
    payload: any
  ): Promise<void> {
    commit("commonModule/setIsLoading", true, { root: true });

    const email = payload.email;

    delete payload.email;
    const { user, currentShopIndex } = rootState.commonModule;
    const data = {
      staffMemberData: payload,
      instanceId: user.sites[currentShopIndex].instanceId,
      email,
    };

    try {
      await staffApi.updateStaffMemberData(data);
      dispatch("getStaffList");
    } catch (err) {
      console.error(`update staff request: ${err}`);
    } finally {
      commit("commonModule/setIsLoading", false, { root: true });
    }
  },
  async getSiteStaffFromWix({ commit, rootState }, payload) {
    const {
      user: { sites },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      instanceId: sites[currentShopIndex].instanceId,
      isOnboarding: payload.isOnBoarding,
    };

    commit("commonModule/setIsLoading", true, { root: true });

    try {
      const rsp: any = await staffApi.getSiteStaffFromWix(data);
      if (rsp.success) commit("setWixMembersList", rsp.data.staffData);
    } catch (err) {
      console.error(`get staff from wix request: ${err}`);
    } finally {
      commit("commonModule/setIsLoading", false, { root: true });
      // eslint-disable-next-line no-undef,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      $crisp.push(["do", "chat:show"]);
    }
  },
  async addStaffMembers({ commit, rootState }, payload) {
    const { user, currentShopIndex, tokenDetails } = rootState.commonModule;

    const isSendInviteEmails = payload.isSendInviteEmails;

    delete payload.isSendInviteEmails;

    const memberData = payload.memberData.filter((member: any) => {
      return member.email !== tokenDetails.email;
    });

    const data = {
      instanceId: user.sites[currentShopIndex].instanceId,
      siteName: user.sites[currentShopIndex].siteDisplayName,
      invitorName: user.userName,
      membersData: memberData,
      sendInviteEmails: isSendInviteEmails,
    };

    commit("commonModule/setIsLoading", true, { root: true });

    try {
      await staffApi.addStaffMembers(data);
    } catch (err) {
      console.error(`add staff members request: ${err}`);
    } finally {
      commit("commonModule/setIsLoading", false, { root: true });
    }
  },
  async deleteMember({ rootState }, payload) {
    const {
      user: { sites },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      instanceId: sites[currentShopIndex].instanceId,
      email: payload,
    };

    return staffApi.deleteStaffMember(data);
  },
  async redirectToPackageUpgrade({ rootState }, payload) {
    const {
      user: { sites },
      currentShopIndex,
    } = rootState.commonModule;

    const data = {
      instanceId: sites[currentShopIndex].instanceId,
      totalUsers: payload,
    };

    return staffApi.redirectToPackageUpgrade(data);
  },
};
