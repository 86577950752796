var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-dialog',{attrs:{"dialog-title":_vm.labels.MISSING_EMAILS_DIALOG.TITLE,"width":"600","persistent":"","actions":_vm.dialogActions,"dialog-subtitle":_vm.labels.MISSING_EMAILS_DIALOG.FIRST_DESCRIPTION},on:{"closeDialog":function($event){return _vm.$emit('closeMissingEmailDialog')}},model:{value:(_vm.isOpenMissingEmailsDialog),callback:function ($$v) {_vm.isOpenMissingEmailsDialog=$$v},expression:"isOpenMissingEmailsDialog"}},[_c('div',{staticClass:"pt-2 pb-0 px-4"},[_c('div',{staticClass:"pb-0"},[_c('v-card',{staticClass:"mt-3 border-radius",attrs:{"elevation":"0"}},[_c('v-list',{staticClass:"py-0"},[_c('v-form',{ref:"form"},[_vm._l((_vm.missingEmails),function(item,i){return [(i > 0)?_c('v-divider',{key:`divider-${i}`}):_vm._e(),_c('v-list-item',{key:`item-${i}`,staticClass:"pt-3 px-0",attrs:{"value":item}},[_c('v-list-item-content',{staticClass:"px-0 pb-1"},[_c('div',{staticClass:"align-start"},[_c('v-avatar',{attrs:{"size":"30"}},[_c('img',{attrs:{"src":_vm.placeholder,"alt":"placeholder"}})]),_c('v-flex',{staticClass:"justify-space-between"},[_c('v-list-item-title',{staticClass:"member-name mr-3 ml-2 pt-1",staticStyle:{"flex":"unset"},domProps:{"textContent":_vm._s(item.name)}}),_c('v-text-field',{staticStyle:{"max-width":"225px"},attrs:{"outlined":"","dense":"","placeholder":"Enter Email","rules":[
                          _vm.formRules.email,
                          _vm.formRules.required,
                          _vm.formRules.emailConfirmation(
                            item.email,
                            _vm.missingEmails,
                            i
                          ),
                        ]},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})],1)],1)])],1)]})],2)],1),_c('v-divider'),_c('div',{staticClass:"pt-6 pb-2 px-1 align-center"},[_c('v-checkbox',{staticClass:"mt-1",attrs:{"color":"black","on-icon":"mdi-checkbox-outline"}}),_c('div',{staticClass:"checkbox-text"},[_vm._v(" "+_vm._s(_vm.labels.MISSING_EMAILS_DIALOG.CHECKBOX_TEXT)+" ")])],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }