// eslint-disable-next-line @typescript-eslint/no-var-requires
const forge = require("node-forge");

const hashPassword = (password: string): string => {
  const md = forge.md.sha512.create();
  md.update(password);
  return md.digest().toHex();
};

const hashUtil = {
  hashPassword,
};

export default hashUtil;
