import moment from "moment";

export const emailReg =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isEmptyInput = (v: string): boolean => {
  if (v === undefined) return false;
  return v === null || !v.length;
};

const isNameExists = (
  v: string,
  data: Array<string>,
  type = "Checklist"
): boolean | string => {
  let currentData;
  if (v)
    currentData = data.filter(
      (item: any) => item.name.toLowerCase() === v.toLowerCase()
    );

  return (
    !(currentData && currentData.length) ||
    `You already have a ${type} with this name`
  );
};

const formRules = () => ({
  email: (v: string): boolean | string =>
    isEmptyInput(v) || emailReg.test(v) || "Email is not valid",
  required: (value: any) =>
    !!value ||
    value === 0 ||
    (value && value.trim() !== "") ||
    "Required Field",
  minCharacters:
    (numberOfCharacters: number) =>
    (v: string): boolean | string =>
      isEmptyInput(v) ||
      (v && v.length) >= numberOfCharacters ||
      `The password must contain at least ${numberOfCharacters} characters.`,
  fourDigits: (v: any) =>
    isEmptyInput(v) || (v && v.length) <= 4 || "pincode must be 4 digits",
  oneSpecialChar: (v: any) =>
    isEmptyInput(v) ||
    RegExp("[~!@#$%^&*()_+.]").test(v) ||
    "One special character",
  oneDigit: (v: string) =>
    isEmptyInput(v) || RegExp("[0-9]").test(v) || "One number",
  oneLowerCase: (v: string) =>
    isEmptyInput(v) || RegExp("[a-z]").test(v) || "One lowercase character",
  oneUpperCase: (v: string) =>
    isEmptyInput(v) || RegExp("[A-Z]").test(v) || "One uppercase character",
  passwordConfirmation: (first: string, second: string): boolean | string =>
    first === second || "Passwords should be the same",
  requiredArray: [(v: Array<string>) => v.length > 0 || "Required Field"],
  duplicateNameValidation: (v: string, data: Array<string>, type: string) =>
    isNameExists(v, data, type),
  datesValidation: (v: string[]) => {
    return moment(v[1], "YYYY-MM-DD").diff(moment(v[0], "YYYY-MM-DD")) > 0;
  },
  emailConfirmation: (email: string, data: any, index: number): any => {
    if (!email) return true;

    if (index > 0) {
      const emails = data.filter((member: any) => member.email === email);

      return emails.length < 2 || "email already exists";
    } else {
      return true;
    }
  },
});

export default formRules;
