var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-dialog',{attrs:{"dialog-title":_vm.labels.ONBOARDING_INVITE_STAFF_DIALOG.TITLE,"max-width":"650","persistent":"","actions":_vm.dialogActions},on:{"closeDialog":function($event){return _vm.$emit('closeDialog')}},model:{value:(_vm.isOpenInviteStaffDialog),callback:function ($$v) {_vm.isOpenInviteStaffDialog=$$v},expression:"isOpenInviteStaffDialog"}},[_c('div',{staticClass:"container pb-0"},[_c('div',{staticClass:"input-wrapper mt-3 border-radius scroll"},[_c('v-form',{ref:"form"},_vm._l((_vm.userDetails),function(user,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v("FULL NAME")]),_c('v-text-field',{key:`fullNameInput${index}`,attrs:{"color":"primary","placeholder":"Enter name","dense":"","outlined":"","full-width":"","error":_vm.userDetails[index].isNameError,"rules":_vm.userDetails[index].email || _vm.userDetails[index].name
                  ? [_vm.formRules.required]
                  : []},model:{value:(_vm.userDetails[index].name),callback:function ($$v) {_vm.$set(_vm.userDetails[index], "name", $$v)},expression:"userDetails[index].name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v("EMAIL")]),_c('v-text-field',{key:`emailInput${index}`,attrs:{"full-width":"","rules":_vm.userDetails[index].name || _vm.userDetails[index].email
                  ? [
                      _vm.formRules.email,
                      _vm.formRules.required,
                      _vm.formRules.emailConfirmation(
                        _vm.userDetails[index].email,
                        _vm.userDetails,
                        index
                      ),
                    ]
                  : [],"type":"email","outlined":"","dense":"","color":"primary","placeholder":"Enter email"},model:{value:(_vm.userDetails[index].email),callback:function ($$v) {_vm.$set(_vm.userDetails[index], "email", $$v)},expression:"userDetails[index].email"}})],1)],1)}),1)],1),_c('v-btn',{staticClass:"text-transform-capitalize add-more-btn",attrs:{"color":"primary","text":""},on:{"click":_vm.addNewStaff}},[_vm._v("+ Add more")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }